import { cipo } from 'cipo';
import { environment } from 'src/environments/environment';
import { AzureADB2C } from 'src/environments/aadb2c';

    var describeURL = function (string) {
        var stringObj = (string || '').split('|');
        var method = stringObj.length > 1 ? stringObj[0] : 'GET';
        var URL = stringObj.length > 1 ? stringObj[1] : stringObj[0];

        return new function () {
            var allowedMethods = ['get', 'put', 'post', 'delete'];
            this.method = (allowedMethods.indexOf(method.toLowerCase()) === -1) ?
                'get' : method.toLowerCase();

            this.toString = function () {
                return environment.baseApiUrl + URL;
            };
        };
    };

    // Azure AD B2C
    cipo.constant("AADB2C", new AzureADB2C(window.location.host).generateConstants());

    cipo.constant("APP_CONTENT", {
        DRIVE: ['Project Files', 'My Drive']
    });

    cipo.constant("TEMPLATES_TYPES", {
        1: "Printout",
        2: "Correspondence",
        3: "Notification"
    });

    cipo.constant("SIGNATURE_TYPES", {
        1: "Drawing",
        2: "Text",
    });

    cipo.constant("YEAR", {
        1: {
            name: 'Jan',
            days: 31
        }, 2: {
            name: 'Feb',
            days: 28
        }, 3: {
            name: 'Mar',
            days: 31
        }, 4: {
            name: 'Apr',
            days: 30
        }, 5: {
            name: 'May',
            days: 31
        }, 6: {
            name: 'Jun',
            days: 30
        }, 7: {
            name: 'Jul',
            days: 31
        }, 8: {
            name: 'Aug',
            days: 31
        }, 9: {
            name: 'Sep',
            days: 30
        }, 10: {
            name: 'Oct',
            days: 31
        }, 11: {
            name: 'Nov',
            days: 30
        }, 12: {
            name: 'Dec',
            days: 31
        }
    });

    cipo.constant("DASHBOARD_STATUS_TYPES", {
        1: "OVERDUE",
        2: "OPEN",
        3: "REVISE",
        4: "INMYCOURT",
        5: "CLOSED",
        6: "INAPPROVAL"
    });

    cipo.constant("RELATION_TYPES", {
        1: "Relation",
        2: "Payment",
        3: "Dependency"
    });

    cipo.constant("STATES_STATUS_TYPES", {
        "0": [0, 2, 101, 102], // draft
        "2": [2, 101, 102], // open
        // "100": [0, 100, 101, 102], // revise
        "101": [101, 102], // approved
        "102": [101, 102], // rejected
        "103": [2, 101, 102] // global
    });

    cipo.constant("GLOBAL_TRANSITIONS", {
        toSelf: 1,
        toState: 2
    });

    cipo.constant("CLOSED_STATE_TYPE", '104');

    cipo.constant("STATES_STATUS", function () {
        var statuslookup = {
            0: 'draft',
            2: 'open',
            // 100: 'revise', // 
            101: 'approved', // 
            102: 'rejected', // 
            103: 'global', // 
            104: 'closed'
        };

        var statusDict = [
            { key: 0, value: "Draft" },
            { key: 2, value: "Open" },
            // { key: 100, value: "Revise" },
            { key: 101, value: "Approved" },
            { key: 102, value: "Rejected" },
            { key: 103, value: "Global" },
            { key: 104, value: "Closed" }
        ];
        var statusSmallerDict = [
            { key: "2", value: "Open", icon: "mdi mdi-file-document-box-outline blueText" },
            // { key: "100", value: "Revise", icon: "mdi mdi-file-document-box-multiple-outline neutralText" },
            { key: "101", value: "Approved", icon: "mdi mdi-file-document-box-check-outline successText" },
            { key: "102", value: "Rejected", icon: "mdi mdi-file-document-box-remove-outline dangerText" }
        ];
        return {
            statuslookup: statuslookup,
            statusDict: statusDict,
            statusSmallerDict: statusSmallerDict
        };
    });

    cipo.constant("STATES_INFO", function () {
        var haveTransitions = ['0', '2', '101', '102','103'];
        var statusNamesLookup = {
            0: 'start',
            2: 'open',
            // 100: 'revise', // 
            101: 'approved', // 
            102: 'rejected', // 
            103: 'global', // 
            104: 'closed'
        };
        return {
            haveTransitions: haveTransitions,
            statusNamesLookup: statusNamesLookup
        };
    });

    cipo.constant("URI", {
        SYSTEM: {
            IMPERSONATE: describeURL("_api/Main/Impersonate"),
            STOPIMPERSONATION: describeURL("_api/Main/StopImpersonate"),
            USER_ROLES_CONTRACT: describeURL("_api/Main/UserRolesForContract"), //?contractId={contractId}
            CREATE_SIGNATURE: describeURL("post|_api/Main/CreateSignature"), // [Body] UserSignatureModel
            GET_CURRENCY_BY_PROGRAM: describeURL("_api/Main/GetCurrencyByProgram"), //?programId={programId}&entityInstanceId={entityInstanceId}
            GET_CURRENCY_BY_PROJECT: describeURL("_api/Main/GetCurrencyByProject"), //?projectId={projectId}&entityInstanceId={entityInstanceId}
            GET_CURRENCY_BY_CONTRACT: describeURL("_api/Main/GetCurrencyByContract"), //?contractId={contractId}&entityInstanceId={entityInstanceId}
            GET_CURRENCY_BY_ID: describeURL("_api/Main/GetCurrencyById"), //?id={id}
            PBI_CONFIG: describeURL("_api/Main/PowerBiGetReportConfig"), // //?moduleId={moduleId}
        },
        MISC: {
            INDUSTRIES: describeURL('_api/Main/Industries'),
            COUNTRY_CODES: describeURL('_api/Main/CountryCodes'),
            CURRENCIES: describeURL('_api/Main/Currencies')
        },
        COMPANY: {
            DETAIL: describeURL('_api/Tenant/Detail'),
            INFO: describeURL('_api/Tenant/Counts'),
            SAVE: describeURL('put|_api/Tenant/Edit')
        },
        ORGANIZATION: {
            SEARCH: describeURL('post|_api/Organization/Search'),
            DICT: describeURL('_api/Organization/Dict'), //?userId={userId}&contractId={contractId}
            DETAIL: describeURL('_api/Organization/Detail'),
            ADD: describeURL('post|_api/Organization/Add'), // [FromUri]int? roleId = null
            SAVE: describeURL('put|_api/Organization/Edit'),
            ENABLE: describeURL('put|_api/Organization/Enable'),
            DISABLE: describeURL('put|_api/Organization/Disable'),
            USERS: describeURL('post|_api/Organization/Users') //[Uri] ?orgId={orgId} [Body] SearchViewModel
        },
        ROLE: {
            SYSTEM_LIST: describeURL('post|_api/Role/List'), // ?Id={Id}&Criteria={Criteria}&Page={Page}&Pagesize={Pagesize}&ShowDisabled={ShowDisabled}
            DYNAMIC_LIST: describeURL('_api/Role/ListContracting'), // ?Id={Id}&Criteria={Criteria}&Page={Page}&Pagesize={Pagesize}&ShowDisabled={ShowDisabled}
            DICT: describeURL('_api/Role/Dict'), // ?perContract={perContract}&permissionId={permissionId}&userId={userId}&contractId={contractId}
            DETAIL: describeURL('_api/Role/Detail'),
            ADD: describeURL('post|_api/Role/Add'),
            EDIT: describeURL('put|_api/Role/Edit'),
            ENABLE: describeURL('put|_api/Role/Enable'),
            DISABLE: describeURL('put|_api/Role/Disable'),
            DELETE: describeURL('put|_api/Role/Delete'),
            SYNC_USERS: describeURL('post|_api/Role/SyncUsers'),
            GET_PERMISSIONS: describeURL('_api/Role/GetPermissions'), //[Uri] ?Id={Id}&perContract={perContract}
            SYNC_PERMISSIONS: describeURL('post|_api/Role/SyncPermissions'), //[Uri] ?Id={Id} [Body] ListParameter ids /*selected permission ids*/
            CONTRACT_LIST: describeURL('_api/Role/ContractList'), //?Id={Id}&Criteria={Criteria}&Page={Page}&Pagesize={Pagesize}&ShowDisabled={ShowDisabled}
            GET_CONTRACT_USERS: describeURL('_api/Role/ContractUsers'), //?roleId={roleId}
            SYNC_CONTRACT_USERS: describeURL('put|_api/Role/ContractUsers'), //?roleId={roleId}
            // moduleId needs to be passed in header
            MISSING_ROLES: describeURL('_api/Role/MissingRolesDict') //?section={section}&contractId={contractId}
        },
        ACCESS_LEVELS: {
            DICT: describeURL('_api/Role/AccessLevelsDict')
        },
        USER: {
            SEARCH: describeURL('post|_api/User/Search'),
            ROLES: describeURL('_api/User/Roles'), // ?userId={userId}
            SYNC_ROLES: describeURL('post|_api/User/SyncRoles'),
            DETAIL: describeURL('_api/User/Detail'),
            ADD: describeURL('post|_api/User/Add'), // ?roleId={roleId}
            REINVITE: describeURL('post|_api/v2/User/Reinvite'), // ?userId = { userId }
            SAVE: describeURL('post|_api/User/Edit'),
            ENABLE: describeURL('put|_api/User/Enable'),
            ENABLE2: describeURL('post|_api/v2/User/Enable'), // ?userId = { userId }
            DISABLE: describeURL('put|_api/User/Disable'),
            DISABLE2: describeURL('post|_api/v2/User/Disable'), // ?userId = { userId }
            DELETE: describeURL('put|_api/User/Delete'),
            CHANGE_EMAIL: describeURL('post|_api/User/ChangeEmail'), // ?userId = { userId } & email = {newEmail}
            DICT: describeURL('_api/User/Dict'), //?roleId={roleId}&contractId={contractId}
            GET_ROLE_CONTRACTS: describeURL('_api/User/RoleContracts'), //?userId={userId}
            SYNC_ROLE_CONTRACTS: describeURL('put|_api/User/RoleContracts'), //?userId={userId}
            SYNC_SIGNATURE_ACCOUNTS: describeURL('put|_api/User/SyncSignatureAccounts'),
            CONTRACTS_DICT: describeURL('_api/User/ContractsDict'),
            TOGGLE_DISABLE_ROLE: describeURL('_api/User/ToggleDisableRole'), //?userId={userId}
            EDIT_PROFILE: describeURL('put|_api/User/EditProfile'), // [Body] UserEditProfileModel
            SYNC_ROLE_POSITIONS: describeURL('put|_api/User/SyncRolePositions'), // [Body] SyncRolePositionsModel
            TOGGLE_LOAD_MORE: describeURL('put|_api/User/ToggleLoadMore')
        },
        PROFILE: {
            CHANGE_PASSWORD: describeURL('put|_api/Profile/ChangePassword'), // [Body] UserPasswordModel
            GENERATE_RECOVER_PASSWORD_TOKEN: describeURL('post|_api/Profile/GenerateRecoverPasswordToken'), // [Body] UsernameModel
            CHECK_TOKEN_AVAILABILITY: describeURL('post|_api/Profile/CheckTokenAvailability'), // ?token={token}
            CHANGE_PASSWORD_RECOVERY: describeURL('put|_api/Profile/ChangePasswordRecovery'), // [Body] RecoveryPasswordModel
            EDIT: describeURL('post|_api/Profile/Edit'), // [Body] ProfileViewModel
        },
        USER_PROFILE: {
            GET: describeURL('_api/UserProfile/Get'),
            ADD: describeURL('post|_api/UserProfile/Add'), // [Body] UserProfileModel
            EDIT: describeURL('put|_api/UserProfile/Edit'), // [Body] UserProfileModel
            SET_SIGNATURE: describeURL('put|_api/UserProfile/SetSignature'), // [Body] FileBlobModel
        },
        TENANT: {
            LIST: describeURL('_api/Main/Tenants')
        },
        PROJECT: {
            //LIST: describeURL('_api/Project/List'), //?Id={Id}&Criteria={Criteria}&Page={Page}&Pagesize={Pagesize}&ShowDisabled={ShowDisabled}'
            LIST3: describeURL('_api/Project/Projects'), //?Id={Id}&Criteria={Criteria}&Page={Page}&Pagesize={Pagesize}&ShowDisabled={ShowDisabled}'
            DICT: describeURL('_api/Project/Dict')
            //LIST2: describeURL('_api/Main/listProjects')
        },
        DOCUMENT: {
            CREATE_ON_CONTRACT: describeURL('_api/Module/MyStartRole/'),
            LAYOUT: describeURL('_api/doc/layout/'),
            VIEW: describeURL('_api/doc/view/')
        },
        EMAIL: {
            UNREAD_THREAD_COUNT: describeURL('_api/Folder/TotalUnreadCount'),
            FOLDERS: describeURL('_api/Folder/List'),
            ADD_FOLDER: describeURL('post|_api/Folder/Add'),
            RENAME_FOLDER: describeURL('put|_api/Folder/Rename'),
            DELETE_FOLDER: describeURL('put|_api/Folder/Delete'),
            RECIPIENTS_DICT: describeURL('_api/Correspondence/RecipientDict'), //?contractId={contractId}
            LIST: describeURL('_api/Correspondence/List'),
            DETAIL: describeURL('_api/Correspondence/Detail'), //?conversationId={conversationId}&folderId={folderId}
            // Also soft deletes thread and moves to trash folder
            MOVE: describeURL('put|_api/Correspondence/Move'),
            READ: describeURL('put|_api/Correspondence/MarkAsRead?isRead=true'),
            UNREAD: describeURL('put|_api/Correspondence/MarkAsRead?isRead=false'),
            IMPORTANT: describeURL('put|_api/Correspondence/MarkAsImportant?isImportant=true'),
            UNIMPORTANT: describeURL('put|_api/Correspondence/MarkAsImportant?isImportant=false'),
            EMPTY_TRASH: describeURL('put|_api/Correspondence/EmptyTrash'),
            DISCARD_DRAFTS: describeURL('put|_api/Correspondence/DeleteDrafts'),
            SAVE_NEW_DRAFT: describeURL('post|_api/Correspondence/CreateDraft'), // [Uri] ?roleId={roleId}
            SAVE_EXISTING_DRAFT: describeURL('put|_api/Correspondence/UpdateDraft'),
            SEND: describeURL('post|_api/Correspondence/Send'),

            // Not used
            RESTORE: describeURL('put|_api/Correspondence/Restore'),
            TYPE_DICT: describeURL('_api/Correspondence/TypeDict'),
            SEND_REPLY_ALL: describeURL('post|_api/Correspondence/Send'),
            SEND_FORWARD: describeURL('post|_api/Correspondence/Forward')
        },
        //Contacts
        CONTACT: {
            SEARCH: describeURL('post|_api/Contact/Search'), // [Body] SearchViewModel
            GET: describeURL('_api/Contact/Get'), // [Uri] ?id={id}
            CREATE: describeURL('post|_api/Contact/Create'), // [Body] ContactModel [Uri] ?roleId={roleId}
            CREATE_CONTACT: describeURL('post|_api/Contact/CreateContact'), // [Body] ContactModel
            UPDATE: describeURL('put|_api/Contact/Update'), // [Body] ContactModel [Uri] ?roleId={roleId}
            DELETE: describeURL('put|_api/Contact/Delete'), // [Body] ids
            DICT: describeURL('_api/ContactModel/Dict')
        },
        MY_CIPO_DRIVE: {
            GET_FOLDER_ID: describeURL('post|_api/FolderFile/GetFolderId'), // [Body] FolderUserModel [Uri] ?roleId={roleId}
            CREATE_FOLDER: describeURL('post|_api/FolderFile/CreateFolder'), // [Body] FolderUserModel [Uri] ?roleId={roleId}
            EDIT_FOLDER: describeURL('put|_api/FolderFile/EditFolder'), // [Body] FolderUserModel [Uri] ?roleId={roleId}
            GENERATE_FILEID: describeURL('_api/FolderFile/GenerateFileId'),
            UPLOAD_FILE_CHUNKS: describeURL('_api/FolderFile/UploadFileInChunks'), //?name={name}  
            UPLOAD_CHUNK: describeURL('post|_api/FolderFile/UploadChunk'), // [Body] file chunk [Uri] ?fileId={fileId}            
            SAVE_FILE_INFO: describeURL('post|_api/FolderFile/SaveFileInfo'), //[Body] MainFile model [Uri] ?parentId={parentId}&mimeType={mimeType}&fileId={fileId}&totalBlocks={totalBlocks}&roleId={roleId}
            UPLOAD_FILE: describeURL('post|_api/FolderFile/UploadFile'), //[Body] MainFile model [Uri] ?parentId={parentId}&mimeType={mimeType}&roleId={roleId}
            UPLOAD_FILES: describeURL('post|_api/FolderFile/UploadFiles'), //?parentId={parentId}&mimeType={mimeType}&roleId={roleId} [Body] file blob array
            GET_FILE: describeURL('_api/FolderFile/GetFile'), //?fileId={fileId}&versionId={versionId}
            GET_FILE_PREVIEW: describeURL('_api/FolderFile/GetFilePreview'), //?fileId={fileId}&versionId={versionId}
            GET_VERSIONS: describeURL('_api/FolderFile/GetVersions'), //?fileId={fileId}
            DELETE: describeURL('put|_api/FolderFile/Delete'), // [Body] DeleteFilesModel
            PERMANENTLY_DELETE: describeURL('put|_api/FolderFile/PermanentlyDelete'), // [Body] DeleteFilesFoldersModel
            RESTORE: describeURL('put|_api/FolderFile/Restore'), // [Body] DeleteFilesFoldersModel
            MOVE: describeURL('put|_api/FolderFile/Move'), // [Body] DeleteFilesFoldersModel
            RENAME_FILE: describeURL('put|_api/FolderFile/RenameFile'), // [Body] RenameFileModel
            SHARE_FILE: describeURL('put|_api/FolderFile/ShareFile'), // [Body] ShareFileModel
            GET_FILE_BLOB: describeURL('_api/FolderFile/GetFileToByteArray'), //?fileId={fileId}
            SEARCH_FILES_FOLDERS: describeURL('post|_api/FolderFile/SearchFilesFolders'), // [Body] SearchViewModel
            ADD_CHUNKED_VERSION_FILE: describeURL('post|_api/FolderFile/AddChunkedVersion'), // [Body] MainFile model [Uri] ?fileId={fileId}&physicalId={physicalId}&mimeType={mimeType}&totalBlocks={totalBlocks}&roleId={roleId}
            ADD_VERSION_FILE: describeURL('post|_api/FolderFile/AddVersion'), // [Body] MainFile model [Uri] ?fileId={fileId}&physicalId={physicalId}&mimeType={mimeType}&totalBlocks={totalBlocks}&roleId={roleId}
            DUPLICATE_CHUNKED_VERSION_FILE: describeURL('post|_api/FolderFile/DuplicateChunkedVersion'), // [Body] DuplicateVersionModel [Uri] ?roleId={roleId}
            DUPLICATE_VERSION_FILE: describeURL('post|_api/FolderFile/DuplicateVersion'), // [Body] DuplicateVersionModel [Uri] ?roleId={roleId}
            SET_FILE_AS_COMPANY_LOGO: describeURL('put|_api/FolderFile/SetFileAsCompanyLogo'), //?fileId={fileId}
            SET_FILE_AS_USER_LOGO: describeURL('put|_api/FolderFile/SetFileAsUserLogo'), //?fileId={fileId}
            UPLOAD_FILE_AS_COMPANY_LOGO: describeURL('put|_api/FolderFile/UploadFileAsCompanyLogo'), //?mimeType={mimeType}&roleId={roleId}
            UPLOAD_FILE_AS_USER_LOGO: describeURL('put|_api/FolderFile/UploadFileAsUserLogo'), //?mimeType={mimeType}&roleId={roleId}
            SEARCH: describeURL('post|_api/FolderFile/Search'), // [Body] FolderFileSearch
            DELETE_SHARES: describeURL('put|_api/FolderFile/DeleteShares'), // [Body] DeleteShares
            DELETE_SHARES_GLOBAL: describeURL('put|_api/FolderFile/DeleteSharesGlobal'), // [Body] FilesId [Uri] ?parentId={parentId}
            GET_FILE_URL: describeURL('_api/FolderFile/GetFileURL'), //?versionId={versionId}&isPreview={isPreview}
            DOWNLOAD_ZIP_FILE: describeURL('put|_api/FolderFile/DownloadZipFromFolderAndFiles'),
            GET_PREVIEW: describeURL('put|_api/FolderFile/GetPicturePreview'), //?versionId={versionId}
            SAVE_FILES_TO_FOLDER: describeURL('put|_api/FolderFile/SaveAttachmentsToFolder'), // [Body] SaveAttachmentsToFolder
            FILE_MODULE_NAME: describeURL('_api/FolderFile/GetFilesModuleName'),
            GET_FILES_URL: describeURL('put|_api/FolderFile/GetFilesUrl'), // [Body] FileModel
        },
        RICHTEXT: {
            UPLOAD: describeURL('put|_api/FolderFile/UploadRichtextImage'),
            DELETE: describeURL('delete|_api/FolderFile/DeleteRichtextImage'), //?image={image}
        },
        PROJECT_FILE_MANAGER: {
            CREATE_FOLDER: describeURL('post|_api/ProjectFolderFile/CreateFolder'), // [Body] FolderProjectModel [Uri] ?roleId={roleId}
            EDIT_FOLDER: describeURL('put|_api/ProjectFolderFile/EditFolder'), // [Body] FolderProjectModel [Uri] ?roleId={roleId}
            GENERATE_FILEID: describeURL('_api/ProjectFolderFile/GenerateFileId'),
            UPLOAD_FILE_CHUNKS: describeURL('_api/ProjectFolderFile/UploadFileInChunks'), //?name={name}  
            UPLOAD_CHUNK: describeURL('post|_api/ProjectFolderFile/UploadChunk'), // [Body] file chunk [Uri] ?fileId={fileId}            
            SAVE_FILE_INFO: describeURL('post|_api/ProjectFolderFile/SaveFileInfo'), //[Body] MainFile model [Uri] ?parentId={parentId}&mimeType={mimeType}&fileId={fileId}&totalBlocks={totalBlocks}&projectId={projectId}&contractId={contractId}&roleId={roleId}
            UPLOAD_FILE: describeURL('post|_api/ProjectFolderFile/UploadFile'), //[Body] MainFile model [Uri] ?parentId={parentId}&mimeType={mimeType}&projectId={projectId}&contractId={contractId}&roleId={roleId}
            GET_FILE: describeURL('_api/ProjectFolderFile/GetFile'), //?fileId={fileId}&versionId={versionId}&projectId={projectId}&contractId={contractId}
            GET_FOLDER: describeURL('_api/ProjectFolderFile/GetFolder'), //?folderId={folderId}&contractId={contractId}&isFolders={isFolders}&onlyEssentialInformation={onlyEssentialInformation}
            GET_VERSIONS: describeURL('_api/ProjectFolderFile/GetVersions'), //?fileId={fileId}&contractId={contractId}
            DELETE: describeURL('put|_api/ProjectFolderFile/Delete'), // [Body] DeleteFilesFoldersModel   [Uri] ?contractId={contractId}
            PERMANENTLY_DELETE: describeURL('put|_api/ProjectFolderFile/PermanentlyDelete'), // [Body] DeleteFilesFoldersModel   [Uri] ?contractId={contractId}
            RESTORE: describeURL('put|_api/ProjectFolderFile/Restore'), // [Body] DeleteFilesFoldersModel [Uri] ?contractId={contractId}
            MOVE: describeURL('put|_api/ProjectFolderFile/Move'), // [Body] DirMoveModel
            RENAME_FILE: describeURL('put|_api/ProjectFolderFile/RenameFile'), // [Body] RenameFileModel
            GET_FILE_BLOB: describeURL('_api/ProjectFolderFile/GetFileToByteArray'), //?fileId={fileId}&contractId={contractId}
            SEARCH_FILES_FOLDERS: describeURL('post|_api/ProjectFolderFile/SearchFilesFolders'), //[Body] SearchViewModel [Url] ?projectId={projectId}&contractId={contractId}
            ADD_CHUNKED_VERSION_FILE: describeURL('post|_api/ProjectFolderFile/AddChunkedVersion'), // [Body] MainFile model [Uri] ?fileId={fileId}&physicalId={physicalId}&mimeType={mimeType}&totalBlocks={totalBlocks}&contractId={contractId}&roleId={roleId}
            ADD_VERSION_FILE: describeURL('post|_api/ProjectFolderFile/AddVersion'), //?fileId={fileId}&mimeType={mimeType}&contractId={contractId}&roleId={roleId} [Body] file blob
            DUPLICATE_VERSION_FILE: describeURL('post|_api/ProjectFolderFile/DuplicateVersion'), //?contractId={contractId} [Body] DuplicateVersionModel [Uri] ?roleId={roleId}
            DUPLICATE_CHUNKED_VERSION_FILE: describeURL('post|_api/ProjectFolderFile/DuplicateChunkedVersion'), //?contractId={contractId} [Body] DuplicateVersionModel [Uri] ?roleId={roleId}
            SET_FILE_GLOBAL: describeURL('put|_api/ProjectFolderFile/SetFilesGlobal'), // [Body] GlobalFileModel [URL]?contractId={contractId}
            UNSET_FILE_GLOBAL: describeURL('put|_api/ProjectFolderFile/UnSetFilesGlobal'), // [Body] GlobalFileModel [URL]?contractId={contractId}
            SEARCH: describeURL('post|_api/ProjectFolderFile/Search'), // [Body] ProjectFolderFileSearch [Url] ?projectId={projectId}&contractId={contractId}
            SEARCH_GLOBAL: describeURL('post|_api/ProjectFolderFile/SearchGlobal'), // [Body] ProjectFolderFileSearch [Url] ?rootModuleId={rootModuleId}&entityInstanceId={entityInstanceId}
            GET_FILE_URL: describeURL('_api/ProjectFolderFile/GetFileURL'), //?versionId={versionId}&isPreview={isPreview}
            DOWNLOAD_ZIP_FILE: describeURL('put|_api/ProjectFolderFile/DownloadZipFromFolderAndFiles'), //contractId={contractId}
            GET_PREVIEW: describeURL('put|_api/ProjectFolderFile/GetPicturePreview'), //?versionId={versionId}
            SAVE_FILES_TO_FOLDER: describeURL('put|_api/ProjectFolderFile/SaveAttachmentsToFolder'), // [Body] SaveAttachmentsToFolder
            GET_FILES_URL: describeURL('put|_api/ProjectFolderFile/GetFilesUrl'), // [Body] FileModel
        },
        ICONS: {
            DICT: describeURL('_api/Icons/Dict'),
            GET: describeURL('_api/Icons/Get'), //?iconId={iconId}
            SYNC: describeURL('post|_api/Icons/Sync'), // [Body] KeyValueModels
        },
        MENU: {
            USER_MENU: describeURL('_api/Menu/UserMenu'), // ?contractId={contractId}
            MODULES: describeURL('_api/Menu/Modules'),
            LIST: describeURL('_api/Menu/List'),
            ADD: describeURL('post|_api/Menu/Add'), // [Body] MenuModel  [Uri] ?roleId={roleId}
            ATTACH: describeURL('post|_api/Menu/Attach'), // [Body] MenuModel [Uri] ?roleId={roleId}
            DETACH: describeURL('put|_api/Menu/Detach'), // [Body] menuId
            EDIT: describeURL('put|_api/Menu/Edit'), // [Body] MenuModel
            MOVE: describeURL('put|_api/Menu/Move'), // [Body] MenuModel
            ORDER: describeURL('put|_api/Menu/Order'), // [Body] List of MenuModel [Uri] ?roleId={roleId}
            DELETE: describeURL('put|_api/Menu/Delete') // ?menuId={menuId}
        },
        FIELD_DEFINITION: {
            //moduleId needs to be passed in the header on all calls
            SEARCH: describeURL('post|_api/FieldDefinition/Search'), // [Uri] ?entityId={entityId}&excludedEntityId={excludedEntityId}&screenId={screenId}&addContractFields={addContractFields}  [Body] SearchViewModel; 
            GET: describeURL('_api/FieldDefinition/Get'), // [Uri] ?id={id}
            ADD: describeURL('post|_api/FieldDefinition/Add'), // [Body] FieldModel [Uri] ?screenId={screenId}
            EDIT: describeURL('put|_api/FieldDefinition/Edit'), // [Body] FieldModel
            ENTITIES_DICT: describeURL('_api/FieldDefinition/EntitiesDict'), // [Uri] ?moduleTypeId={moduleTypeId}
            SYNC_FIELD_ENTITIES: describeURL('put|_api/FieldDefinition/SyncFieldEntities'), // [Body] SyncFieldEntitiesModel
            TYPES_DICT: describeURL('_api/FieldDefinition/FieldTypesDict'), // [Uri] ?onlyChildren={onlyChildren}
            DELETE: describeURL('put|_api/FieldDefinition/Delete'), // [Uri] ?id={id}
            MIME_TYPES_DICT: describeURL('_api/FieldDefinition/MimeTypesDict'),
            DATASOURCE_LIST: describeURL('post|_api/FieldDefinition/SearchDataSourceList'), // [Body] DataSourcesSearchModel
            DATASOURCE_RELATION: describeURL('post|_api/FieldDefinition/SearchEntityInstances'), // [Body] DataSourcesSearchEIModel
            DATASOURCE_RELATION_FILTER: describeURL('post|_api/FieldDefinition/SearchEntityInstancesFilter'), // [Uri] ?relationId={relationId}&fieldId={fieldId}&contractId={contractId}   [Body] SearchViewModel
            DICT: describeURL('_api/FieldDefinition/Dict'), // [Uri] ?type={type}&multiple={multiple} null for all, 0 for single and 1 for multiple
            DATA_LIST_COMPLEX_FORMULA_DICT: describeURL('_api/FieldDefinition/DataListComplexFormulaFieldsDict'),
            EXPRESSION_DICT: describeURL('_api/FieldDefinition/ExpressionDict'),
            IMPORT_DICT: describeURL('_api/FieldDefinition/ImportDict'), // [Uri] ?screenId={screenId}
            AGGREGATE_DICT: describeURL('_api/FieldDefinition/AggregateFunctionsDict'),
            CORRESPONDENCE_DICT: describeURL('_api/FieldDefinition/LinkCorrespondenceFieldsDict'), // [Uri] ?screenId={screenId}
            DESTINATION_FOLDER_DICT: describeURL('_api/FieldDefinition/DestinationFolderFieldsDict'),
            DATASOURCE_HAS_DEFAULT_VALUE: describeURL('_api/FieldDefinition/CheckParentDataSourceHasDefaultValue'), // [Uri] ?id={id}
            ENTITY_FIELDS_DICT: describeURL('_api/FieldDefinition/EntityFieldsDict'),
            FIELDS_ON_REPORTS: describeURL('_api/FieldDefinition/AvailableForReportsFields'),
            SYNC_FIELDS_ON_REPORTS: describeURL('put|_api/FieldDefinition/SyncFieldsOnReport'), // [Body] fields

            // Table fields
            ASSIGNED_FIELDS: describeURL('_api/FieldDefinition/TableFields'), // ?id={id}
            ALL_DELETED_FIELDS: describeURL('_api/FieldDefinition/DeletedTableFields'), // ?id={id}
            ADD_FIELD: describeURL('post|_api/FieldDefinition/AddTableField'), // [Body] TableFieldModel  [Uri] ?id={id}
            // Edit table field will not change width and position of a field, use sync for that
            EDIT_FIELD: describeURL('put|_api/FieldDefinition/EditTableField'), // [Body] TableFieldModel  [Uri] ?id={id}
            DELETE_FIELD: describeURL('put|_api/FieldDefinition/DeleteTableField'), // [Uri] ?id={id}
            SYNC_TABLE_FIELDS: describeURL('put|_api/FieldDefinition/SyncTableFields') // [Body] SyncTableFields
        },
        MODULE_GRID: {
            //moduleId needs to be passed in the header on all calls
            FIELDS: describeURL('_api/ModuleGrid/GridFields'), // [Uri] ?workflowId={workflowId}&contractId={contractId}&relationId={relationId}
            START_SCREENS: describeURL('_api/ModuleGrid/GetStartScreens'), // ?wofkflowId={wofkflowId}&contractId={contractId}
            INSTANCES: describeURL('_api/ModuleGrid/GridCurrentInstances'), // [Uri] ?contractId={contractId}
            SEARCH: describeURL('post|_api/ModuleGrid/GridSearch'), // [Uri] ?contractId={contractId}&current={current}  [Body] SearchViewModel
            SEARCH_PRESETS: describeURL('post|_api/ModuleGrid/GridSearchPresets'), // [Uri] ?contractId={contractId}&current={current}  [Body] SearchViewModel
            SEARCH_CHILDREN: describeURL('post|_api/ModuleGrid/GridSearchChildren'), // [Uri] ?contractId={contractId}&relationId={relationId}&parentEntityInstanceId={parentEntityInstanceId}  [Body] SearchViewModel
            GET_FORMULAS: describeURL('post|_api/ModuleGrid/GridExpressions'), // [Uri] ?contractId={contractId}&current={current}  [Body] SearchViewModel
            GET_CHILDREN_FORMULAS: describeURL('post|_api/ModuleGrid/GridExpressionsChildren'), // [Uri] ?contractId={contractId}&relationId={relationId}&parentEntityInstanceId={parentEntityInstanceId}  [Body] SearchViewModel
            HAS_AVAILABLE_PRESETS: describeURL('_api/EntityTemplates/ModuleHasAvailablePresets'), // [Uri] ?moduleId&contractId 
            GET_FILTER: describeURL('_api/ModuleGrid/GetFilter'), // [Uri] ?id={id}&contractId={contractId}
            SEARCH_REPORT: describeURL('post|_api/ModuleGrid/GridSearchReport'), // [Uri] ?contractId={contractId}&reportId={reportId}&current={current}  [Body] SearchViewReportModel
            SEARCH_REPORT_EXCEL: describeURL('post|_api/ModuleGrid/GridSearchReportAsExcel'), // [Uri] ?contractId={contractId}&reportId={reportId}&current={current}  [Body] SearchViewReportModel
            SEARCH_REPORT_PDF: describeURL('post|_api/ModuleGrid/GridSearchReportAsPDF'), // [Uri] ?contractId={contractId}&reportId={reportId}&current={current}  [Body] SearchViewReportModel
            MODULE_INFORMATION: describeURL('_api/ModuleGrid/GetModuleInformation'), // [Uri] ?contractId={contractId}
        },
        MODULE_DATA_LIST: {
            //moduleId needs to be passed in the header on all calls
            // Search method retrieve all data list fields
            SEARCH: describeURL('post|_api/DataList/Search'), // [Uri] ?relationId={relationId}  [Body] SearchViewModel; 
            // ModuleFields method retrieve all module fields that aren't on data list
            MODULE_FIELDS: describeURL('post|_api/DataList/ModuleFields'), // [Uri] ?relationId={relationId}  [Body] SearchViewModel; 
            SYNC_FIELDS: describeURL('put|_api/DataList/SyncFieldToDataList'), // [Uri] ?relationId={relationId}  [Body] DataListFieldPropertiesModel (fields)
            UPDATE_FIELD: describeURL('put|_api/DataList/UpdateField') // [Body] DataListFieldPropertiesModel
        },
        MODULE_AGGREGATE_FORMULA: {
            //moduleId needs to be passed in the header on all calls
            ALL: describeURL('_api/DataListAggregateFormula/AllAggregates'), // [Uri] ?dataListId={dataListId}
            GET: describeURL('_api/DataListAggregateFormula/GetAggregate'), // [Uri] ?id={id}
            ADD: describeURL('post|_api/DataListAggregateFormula/Add'), // [Uri] ?dataListId={dataListId}  [Body] DataListAggregateFormulaModel
            EDIT: describeURL('put|_api/DataListAggregateFormula/Edit'), // [Uri] ?dataListId={dataListId} [Body] DataListAggregateFormulaModel
            DELETE: describeURL('put|_api/DataListAggregateFormula/Delete'), // [Uri] ?id={id}
            SYNC_FIELD_FORMULAS: describeURL('put|_api/DataListAggregateFormula/SyncAggregateFormulas') // [Uri] ?dataListId={dataListId}   [Body] DataListAggregateFormulasModel
        },
        MODULE_COMPLEX_FORMULA: {
            //moduleId needs to be passed in the header on all calls
            ALL: describeURL('_api/DataListComplexFormula/AllComplex'), // [Uri] ?dataListId={dataListId}
            GET: describeURL('_api/DataListComplexFormula/GetComplex'), // [Uri] ?id={id}
            ADD: describeURL('post|_api/DataListComplexFormula/Add'), // [Uri] ?dataListId={dataListId}  [Body] DataListComplexFormulaModel
            EDIT: describeURL('put|_api/DataListComplexFormula/Edit'), // [Uri] ?dataListId={dataListId} [Body] DataListComplexFormulaModel
            SYNC: describeURL('put|_api/DataListComplexFormula/SyncFormulas'), // [Uri] ?dataListId={dataListId} [Body] DataListComplexFormulaModel(s)
            DELETE: describeURL('put|_api/DataListComplexFormula/Delete') // [Uri] ?id={id}
        },
        //Modules definition
        MODULES: {
            SEARCH: describeURL('post|_api/ModuleDefinition/Search'), // [Body] SearchViewModel
            GET: describeURL('_api/ModuleDefinition/Get'), // [Uri] ?id={id}
            ADD: describeURL('post|_api/ModuleDefinition/Add'), // [Body] ModuleModel [Uri] ?roleId={roleId}
            EDIT: describeURL('put|_api/ModuleDefinition/Edit'), // [Body] ModuleModel
            SYNC_PDFS: describeURL('put|_api/ModuleDefinition/SyncPDFsDocuments'), // [Uri] ?id={id}
            DELETE: describeURL('put|_api/ModuleDefinition/Delete'), // [Body] ids
            ENABLE: describeURL('put|_api/ModuleDefinition/Enable'), // [Body] ids
            DISABLE: describeURL('put|_api/ModuleDefinition/Disable'), // [Body] ids
            DOC_NUM_PLACEHOLDERS: describeURL('_api/ModuleDefinition/DocumentNumberPlaceholders'),
            DOC_NUM_MOCK_PREVIEW: describeURL('post|_api/ModuleDefinition/DocumentNumberMockPreview'), // [Body] ModuleModel
            ROLES_DICT: describeURL('_api/ModuleDefinition/ModuleRoleUserDict'), // ?id={id}&contractId={contractId}&permissionId={permissionId}
            WORKFLOW_GROUPS: describeURL('_api/ModuleDefinition/WorkflowGroupsDict'), // ?id={id}
            COPY: describeURL('post|_api/ModuleDefinition/Copy'), // ?id=id[&roleId=roleId] [Body] { "details": <ModuleModel>, "sections": <array of sections to copy> }
            EXPORT: describeURL('post|_api/ModuleDefinition/Export'), // [?roleId=roleId] [Body] { "ids": <array of module IDs>, "sections": <array of sections to export> }
            IMPORT: describeURL('post|_api/ModuleDefinition/Import'), // [?roleId=roleId] [Body] <ModuleExportResponseModel> (the data returned by an export as is)
            SET_DIFINITION_FIELD: describeURL('put|_api/ModuleDefinition/SetDescriptionFieldId') // ?id={id}&fieldId={fieldId}
        },
        // Module types
        MODULE_TYPES: {
            TYPES_DICT: describeURL('_api/ModuleType/TypesDict'), // moduleId in header
        },
        //Module instance approval
        MODULE_APPROVAL: {
            CANCEL_APPROVAL: describeURL('put|_api/ModuleInstanceApproval/CancelApproval'), // ?approvalId={approvalId}&moduleId={moduleId}&contractId={contractId}
            DO_APPROVAL: describeURL('put|_api/ModuleInstanceApproval/DoApproval'), // [Body] DoApprovalModel  [Uri] ?moduleId={moduleId}&contractId={contractId}&roleId={roleId}
            ADD_COMMENT: describeURL('put|_api/ModuleInstanceApproval/AddComment') // ?moduleId={moduleId}&contractId={contractId} [Body] AddCommentApprovalModel
        },
        MODULE_RELATION: {
            SEARCH: describeURL('post|_api/ModuleRelation/Search'), // ?moduleTypeId={moduleTypeId} [Body] SearchViewModel
            GET_RELATIONS: describeURL('_api/ModuleRelation/AllRelations'), // ?moduleTypeId={moduleTypeId}
            ADD: describeURL('post|_api/ModuleRelation/Add'), // [Body] ModuleRelationModel
            EDIT: describeURL('put|_api/ModuleRelation/Edit'), // [Body] ModuleRelationModel
            DELETE: describeURL('put|_api/ModuleRelation/Delete'), // [Body] ids
            RELATION_TYPES_DICT: describeURL('_api/ModuleRelation/RelationTypesDict'),
            TOGGLE_PARTIAL_PAYMENT: describeURL('put|_api/ModuleRelation/TogglePartialPayment'), // [Body] TogglePartialPaymentModel
            DATASOURCE_DICT_RELATIONS: describeURL('_api/ModuleRelation/DataSourceRelationDict'),
            GET_RELATION_MAPPINGS: describeURL('_api/ModuleRelation/GetRelationStateMappings'), // ?relationId={relationId}
            SYNC_RELATION_MAPPINGS: describeURL('put|_api/ModuleRelation/SyncRelationStateMappings') // [Uri] ?relationId={relationId} [Body] ModuleRelationStatesMappingModel(s)
        },
        MODULE_REPORTS: {
            //moduleId needs to be passed in the header on all calls
            SEARCH: describeURL('post|_api/ModuleReport/Search'), // [Body] SearchViewModel  [Uri] ?relationId={relationId}&contractId={contractId}
            ALL: describeURL('post|_api/ModuleReport/All'), // [Uri] ?relationId={relationId}&contractId={contractId}
            GET: describeURL('_api/ModuleReport/Get'), // [Uri] ?id={id}&contractId={contractId}
            ADD: describeURL('post|_api/ModuleReport/Add'), // [Body] ModuleReportModel  [Uri] ?contractId={contractId}
            EDIT: describeURL('put|_api/ModuleReport/Edit'), // [Body] ModuleReportModel [Uri] ?contractId={contractId}
            DELETE: describeURL('put|_api/ModuleReport/Delete'), // [Uri] ?id={id}&contractId={contractId}
            FIELDS: describeURL('post|_api/ModuleReport/ModuleFields'), // [Body] SearchViewModel  [Uri] ?relationId={relationId}
            SYNC_FIELDS: describeURL('put|_api/ModuleReport/SyncFieldToReport'), // [Body] ModuleReportFieldModel (fields) [Uri] ?id={id}&contractId={contractId}
            SAVE_FILTERS: describeURL('put|_api/ModuleReport/SaveFilters'), // [Body] ReportFilterModel (filters)  [Uri] ?id={id}&contractId={contractId}
            DICT: describeURL('_api/ModuleReport/Dict') // [Uri] ?relationId={relationId}
        },
        MODULE_LINK_CORRESPONDENCE: {
            //moduleId needs to be passed in the header
            GET: describeURL('_api/LinkCorrespondenceToModule/Get'),
            CREATE: describeURL('post|_api/LinkCorrespondenceToModule/Create'), // [Body] LinkCorrespondenceToModuleModel
            UPDATE: describeURL('put|_api/LinkCorrespondenceToModule/Update'), // [Body] LinkCorrespondenceToModuleModel
            DELETE: describeURL('put|_api/LinkCorrespondenceToModule/Delete'), // [Uri] ?id={id}
            DICT: describeURL('_api/LinkCorrespondenceToModule/Dict')
        },
        //Modules instance
        MODULE: {
            //moduleId needs to be passed in the header on all calls
            //relationId is optional; used to get instances from a relation
            //parentEntityInstanceId is optional; used to get instances from a relation with a specific parent
            GRID_FIELDS: describeURL('_api/ModuleInstance/GridFields'), // [Uri] ?moduleId={moduleId}&contractId={contractId}&relationId={relationId}
            GRID_FIELDS_CHILDREN: describeURL('_api/ModuleInstance/GridFieldsChildren'), // [Uri] ?moduleId={moduleId}&contractId={contractId}&relationId={relationId}
            GRID_SEARCH: describeURL('post|_api/ModuleInstance/GridSearch'), // [Uri] ?moduleId={moduleId}&contractId={contractId}&relationId={relationId}&parentEntityInstanceId={parentEntityInstanceId} [Body] SearchViewModel; 
            GRID_SEARCH_CHILDREN: describeURL('post|_api/ModuleInstance/GridSearchChildren'), // [Uri] ?moduleId={moduleId}&contractId={contractId}&relationId={relationId}&parentEntityInstanceId={parentEntityInstanceId} [Body] SearchViewModel; 
            GET_TRANSITION_SCREEN: describeURL('_api/ModuleInstance/GetTransitionScreen'), // [Uri] ?moduleId={moduleId}&entityInstanceId={entityInstanceId}&transitionId={transitionId}&contractId={contractId}
            SAVE_TRANSITION_SCREEN: describeURL('post|_api/ModuleInstance/SaveTransitionScreen'), // [Body] ModuleScreenValuesModel [Uri] ?workflowId={workflowId}&moduleId={moduleId}&entityInstanceId={entityInstanceId}&transitionId={transitionId}&contractId={contractId}&relationId={relationId}&parentEntityInstanceId={parentEntityInstanceId}&roleId={roleId}
            SAVE_MULTI_TRANSITION_SCREEN: describeURL('post|_api/ModuleInstance/SaveMultipleTransitionScreens'), // [Body] ModuleScreenMultipleValuesModel [Uri] ?workflowId={workflowId}&moduleId={moduleId}&transitionId={transitionId}&contractId={contractId}&relationId={relationId}&parentEntityInstanceId={parentEntityInstanceId}&roleId={roleId}
            SAVE_PRESET: describeURL('post|_api/ModuleInstance/SaveTemplateInstance'), // [Body] ModuleScreenValuesModel [Uri] ?workflowId={workflowId}&moduleId={moduleId}&presetId={presetId}&transitionId={transitionId}&contractId={contractId}&roleId={roleId}
            SAVE_MULTI_PRESETS: describeURL('post|_api/ModuleInstance/SaveMultipleTemplateInstances'), // [Body] ModuleTemplatesMultipleValuesModel [Uri] ?workflowId={workflowId}&moduleId={moduleId}&transitionId={transitionId}&contractId={contractId}&roleId={roleId}
            SAVE_DRAFT: describeURL('post|_api/ModuleInstance/SaveDraftInstance'), // [Body] ModuleScreenValuesModel [Uri] ?workflowId={workflowId}&moduleId={moduleId}&entityInstanceId={entityInstanceId}&contractId={contractId}&roleId={roleId}
            SAVE_MULTI_DRAFT: describeURL('post|_api/ModuleInstance/SaveMultipleDraftInstances'), // [Body] ModuleScreenMultipleValuesModel [Uri] ?moduleId={moduleId}&contractId={contractId}&roleId={roleId}
            SAVE_REVISE: describeURL('post|_api/ModuleInstance/SaveReviseInstance'), // [Body] ModuleScreenValuesModel [Uri] ?moduleId={moduleId}&entityInstanceId={entityInstanceId}&contractId={contractId}&roleId={roleId}
            SAVE_MULTI_REVISE: describeURL('post|_api/ModuleInstance/SaveMultipleReviseInstances'), // [Body] ModuleScreenMultipleValuesModel [Uri] ?moduleId={moduleId}&contractId={contractId}&roleId={roleId}
            UPDATE: describeURL('put|_api/ModuleInstance/UpdateFieldValuesDocument'), // [Body] ModuleScreenValuesModel [Uri] ?moduleId={moduleId}&entityInstanceId={entityInstanceId}&contractId={contractId}
            ADD_COMMENT: describeURL('post|_api/ModuleInstance/AddComment'), // [Body] ModuleScreenValuesModel [Uri] ?entityInstanceId={entityInstanceId}&actionId={actionId}&roleId={roleId}
            EDIT_DOCUMENT_NUMBER: describeURL('put|_api/ModuleInstance/UpdateDocumentNumber'), // [Body] UpdateDocumentNumberModel
            SYNC_BULK_VALUES: describeURL('put|_api/ModuleInstance/SyncBulkValues'), // [Body] List of custom objects [Uri] ?moduleId={moduleId}&contractId={contractId}
            DELETE: describeURL('put|_api/ModuleInstance/Delete'), // [Uri] ?moduleId={moduleId}&entityInstanceId={entityInstanceId}&contractId={contractId}
            DELETE_MULTIPLE: describeURL('put|_api/ModuleInstance/DeleteMultiple'), // [Body] entityInstanceIds [Uri] ?moduleId={moduleId}&entityId={entityId}
            TRANSITIONS: describeURL('_api/ModuleInstance/GetTransitions'), // [Uri] ?moduleId={moduleId}&entityInstanceId={entityInstanceId}&contractId={contractId}
            MODULE_ROLES_DICT: describeURL('_api/ModuleInstance/ModuleRolesDict'), // [Uri] ?moduleId={moduleId}&contractId={contractId}&excludeRoleId={excludeRoleId}
            TAGG_USERS_DICT: describeURL('_api/ModuleInstance/TaggingUsersDict'), // [Uri] ?contractId={contractId}
            DOC_NUM_AVAILABLE: describeURL('_api/ModuleInstance/AvailableDocumentNumberDict'), // [Uri] ?moduleId={moduleId}&contractId={contractId}&entityInstanceId={entityInstanceId}
            SYNC_EXCLUDED_ROLES: describeURL('put|_api/ModuleInstance/SyncExcludedRoles'), // [Body] roleToExcludeIds [Uri] ?moduleId={moduleId}&contractId={contractId}&actionInstanceId={actionInstanceId}
            GET_INSTANCE_AS_PDF: describeURL('_api/ModuleInstance/GetInstanceAsPDF'), // [Uri] ?moduleId={moduleId}&contractId={contractId}&entityInstanceId={entityInstanceId}
            GET_INSTANCES_AS_PDF: describeURL('post|_api/ModuleInstance/GetInstancesAsPDF'), // [Uri] ?moduleId={moduleId}&contractId={contractId}&title={title} [Body]={entityInstanceIds: []}}
            GET_INSTANCES_AS_SEPARATE_PDFS: describeURL('post|_api/ModuleInstance/GetInstancesAsSeparatePDFs'), // [Uri] ?moduleId={moduleId}&contractId={contractId}&printDefault={printDefault} [Body] entityInstancesIds
            GET_AS_EXCEL: describeURL('post|_api/ModuleInstance/GetAsExcel'), // [Uri] ?moduleId={moduleId}&contractId={contractId}&relationId={relationId}&parentEntityInstanceId={parentEntityInstanceId} [Body] SearchViewModel; 
            GET_ACCESS_LOG: describeURL('post|_api/ModuleInstance/GetAccessLogEntityInstance'), // [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId} [Body] SearchViewModel; 
            SYNC_RELATION_INSTANCES: describeURL('put|_api/ModuleInstance/SyncRelationFieldValues'), // [Body] ids [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}&relationId={relationId}
            GET_RELATION_FORMULA_VALUES: describeURL('post|_api/ModuleInstance/GetRelationExpressionValues'), // [Uri] ?moduleId={moduleId}&contractId={contractId}&fieldId={fieldId} [Body] entities
            GENERATE_SIGN_DOCUMENT: describeURL('post|_api/ModuleInstance/GenerateSignDocument'), // [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}
            CHECK_SIGN_TRANSITION: describeURL('_api/ModuleInstance/CheckSignTransition'), // [Uri] ?transitionId={transitionId}
            SET_PREFERRED_VALUE: describeURL('put|_api/ModuleInstance/SetPreferredValue'), // [Uri] ?actionId={actionId}&actionInstanceId={actionInstanceId}&fieldId={fieldId}&contractId={contractId}
            GET_PREFERRED_VALUES: describeURL('_api/ModuleInstance/GetPreferredValues'), // [Uri] ?actionId={actionId}&entityInstanceId={entityInstanceId}&contractId={contractId}
            EXCEL_COLUMNS: describeURL('post|_api/ModuleInstance/GetColumnsFromExcel'), // [Body] file
            EXCEL_COLUMNS_BY_SHEET: describeURL('post|_api/ModuleInstance/GetColumnsFromExcelBySheet'), // [Uri] ?fileId={fileId}&sheet={sheet}
            EXPORT_TEMPLATE: describeURL('_api/ModuleInstance/ExportListTemplateToExcel'), 
            IMPORT_INSTANCES: describeURL('put|_api/ModuleInstance/ImportModuleInstances'), // [Body] ok   [Uri] ?contractId={contractId}
            RECREATE_PDF: describeURL('_api/ModuleInstance/RecreatePdfOnBlob'), // [Uri] ?entityInstanceId={entityInstanceId}
            ROLLBACK: describeURL('put|_api/ModuleInstance/RollbackLastTransition'), // [Uri] ?actionInstanceId={actionInstanceId}&contractId={contractId}
            GET_FORMULA_VALUE: describeURL('put|_api/ModuleInstance/GetFormulaFieldValue'), // [Uri] ?fieldId={fieldId}&contractId={contractId}   [Body] ModuleScreenValuesModel
            GET_ATTACHEMENTS_SIZE: describeURL('post|_api/ModuleInstance/GetAttachementsSize'), // [Uri] ?contractId={contractId} [Body]={entityInstanceIds: []}}
            BACKGROUND_DOWNLOAD_INSTANCES: describeURL('post|_api/ModuleInstance/BackgroundDownloadInstances'), // [Uri] ?title={title} [Body]={entityInstanceIds: []}}
            GET_WORKFLOW: describeURL('_api/ModuleInstance/GetWorkflowId'), // [Uri] ?entityInstanceId={entityInstanceId}&moduleId={moduleId}
            GET_EMAIL_DATA: describeURL('_api/ModuleInstance/GetActionEmailData'), // [Uri] ?entityInstanceId={entityInstanceId}&actionId={actionId}&contractId={contractId}
            SEND_EMAIL: describeURL('put|_api/ModuleInstance/SendActionEmail'), // [Body] SendEmailActionModel [Uri] ?actionId={actionId}&entityInstanceId={entityInstanceId}&contractId={contractId}
            CHECK_REQUIRED: describeURL('post|_api/ModuleInstance/CheckRequiredFieldsSaveMultipleInstances'), // [Body] entityInstanceIds [Uri] ?transitionId={transitionId}
            SAVE_EMAIL_ACTION: describeURL('put|_api/ModuleInstance/SaveEmailAction'), // [Body] SaveEmailActionModel [Uri] ?contractId={contractId}

            // new calls for getting info
            GET_HEADER: describeURL('_api/ModuleInstance/GetHeaderEntityInstance'), // [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}
            GET_GENERAL_INFO: describeURL('_api/ModuleInstance/GetGeneralInformationEntityInstance'), // [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}
            GET_FIELDS: describeURL('_api/ModuleInstance/GetFieldsEntityInstance'), // [Uri] ?workflowId={workflowId}&entityInstanceId={entityInstanceId}&transitionId={transitionId}&contractId={contractId}&actionId={actionId}&excludeRoleId={excludeRoleId}&excludeRoleId={excludeRoleId}&screenId={screenId}
            GET_ASSIGNMENT: describeURL('_api/ModuleInstance/GetAssignmentEntityInstance'), // [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}
            GET_ASSIGNMENT_HISTORY: describeURL('_api/ModuleInstance/GetAssignmentHistoryEntityInstance'), // [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}
            GET_SIGNERS: describeURL('_api/ModuleInstance/GetSignersEntityInstance'), // [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}
            GET_TRANSITIONS: describeURL('_api/ModuleInstance/GetTransitionsEntityInstance'), // [Uri] ?workflowId={workflowId}&entityInstanceId={entityInstanceId}&contractId={contractId}
            GET_ACTIVITY_SUMMARY: describeURL('_api/ModuleInstance/GetActivitySummaryEntityInstance'), // [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}
            GET_ACTIVITY: describeURL('_api/ModuleInstance/GetActivityEntityInstance'), // [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}
            GET_TRANSITION_INSTANCE: describeURL('_api/ModuleInstance/GetTransitionInstanceInformations'), // [Uri] ?actionInstanceId={actionInstanceId}&contractId={contractId}&noFields={noFields}
            GET_TABLE_VALUES: describeURL('_api/ModuleInstance/GetTableFieldValues'), // [Uri] ?entityInstanceId={entityInstanceId}&fieldId={fieldId}&contractId={contractId}&getDisplayValue={getDisplayValue}
            GET_DUPLICATE_FIELDS: describeURL('_api/ModuleInstance/GetFieldsEntityInstanceToDuplicate'), // [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}
            GET_EMAILS_DATASOURCE: describeURL('put|_api/ModuleInstance/GetTransitionEmailsDatasource'), // [Body] values  [Uri] ?entityInstanceId={entityInstanceId}&transitionId={transitionId}&contractId={contractId}

            // Progress payment
            PP_GRID_FIELDS: describeURL('_api/ModuleInstance/GetGridFieldsEntityInstanceProgressPayments'), // [Uri] ?contractId={contractId}
            PP_GRID_SEARCH: describeURL('post|_api/ModuleInstance/GetEntityInstanceProgressPayments'), // ?parentEntityInstanceId={parentEntityInstanceId}&contractId={contractId} [Body] SearchViewModel
        },
        //Module workflow
        WORKFLOW:
        {
            //moduleId needs to be passed in the header
            ALL: describeURL('_api/WorkflowDefinition/ModuleWorkflows'),
            GET: describeURL('_api/WorkflowDefinition/Get'), // [Uri] ?id={id}
            ADD: describeURL('post|_api/WorkflowDefinition/Add'), // [Body] WorkflowModel
            EDIT: describeURL('put|_api/WorkflowDefinition/Edit'), // [Body] WorkflowModel
            SYNC_GEOMETRY: describeURL('put|_api/WorkflowDefinition/SyncWorkflowGeometry'), // [Body] WorkflowGeometryModel
            DELETE: describeURL('put|_api/WorkflowDefinition/Delete'), // [Uri] ?id={id}
            CHANGE_GROUP: describeURL('put|_api/WorkflowDefinition/ChangeWorkflowGroup'), // [Uri] ?id={id}&workflowGroupId={workflowGroupId}
            DUPLICATE: describeURL('put|_api/WorkflowDefinition/DuplicateWorkflow') // [Uri] ?id={id}&workflowGroupId={workflowGroupId}
        },
        //Module workflow groups
        WORKFLOW_GROUPS:
        {
            //moduleId needs to be passed in the header
            SEARCH: describeURL('post|_api/WorkflowGroup/Search'), // [Body] SearchViewModel
            GET: describeURL('_api/WorkflowGroup/Get'), // [Uri] ?id={id}
            CREATE: describeURL('post|_api/WorkflowGroup/Create'), // [Body] WorkflowGroupModel
            UPDATE: describeURL('put|_api/WorkflowGroup/Update'), // [Body] WorkflowGroupModel
            DELETE: describeURL('put|_api/WorkflowGroup/Delete'), // [Uri] ?id={id}
            DICT: describeURL('_api/WorkflowGroup/Dict'),
        },
        // Module actions
        ACTIONS:
        {
            SEARCH: describeURL('post|_api/Action/Search'), // [Body] SearchViewModel  [Uri] ?moduleTypeId={moduleTypeId} 
            ALL_COMMENTS: describeURL('_api/Action/AllComments'), // [Uri] ?moduleTypeId={moduleTypeId} 
            ADD_COMMENT: describeURL('post|_api/Action/AddComment'), // [Uri] ?moduleTypeId={moduleTypeId}   [Body] ActionModel 
            EDIT_COMMENT: describeURL('put|_api/Action/EditComment'), // [Uri] ?moduleTypeId={moduleTypeId}  [Body] ActionModel
            ADD_ASSIGNMENT: describeURL('post|_api/Action/AddAssignment'), // [Uri] ?moduleTypeId={moduleTypeId}   [Body] ActionModel 
            EDIT_ASSIGNMENT: describeURL('put|_api/Action/EditAssignment'), // [Uri] ?moduleTypeId={moduleTypeId}  [Body] ActionModel
            DELETE: describeURL('put|_api/Action/Delete'), // [Uri] ?id={id}
            ADD_PREFERRED_FIELD: describeURL('post|_api/Action/AddPreferredField'), // [Uri] ?moduleTypeId={moduleTypeId}   [Body] ActionModel 
            EDIT_PREFERRED_FIELD: describeURL('put|_api/Action/EditPreferredField'), // [Uri] ?moduleTypeId={moduleTypeId}  [Body] ActionModel
            ADD_EMAIL: describeURL('post|_api/Action/AddEmail'), // [Uri] ?moduleTypeId={moduleTypeId}   [Body] ActionModel 
            EDIT_EMAIL: describeURL('put|_api/Action/EditEmail'), // [Uri] ?moduleTypeId={moduleTypeId}  [Body] ActionModel
            FIELDS_DICT: describeURL('_api/Action/PreferredFields'), // [Uri] ?moduleTypeId={moduleTypeId}&id={id}
        },
        //Module Permissions
        PERMISSIONS: {
            //moduleId needs to be passed in the header
            SEARCH: describeURL('post|_api/ModulePermission/Search'), // [Body] SearchViewModel
            CREATE: describeURL('post|_api/ModulePermission/Create'), // [Body] PermissionModel
            UPDATE: describeURL('put|_api/ModulePermission/Update'), // [Body] PermissionModel
            DELETE: describeURL('put|_api/ModulePermission/Delete'), // [Body] permissionIds
            OPERATIONS_DICT: describeURL('_api/ModulePermission/OperationDict'), // [Uri] ?perContract={perContract}
            ROLES_DICT: describeURL('_api/ModulePermission/RolesDict')
        },
        // new role
        ROLES: {
            SEARCH: describeURL('post|_api/Role/Search'), // [Body] SearchViewModel
            DETAIL: describeURL('_api/Role/Get'), // [Uri] ?id={id}
            CREATE: describeURL('post|_api/Role/Create'), // [Body] RoleModel [Uri] ?roleId={roleId}
            UPDATE: describeURL('put|_api/Role/Update'), // [Body] RoleModel [Uri] ?roleId={roleId}
            DELETE: describeURL('put|_api/Role/Delete'), // [Uri] ?id={id}
            DICT: describeURL('_api/Role/Dict'), //[Uri] ?moduleTypeId={moduleTypeId}
            // users on a role
            ASSIGN_USERS_TO_ROLE: describeURL('put|_api/Role/AssignUsersToRole'), //[Body] AssignRoleUserModel
            // not needed
            ASSIGN_ROLES_TO_USER: describeURL('put|_api/Role/AssignRolesToUser'), //[Body] AssignUserRoleModel
            // assign contract to a user
            ASSIGN_CONTRACTS_TO_USER: describeURL('put|_api/Role/AssignUserRoleContracts'), //[Body] AssignUserRolePerContractModel
            // assign users to a contract

            ASSIGN_USERS_TO_CONTRACT: describeURL('put|_api/Role/AssignContractUserRole'), //[Body] AsignUserRolePerContractsModel
            SYNC_ROLES_TO_CONTRACT: describeURL('put|_api/Role/SyncUsersAndRolesForContract'), //[Body] SyncUsersAndRolesForContractModel
            SYNC_ROLES_TO_USER: describeURL('put|_api/Role/SyncContractsAndRolesForUser'), //[Body] SyncContractsAndRolesForUserModel
            GET_MODULES: describeURL('_api/Role/GetModules'), // [Uri] ?id={id}&moduleId={moduleId}&moduleTypeId={moduleTypeId}
            CONTRACTS_DICT: describeURL('_api/Role/ContractsDict'), // [Uri] ?userId={userId}
            TOGGLE_ROLE_DISABLE: describeURL('put|_api/Role/ToggleDisableRole'), // [Uri] ?id={id}
            ENABLE_DISABLE_ROLES: describeURL('put|_api/Role/EnableDisableRoles'), // [Body] EnableDisableRolesModel
            SYNC_PERMISSIONS: describeURL('put|_api/Role/SyncPermissionsFromRole'), // [Body] SyncPermissionsFromRoleModel
            GET_USERS: describeURL('post|_api/Role/GetUsers'), // [Body] SearchViewModel [Uri] ?contractId={contractId}
            GET_CONTRACTS: describeURL('post|_api/Role/GetContracts'), // [Body] SearchViewModel [Uri] ?userId={userId}
            // Module part
            GET_MODULE_ROLES: describeURL('post|_api/Role/SearchModuleRoles'), // [Body] SearchViewModel [Uri] ?moduleTypeId={moduleTypeId}
            ASSIGN_ROLES_TO_MODULETYPE: describeURL('put|_api/Role/SyncModuleRoles'), // [Body] AssignModuleRolesModel
            MODULE_ROLES_DICT: describeURL('_api/Role/ModuleRolesDict'), // [Uri] ?moduleTypeId={moduleTypeId}
            SYNC_MODULE_ROLES: describeURL('put|_api/Role/SyncPermissionsFromModule'), // [Body] SyncModuleTypePermissionModel
            GET_CONTRACT_PRIMARIES: describeURL('_api/Role/GetContractPrimary'), // [Uri] ?contractId={contractId}
            SYNC_CONTRACT_PRIMARIES: describeURL('put|_api/Role/SyncContractPrimary'), // [Body] SyncPrimaryUsersModel
            // moduleId needs to pe passed in header
            ROLE_USERS_DICT: describeURL('_api/Role/RoleUsersDict') // [Uri] ?id={id}
        },
        SCREEN_DEFINITION: {
            //if entityId is zero or not passed then current entity will be used; this is needed for module versioning
            //moduleId needs to be passed in the header
            SEARCH: describeURL('post|_api/Screen/Search'), // [Uri] ?type={type}&entityId={entityId}  [Body] SearchViewModel
            CREATE: describeURL('post|_api/Screen/Create'), // [Uri] ?entityId={entityId}  [Body] ScreenModel
            UPDATE: describeURL('put|_api/Screen/Update'), // [Uri] ?entityId={entityId}   [Body] ScreenModel
            DELETE: describeURL('put|_api/Screen/Delete'), // [Uri] ?id={id}
            DICT: describeURL('_api/Screen/Dict'), // [Uri] ?type={type}&entityId={entityId}
        },
        SCREEN_FIELDS: {
            SEARCH: describeURL('post|_api/ScreenFields/Search'), // [Uri] ?screenId={screenId}  [Body] SearchViewModel
            // Sync with simple field properties like widh, position, label, ...
            SYNC_FIELDS: describeURL('put|_api/ScreenFields/SyncFieldToScreen'), // [Uri] ?screenId={screenId}   [Body] ScreenFieldModel
            // Get and update with all field properties
            GET: describeURL('_api/ScreenFields/GetFieldDetails'), // [Uri] ?id={id}&screenId={screenId}
            UPDATE: describeURL('put|_api/ScreenFields/UpdateScreenFieldDetails'), // [Uri] ?screenId={screenId}  [Body] DetailScreenFieldModel
            FIELD_SCREENS_DICT: describeURL('_api/ScreenFields/ScreenFieldDict'), // [Uri] ?id={id}
            DATASOURCE_HAS_DEFAULT_VALUE: describeURL('_api/ScreenFields/CheckParentDataSourceHasDefaultValue'), // [Uri] ?id={id}&screenId={screenId}
            SCREEN_HAS_CONTRACT_FIELDS: describeURL('_api/ScreenFields/ScreenHasContractFields') // [Uri] ?screenId={screenId}
        },
        //Module screens
        SCREENS: {
            //if entityId is zero or not passed then current entity will be used; this is needed for module versioning
            //moduleId needs to be passed in the header
            SEARCH: describeURL('post|_api/ScreenDefinition/Search'), // [Body] SearchViewModel; SearchViewModel.Id={entityId}
            CREATE: describeURL('post|_api/ScreenDefinition/Create'), // [Uri] ?entityId={entityId} [Body] ModuleScreenModel
            UPDATE: describeURL('put|_api/ScreenDefinition/Update'), // [Uri] ?entityId={entityId} [Body] ModuleScreenModel
            DELETE: describeURL('put|_api/ScreenDefinition/Delete'), // [Uri] ?id={id}
            DICT: describeURL('_api/ScreenDefinition/ScreensDict'), // [Uri] ?entityId={entityId}

            FIELD_TYPE_DICT: describeURL('_api/ScreenDefinition/FieldTypesDict'),
            SYNC: describeURL('put|_api/ScreenDefinition/SyncFields'), // [Uri] ?entityId={entityId}&screenId={screenId} [Body] List of ModuleScreenfieldModel

            //if entityId is zero or not passed then current entity will be used; this is needed for module versioning
            //if screenId is zero or not passed then default screen will be used
            FIELD_CREATE: describeURL('post|_api/ScreenDefinition/CreateField'), // [Uri] ?entityId={entityId}&screenId={screenId} [Body] ModuleScreenfieldModel
            FIELD_UPDATE: describeURL('put|_api/ScreenDefinition/UpdateField'), // [Uri] ?entityId={entityId}&screenId={screenId} [Body] ModuleScreenfieldModel
            FIELD_DELETE: describeURL('put|_api/ScreenDefinition/DeleteField'), // [Uri] ?id={id}&entityId={entityId}

            //entityId - if zero or missing then current entity will be used
            //screenId - if zero or missing then only fields from the given screen are returned; otherwise from all screens
            FIELDS: describeURL('_api/ScreenDefinition/GetScreenFields'), // [Uri] ?entityId={entityId}&screenId={screenId}

            //gets numeric fields as a dictionary, usefull to create formula field expressions
            NUMERIC_FIELDS_DICT: describeURL('_api/ScreenDefinition/NumericFieldsDict'), // [Uri] ?entityId={entityId}
            PAYMENT_NUMERIC_FIELDS_DICT: describeURL('_api/ScreenDefinition/PaymentNumericFieldsDict'), // [Uri] ?entityId={entityId}

            //entityId - if zero or missing then current entity will be used
            //screenId - if zero or missing then only fields from the given screen are returned; otherwise from all screens
            PROP_FIELDS: describeURL('_api/ScreenDefinition/GetPropertyFields'), // [Uri] ?entityId={entityId}&screenId={screenId}
            PROP_FIELDS_CREATE: describeURL('post|_api/ScreenDefinition/CreatePropertyField'), // [Uri] ?entityId={entityId} [Body] ModuleFieldModel
            PROP_FIELDS_UPDATE: describeURL('put|_api/ScreenDefinition/UpdatePropertyField'), // [Uri] ?entityId={entityId} [Body] ModuleFieldModel
            PROP_FIELDS_DELETE: describeURL('put|_api/ScreenDefinition/DeletePropertyField'), // [Uri] ?id={id}&entityId={entityId}

            //gets aggregated expressions from child module type fields as a dictionary
            AGG_EXPR_FROM_CHILD_MODULE_DICT: describeURL('_api/ScreenDefinition/AggregatedExpressionsFromChildModuleFieldsDict') // [Uri] ?entityId={entityId}
        },
        MODULE_FIELDS: {
            //if entityId is zero or not passed then current entity will be used; this is needed for module versioning
            //moduleId needs to be passed in the header
            SYNC: describeURL('put|_api/ModuleGridFields/SyncFields'), // [Uri] ?entityId={entityId}&relationId={relationId} [body] ModuleGridFieldModel
            FIELDS: describeURL('_api/ModuleGridFields/GetFields'), // [Uri] ?entityId={entityId}&relationId={relationId}&visibleOnGrid={visibleOnGrid}
            DATASOURCE_DICT: describeURL('_api/ModuleGridFields/DataSourceDict'), // [Uri] ?withParent={withParent}
            DATASOURCE_DICT_RELATIONS: describeURL('_api/ModuleGridFields/DataSourceRelationDict'),
            DATASOURCE_GET: describeURL('_api/ModuleGridFields/GetUserDefinedDataSource'), // [Uri] ?id={id}
            DATASOURCE_SYNC: describeURL('put|_api/ModuleGridFields/SyncDataSource'), // [Body] KeyValueChildrenModel [Uri] ?roleId={roleId}
            DATASOURCE_SYNC_ELEMENTS: describeURL('put|_api/ModuleGridFields/SyncDataSourceElements'), // [Body] List of KeyValueChildrenModel [Uri] ?id={id}
            DATASOURCE_DELETE: describeURL('put|_api/ModuleGridFields/DeleteDataSourceElements'), // [Uri] ?id={id}
            GET_RELATION_MAPPINGS: describeURL('_api/ModuleGridFields/GetRelationStateMappings'), // [Uri] ?relationId={relationId}
            SYNC_RELATION_MAPPINGS: describeURL('put|_api/ModuleGridFields/SyncRelationStateMappings'), // [Uri] ?relationId={relationId} [Body] ModuleRelationStatesMappingModel(s)
            SCREEN_FIELDS_DICT: describeURL('_api/ModuleGridFields/ScreenFieldsDict'), // [Uri] ?screenId={screenId}
            CONDITIONAL_FIELDS_DICT: describeURL('_api/ModuleGridFields/ConditionalScreenFieldsDict'), // [Uri] ?screenId={screenId}
            TOGGLE_NUMBER_MONEY_FIELD: describeURL('put|_api/ModuleGridFields/ToggleNumberMoney'), // [Uri] ?id={id}
            TOP_FORMULAS_DICT: describeURL('_api/ModuleGridFields/TopFormulasDict') // [Uri] ?type={type}
        },
        GRID_EXPRESSIONS: {
            //if entityId is zero or not passed then current entity will be used; this is needed for module versioning
            //type is optional, default to 0 = none, 1 = top, 2 = bottom
            //if top is not passed or 0 then all expressions will be used, when passed > 0 then will get or sync only those
            //moduleId needs to be passed in the header
            //relationId is optional for module expressions; instead for expressions over a relation we need the relation id
            GET: describeURL('_api/ModuleGridFields/GetExpressions'), // [Uri] ?entityId={entityId}&relationId={relationId}&type={type}
            SYNC: describeURL('put|_api/ModuleGridFields/SyncExpressions'), // [Uri] ?entityId={entityId}&relationId={relationId}&type={type} [body] ModuleGridExpressionModel

            //will return functions that can be used in formula expressions, like: SUM, AVG, MIN, MAX, COUNT
            FN_DICT: describeURL('_api/ModuleGridFields/ExpressionFunctionDict')
        },
        MODULE_PRESETS_TYPES: {
            //moduleId needs to be passed in the header
            SEARCH: describeURL('post|_api/EntityTemplateTypes/Search'), // [Body] SearchViewModel
            GET: describeURL('_api/EntityTemplateTypes/Get'), // [Uri] ?id={id}
            CREATE: describeURL('post|_api/EntityTemplateTypes/Create'), // [Body] EntityTemplateTypeModel
            UPDATE: describeURL('put|_api/EntityTemplateTypes/Update'), // [Body] EntityTemplateTypeModel
            DELETE: describeURL('put|_api/EntityTemplateTypes/Delete'), // [Uri] ?id={id}
            DICT: describeURL('_api/EntityTemplateTypes/Dict')
        },
        MODULE_PRESETS: {
            //moduleId needs to be passed in the header
            SEARCH: describeURL('post|_api/EntityTemplates/Search'), // [Body] SearchViewModel   [Uri] ?contractId={contractId}
            GET: describeURL('_api/EntityTemplates/Get'), // [Uri] ?id={id}
            CREATE: describeURL('post|_api/EntityTemplates/Create'), // [Body] EntityTemplateModel
            UPDATE: describeURL('put|_api/EntityTemplates/UpdatePreset'), // [Body] EntityTemplateModel
            DELETE: describeURL('put|_api/EntityTemplates/Delete'), // [Uri] ?id={id}
            DICT: describeURL('_api/EntityTemplates/Dict'),
            CONTRACT_TYPES_DICT: describeURL('_api/EntityTemplates/ContractTypesDict'),
            CONTRACT_DICT: describeURL('_api/EntityTemplates/ContractsDict'), // [Uri] ?documentNumber={documentNumber}&documentNoMinor={documentNoMinor}
            ALL_MODULES_DICT: describeURL('_api/EntityTemplates/ModulesWithPresetsDict'), // [Uri] ?contractId={contractId}
            CONTRACT_ALLOCATION_DICT: describeURL('_api/EntityTemplates/PresetModuleContractAllocationDict'), // [Uri]?contractId = { contractId }
            EICONTRACT_ALLOCATION_DICT: describeURL('_api/EntityTemplates/PresetModuleContractEIAllocationDict'), // [Uri]?contractId = { contractId }
            SYNC: describeURL('post|_api/EntityTemplates/SyncPresetsWithContract'), // [Body] LIST[EntityTemplateContractAllocationModel]   [Uri] ?contractId={contractId}
            SYNC_ON_EI: describeURL('post|_api/EntityTemplates/SyncPresetsWithContractEI'), // [Body] LIST[EntityTemplateContractAllocationModel]   [Uri] ?contractId={contractId}
            AVAILABLE_NUMBERS_DICT: describeURL('_api/EntityTemplates/AvailableNumbersDict'), // [Uri] ?contractType={contractType}
            GET_FIELDS: describeURL('_api/EntityTemplates/GetFieldsEntityInstance') // [Uri] ?workflowId={workflowId}&screenId={screenId}
        },
        MODULE_SIGNATURE: {
            GET: describeURL('_api/ModuleSignature/Get'), // [Uri] ?id={id}
            GET_WITH_SECRET: describeURL('_api/ModuleSignature/GetWithSecret'), // [Uri] ?id={id}&secret={secret}
            SET_SIGNED: describeURL('put|_api/ModuleSignature/SetHasSigned') // [Uri] ?id={id}&contractId={contractId}
        },
        MODULE_SIGNATURE_ANONYMOUS: {
            GET: describeURL('_api/ModuleSignatureAnonymous/Get'), // [Uri] ?parameters={parameters}
            SET: describeURL('put|_api/ModuleSignatureAnonymous/SetHasSigned') // [Uri] ?parameters={parameters}
        },
        MODULE_EXTRA_SIGNATURES: {
            // the moduleId needs to be passed in the header
            SEARCH: describeURL('post|_api/ModuleSignature/SearchUserRole'), // [Body] SearchViewModel  [Uri] ?workflowId={workflowId}
            CREATE: describeURL('post|_api/ModuleSignature/CreateUserRole'), // [Body] AssignUserRolesSignatureModel
            UPDATE: describeURL('put|_api/ModuleSignature/UpdateUserRole'), // [Body] AssignUserRolesSignatureModel
            DELETE: describeURL('put|_api/ModuleSignature/DeleteUserRoles') // [Body] ids
        },
        STATES: {
            //if workflowId is zero or not passed then first workflow will be used; this is needed for module versioning
            //moduleId needs to be passed in the header
            DRAFT_DETAILS: describeURL('_api/StateDefinition/DraftDetails'), // [Uri] ?workflowId={workflowId}
            DRAFT_EDIT: describeURL('put|_api/StateDefinition/UpdateDraftDetails'), // [Body] DraftStateModel  [Uri] ?workflowId={workflowId}
            LIST: describeURL('_api/StateDefinition/List'), // [Uri] ?workflowId={workflowId}
            ADD: describeURL('post|_api/StateDefinition/Add'), // [Uri] ?workflowId={workflowId} [Body] WorkflowStateModel
            EDIT: describeURL('put|_api/StateDefinition/Edit'), // [Uri] ?workflowId={workflowId} [Body] WorkflowStateModel
            DELETE: describeURL('put|_api/StateDefinition/Delete'), // [Uri] ?id={id}
            DICT: describeURL('_api/StateDefinition/Dict'), // [Uri] ?workflowId={workflowId}&typeId={typeId}
            INITIATOR_DICT: describeURL('_api/StateDefinition/InitiatorRolesDict'), // [Uri] ?workflowId={workflowId}
            TYPES_DICT: describeURL('_api/StateDefinition/TypeDict'),
            SYNC_ORDER: describeURL('put|_api/StateDefinition/SyncOrder'), // [Body] states
            CLOSED_STATES_DICT: describeURL('_api/StateDefinition/ClosedStatesDict') // [Uri] ?workflowId={workflowId}
        },
        STATE_ASSIGNMENT:
        {
            ASSIGN_USER: describeURL('put|_api/StateAssignment/AssignUser'), // [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}&moduleId={moduleId} [Body] KeyValueRoleModel
        },
        NOTIFICATION:
        {
            MODULES_LIST: describeURL('_api/Notification/GetModulesWithNotificationStatus'), // [Uri] ?contractId={contractId}
            TOGGLE_PER_MODULE: describeURL('put|_api/Notification/ToggleModuleNotification'), // [Uri] ?contractId={contractId}&moduleId={moduleId}
            TOGGLE_GLOBAL: describeURL('put|_api/Notification/ToggleGlobalNotification'), // [Uri] ?isOn={0/1}&contractId={contractId}
        },
        //Module templates
        TEMPLATES: {
            //if entityId is zero or not passed then current entity will be used; this is needed for module versioning
            //moduleId needs to be passed in the header
            SEARCH: describeURL('post|_api/TemplateDefinition/Search'), // [Body] SearchViewModel; SearchViewModel.Id={entityId}&typeName={typeName}&screenId={screenId}
            SEARCH_WITH_PERMISSIONS: describeURL('post|_api/TemplateDefinition/SearchWithPermission'), // [Body] SearchViewModel; SearchViewModel.Id={entityId}&typeName={typeName}&screenId={screenId}
            GET: describeURL('_api/TemplateDefinition/Get'), // [Uri] ?id={id}
            CREATE: describeURL('post|_api/TemplateDefinition/Create'), // [Uri] ?entityId={entityId} [Body] ModuleTemplateModel
            UPDATE: describeURL('put|_api/TemplateDefinition/Update'), // [Uri] ?entityId={entityId} [Body] ModuleTemplateModel
            DELETE: describeURL('put|_api/TemplateDefinition/Delete'), // [Uri] ?id={id}
            TEMPLATE: describeURL('put|_api/TemplateDefinition/UpdateTemplate'), // [Body] ModuleTemplateDefinitionModel
            DICT: describeURL('_api/TemplateDefinition/PlaceholdersDict'), // [Uri] ?id={id}&entityInstanceId={entityInstanceId}&contractId={contractId}
            DESCRIPTION_DICT: describeURL('_api/TemplateDefinition/PlaceholdersDescriptionDict'), // [Uri] ?id={id}&entityInstanceId={entityInstanceId}&contractId={contractId}
            TEMPLATES_DICT: describeURL('_api/TemplateDefinition/TemplatesDict'), // [Uri] ?entityId={entityId}&typeName={typeName}&withConditionals={withConditionals}&screenId={screenId}
            USER_TEMPLATES_DICT: describeURL('_api/TemplateDefinition/UserTemplatesDict'), // [Uri] ?entityId={entityId}&contractId={contractId}&typeName={typeName}&screenId={screenId}
            PREVIEW: describeURL('post|_api/TemplateDefinition/PreviewTemplate'), // [Uri] ?id={id}&entityInstanceId={entityInstanceId}&contractId={contractId}
            PREVIEWS: describeURL('post|_api/TemplateDefinition/PreviewTemplates'), // [Uri] ?contractId={contractId} [Body] entityInstanceIds
            SET_DEFAULT_TEMPLATE: describeURL('put|_api/TemplateDefinition/SetDefaultTemplate'), // [Uri] ?id={id}
            SET_TAG_TEMPLATE: describeURL('put|_api/TemplateDefinition/SetTagTemplate'), // [Uri] ?id={id}
            UNSET_TAG_TEMPLATE: describeURL('put|_api/TemplateDefinition/UnSetTagTemplate'), // [Uri] ?id={id}
            BULK_SET_HEADER_FOOTER: describeURL('put|_api/TemplateDefinition/SetHeaderFooter'), // [Body] SetHeaderFooterModel

            // Templates group
            SEARCH_GROUP: describeURL('post|_api/TemplateDefinition/SearchGroup'), // [Body] SearchViewModel   SearchViewModel.Id={entityId}&screenId={screenId}
            GET_CONDITIONALS: describeURL('_api/TemplateDefinition/GetConditionals'), // [Uri] ?id={id}
            CREATE_GROUP: describeURL('post|_api/TemplateDefinition/CreateGroup'), // [Body] ModuleTemplatesGroupModel
            UPDATE_GROUP: describeURL('put|_api/TemplateDefinition/UpdateGroup'), // [Body] ModuleTemplatesGroupModel
            DELETE_GROUP: describeURL('put|_api/TemplateDefinition/DeleteGroup'), // [Uri] ?id={id}
            CREATE_CONDITION: describeURL('post|_api/TemplateDefinition/CreateGroupCondition'), // [Body] ConditionalTemplateModel
            UPDATE_CONDITION: describeURL('put|_api/TemplateDefinition/UpdateGroupCondition'), // [Body] ConditionalTemplateModel
            DELETE_CONDITION: describeURL('put|_api/TemplateDefinition/DeleteGroupCondition'), // [Uri] ?id={id}
            SYNC_CONDITIONS: describeURL('put|_api/TemplateDefinition/SyncGroupConditions'), // [Uri] ?id={id} [Body] List ConditionalTemplateModel
            SET_DEFAULT_GROUP_TEMPLATE: describeURL('put|_api/TemplateDefinition/SetDefaultGroupTemplate') // [Uri] ?id={id}
        },
        //Module transitions
        TRANSITION: {
            //moduleId needs to be passed in the header
            LIST: describeURL('_api/TransitionDefinition/List'), // [Uri] ?stateId={stateId}
            DRAFT_LIST: describeURL('_api/TransitionDefinition/DraftList'), // [Uri] ?workflowId={workflowId}
            LIST_GLOBAL: describeURL('_api/TransitionDefinition/ListGlobal'), // [Uri] ?workflowId={workflowId}
            ADD: describeURL('post|_api/TransitionDefinition/Add'), // [Body] WorkflowTransitionModel  [Uri] ?workflowId={workflowId}
            EDIT: describeURL('put|_api/TransitionDefinition/Edit'), // [Body] WorkflowTransitionModel  [Uri] ?workflowId={workflowId}
            DELETE: describeURL('put|_api/TransitionDefinition/Delete'), // [Uri] ?id={id}
            SYNC_ORDER: describeURL('put|_api/TransitionDefinition/SyncOrder'), // [Body] transitions
            SIGN_LIST: describeURL('_api/TransitionDefinition/SignList'), // [Uri] ?workflowId={workflowId}
            ADD_GLOBAL: describeURL('post|_api/TransitionDefinition/AddGlobal'), // [Body] WorkflowTransitionModel  [Uri] ?workflowId={workflowId}
            EDIT_GLOBAL: describeURL('put|_api/TransitionDefinition/EditGlobal'), // [Body] WorkflowTransitionModel  [Uri] ?workflowId={workflowId}
            ADD_CONDITIONAL: describeURL('post|_api/TransitionDefinition/AddConditionalTransition'), // [Body] WorkflowConditionalTransitionModel
            EDIT_CONDITIONAL: describeURL('put|_api/TransitionDefinition/EditConditionalTransition'), // [Body] WorkflowConditionalTransitionModel
            DELETE_CONDITIONAL: describeURL('put|_api/TransitionDefinition/DeleteConditionalTransition'), // [Uri] ?id={id}
            SYNC_ORDER_CONDITIONALS: describeURL('put|_api/TransitionDefinition/SyncConditionalOrder'), // [Body] conditional transitions
            OPERATORS_DICT: describeURL('_api/TransitionDefinition/OperatorsDict'), // [Uri] ?type={type}
            GET_CONDITIONAL_TRANSITIONS: describeURL('_api/TransitionDefinition/GetConditionalTransitions'), // [Uri] ?transitionId={transitionId}
            GET_ALL_CONDITIONAL_TRANSITIONS: describeURL('_api/TransitionDefinition/GetAllConditionalTransitions'), // [Uri] ?workflowId={workflowId}
            CONDITIONAL_FIELDS_DICT: describeURL('_api/TransitionDefinition/ConditionalsFieldsDict') // [Uri] ?screenId={screenId}
        },
        TRANSITION_GROUP: {
            SEARCH: describeURL('post|_api/ModuleGroupTransitionSign/Search'), // [Body] SearchViewModel  [Uri] ?workflowId={workflowId}
            GET: describeURL('_api/ModuleGroupTransitionSign/Get'), // [Uri] ?id={id}
            ADD: describeURL('post|_api/ModuleGroupTransitionSign/Add'), // [Body] ModuleGroupTransitionsModel  [Uri] ?workflowId={workflowId}
            EDIT: describeURL('put|_api/ModuleGroupTransitionSign/Edit'), // [Body] ModuleGroupTransitionsModel  [Uri] ?workflowId={workflowId}
            DELETE: describeURL('put|_api/ModuleGroupTransitionSign/Delete'), // [Uri] ?id={id}
            SYNC_ORDER: describeURL('put|_api/ModuleGroupTransitionSign/SyncOrder'), // [Body] GroupSyncOrder
            SIGN_LIST_DICT: describeURL('_api/ModuleGroupTransitionSign/SignList'), // [Uri] ?workflowId={workflowId}
        },
        //Schedule Shifts
        SCHEDULE_SHIFT: {
            SEARCH: describeURL('post|_api/ScheduleShift/Search'), // [Body] SearchViewModel
            GET: describeURL('_api/ScheduleShift/Get'), // [Uri] ?id={id}
            CREATE: describeURL('post|_api/ScheduleShift/Create'), // [Body] ScheduleShiftModel
            UPDATE: describeURL('put|_api/ScheduleShift/Update'), // [Body] ScheduleShiftModel
            DELETE: describeURL('put|_api/ScheduleShift/Delete'), // [Uri] ?id={id}
            DICT: describeURL('_api/ScheduleShift/Dict')
        },
        //Schedule Days
        SCHEDULE_DAY: {
            SEARCH: describeURL('post|_api/ScheduleDay/Search'), // [Body] SearchViewModel
            GET: describeURL('_api/ScheduleDay/Get'), // [Uri] ?id={id}
            GET_FULL: describeURL('_api/ScheduleDay/GetFull'), // [Uri] ?id={id}
            CREATE: describeURL('post|_api/ScheduleDay/Create'), // [Body] ScheduleDayModel
            UPDATE: describeURL('put|_api/ScheduleDay/Update'), // [Body] ScheduleDayModel
            DELETE: describeURL('put|_api/ScheduleDay/Delete'), // [Uri] ?id={id}
            DICT: describeURL('_api/ScheduleDay/Dict')
        },
        //Schedules
        SCHEDULE: {
            SEARCH: describeURL('post|_api/Schedule/Search'), // [Body] SearchViewModel
            GET: describeURL('_api/Schedule/Get'), // [Uri] ?id={id}
            CREATE: describeURL('post|_api/Schedule/Create'), // [Body] ScheduleModel [Uri] ?roleId={roleId}
            UPDATE: describeURL('put|_api/Schedule/Update'), // [Body] ScheduleModel [Uri] ?roleId={roleId}
            DELETE: describeURL('put|_api/Schedule/Delete'), // [Uri] ?id={id}
            DICT: describeURL('_api/Schedule/Dict'),
            CALCULATE_DAYS_OF_SCHEDULE: describeURL('_api/Schedule/CalculateDaysOfSchedule') // [Uri] ?schedule_id={schedule_id}&isWorkDay={isWorkDay}&startDate={startDate}&duration={duration}&endDate={endDate}
        },
        //Financial Schedules
        FINANCIAL_SCHEDULE: {
            SEARCH: describeURL('post|_api/FinancialSchedule/Search'), // [Body] SearchViewModel
            GET: describeURL('_api/FinancialSchedule/Get'), // [Uri] ?id={id}
            CREATE: describeURL('post|_api/FinancialSchedule/Create'), // [Body] FinancialScheduleModel [Uri] ?roleId={roleId}
            UPDATE: describeURL('put|_api/FinancialSchedule/Update'), // [Body] FinancialScheduleModel [Uri] ?roleId={roleId}
            DELETE: describeURL('put|_api/FinancialSchedule/Delete'), // [Uri] ?id={id}
            INTERVAL_DICT: describeURL('_api/FinancialSchedule/IntervalDict'),
            DICT: describeURL('_api/FinancialSchedule/Dict')
        },
        //Holidays
        HOLIDAY: {
            SEARCH: describeURL('post|_api/Holiday/Search'), // [Body] SearchViewModel
            GET: describeURL('_api/Holiday/Get'), // [Uri] ?id={id}
            CREATE: describeURL('post|_api/Holiday/Create'), // [Body] HolidayModel [Uri] ?roleId={roleId}
            UPDATE: describeURL('put|_api/Holiday/Update'), // [Body] HolidayModel [Uri] ?roleId={roleId}
            DELETE: describeURL('put|_api/Holiday/Delete'), // [Uri] ?id={id}
            DICT: describeURL('_api/Holiday/Dict')
        },
        //Tenant settings
        TENANT_SETTINGS: {
            LIST: describeURL('get|_api/TenantSettings/List'), 
            GET: describeURL('_api/TenantSettings/Get'), // [Uri] ?id={id}
            UPDATE: describeURL('put|_api/TenantSettings/Update'), // [Body] TenantSettingsModel
            DICT: describeURL('_api/TenantSettings/Dict')
        },
        //Header / footer template settings
        HEADER_FOOTER_SETTINGS: {
            SEARCH: describeURL('post|_api/HeaderFooterSettings/Search'), // [Body] SearchViewModel
            DETAILS: describeURL('_api/HeaderFooterSettings/Get'), // [Uri] ?id={id}
            CREATE: describeURL('post|_api/HeaderFooterSettings/Create'), // [Body] HeaderFooterModel
            UPDATE: describeURL('put|_api/HeaderFooterSettings/Update'), // [Body] HeaderFooterModel
            DELETE: describeURL('put|_api/HeaderFooterSettings/Delete'), // [Body] ids
            DICT: describeURL('_api/HeaderFooterSettings/Dict'), // [Uri] ?isHeader={isHeader}
            SET_GLOBAL: describeURL('put|_api/HeaderFooterSettings/SetGlobal'), // [Uri] ?id={id}
            UNSET_GLOBAL: describeURL('put|_api/HeaderFooterSettings/UnsetGlobal'), // [Uri] ?id={id}
            PLACEHOLDERS_DICT: describeURL('_api/HeaderFooterSettings/PlaceholdersDict'),
            TEMPLATES_DICT: describeURL('_api/HeaderFooterSettings/TemplatesDict'), // [Uri] ?id={id}&isHeader={isHeader}
            BULK_ASSIGN_HEADER_FOOTER: describeURL('put|_api/HeaderFooterSettings/BulkAssignHeaderFooter'), // [Body] BulkAssignHeaderFooterModel
            BULK_DEFAULT_HEADER_FOOTER: describeURL('put|_api/HeaderFooterSettings/BulkDefaultHeaderFooter') // [Body] templates
        },
        // System/ defined lists
        LISTS: {
            SEARCH: describeURL('post|_api/ListsDefinition/Search'), // [Body] SearchViewModel
            CREATE: describeURL('post|_api/ListsDefinition/Create'), // [Body] MainListModel [Uri] ?roleId={roleId}
            UPDATE: describeURL('put|_api/ListsDefinition/Update'), // [Body] MainListModel [Uri] ?roleId={roleId}
            DELETE: describeURL('put|_api/ListsDefinition/Delete'), // [Body] ids
            DETAILS: describeURL('_api/ListsDefinition/Details'), // [Uri] ?id={id}&moduleId={moduleId}&contractId={contractId}
            CREATE_ELEMENT: describeURL('post|_api/ListsDefinition/CreateElement'), // [Uri] ?listId={listId} [Body] MainListElementModel
            UPDATE_ELEMENT: describeURL('put|_api/ListsDefinition/UpdateElement'), // [Uri] ?listId={listId}  [Body] MainListElementModel
            CREATE_ELEMENT_WITH_PARENT: describeURL('post|_api/ListsDefinition/CreateElementWithParent'), // [Uri] ?listId={listId} [Body] MainListElementWithParentModel
            UPDATE_ELEMENT_WITH_PARENT: describeURL('put|_api/ListsDefinition/UpdateElementWithParent'), // [Uri] ?listId={listId}  [Body] MainListElementWithParentModel
            DELETE_ELEMENT: describeURL('put|_api/ListsDefinition/DeleteElement'), //  [Uri] ?id={id}&listId={listId}
            GET_ELEMENTS: describeURL('post|_api/ListsDefinition/GetElements'), // [Body] GetElementsModel
            SYNC_ELEMENTS: describeURL('put|_api/ListsDefinition/SyncOrderElements'), // [Uri] ?id={id}   [Body] elements
            TOGGLE_USE_ELEMENT_ID: describeURL('put|_api/ListsDefinition/ToggleUseElementId'), // [Uri] ?id={id}
            DATASOURCE_DICT: describeURL('_api/ListsDefinition/DataSourceDict'),
            EXPORT_LIST_TEMPLATE: describeURL('_api/ListsDefinition/ExportListTemplateToExcel'), // [Uri]?id={id}
            EXPORT_LIST: describeURL('_api/ListsDefinition/ExportListToExcel'), // [Uri] ?id={id}
            IMPORT_LIST: describeURL('put|_api/ListsDefinition/ImportFromExcel'), // [Body] file   [Uri] ?id={id}

            // Relation list calls
            CHILD_LIST_DICT: describeURL('_api/ListsDefinition/ChildListDict'), // [Uri] ?id={id}
            PARENT_LIST_DICT: describeURL('_api/ListsDefinition/ParentListDict'), // [Uri] ?id={id}
            SET_RELATION: describeURL('put|_api/ListsDefinition/SetParentChildList'), // [Body] SetListRelationModel
            UNSET_RELATION: describeURL('put|_api/ListsDefinition/UnSetParentChildList'), // [Body] SetListRelationModel
            GET_ELEMENT_COUNT: describeURL('post|_api/ListsDefinition/GetRelationElementsCount'), // [Body] SetListRelationModel
            SEARCH_RELATIONS: describeURL('post|_api/ListsDefinition/AllListRelations'), // [Uri] ?id={id}  [Body] SearchViewModel
            GET_ELEMENTS_FOR_PARENT: describeURL('post|_api/ListsDefinition/AllElementsByParent'), // [Body] GetElementsFilteredModel
            SYNC_PARENT_ELEMENTS: describeURL('put|_api/ListsDefinition/SyncParentChildElements'), // [Body] SyncParentChildElementsModel
        },
        //Dashboard
        DASHBOARD: {
            SEARCH: describeURL('post|_api/Dashboard/Search'), // [Body] SearchViewModel [Uri] ?moduleId={moduleId}&programId={programId}&projectId={projectId}&contractId={contractId}&statusType={statusType}
            MODULE_COUNTS: describeURL('_api/Dashboard/ModuleCounts'), // [Uri] ?programId={programId}&projectId={projectId}&contractId={contractId}
            STATUS_TYPE_COUNTS: describeURL('_api/Dashboard/StatusTypeCounts'), // [Uri] ?programId={programId}&projectId={projectId}&contractId={contractId}
            STATUS_NAME_COUNTS: describeURL('_api/Dashboard/StatusNameCounts'), // [Uri] ?programId={programId}&projectId={projectId}&contractId={contractId}
            GANTT_DATA: describeURL('_api/Dashboard/GetGanttData'), // [Uri] ?contractId=[contractId]
            PROGRAM_PROJECT_CONTRACT_DICT: describeURL("_api/Dashboard/ProgramProjectContractDict"), //?programId={programId}&projectId={projectId}&contractId={contractId}
            PERSONAL_TASKS: describeURL("post|_api/Dashboard/GetPersonalTasks"), // [Body] SearchViewModel  [Uri] ?contractId={contractId}&assignedToMe={assignedToMe}
            PERSONAL_CORRESPONDENCE: describeURL("post|_api/Dashboard/GetPersonalCorrespondence") // [Body] SearchViewModel  [Uri] ?contractId={contractId}
        },
        // Contract adjustments (all types merged)
        CONTRACT_ADJUSTMENTS: {
            SEARCH: describeURL('post|_api/ContractAdjustments/Search'), // [Uri] ?contractId={contractId} [Body] SearchViewModel
            GET: describeURL('_api/ContractAdjustments/Get'), // [Uri] ?id={id}&contractId={contractId}
            CREATE: describeURL('post|_api/ContractAdjustments/Create'), // [Uri] ?contractId={contractId} [Body] ContractAdjustmentModel
            UPDATE: describeURL('put|_api/ContractAdjustments/Update'), // [Uri] ?contractId={contractId} [Body] ContractAdjustmentModel
            DELETE: describeURL('put|_api/ContractAdjustments/Delete'), // [Uri] ?id={id}
            DICT: describeURL('_api/ContractAdjustments/Dict'), // [Uri] ?contractId={contractId}
            CATEGORY_DICT: describeURL('_api/ContractAdjustments/CategoryDict'),
            ACCRUAL_FREQUENCY_DICT: describeURL('_api/ContractAdjustments/AccrualFrequencyDict'),
            GET_RELEASE: describeURL('_api/ContractAdjustments/GetRelease'), // [Uri] ?id={id}&contractId={contractId}  (returns AdjustmentReleaseModel)
            CREATE_RELEASE: describeURL('post|_api/ContractAdjustments/CreateRelease'), // [Uri] ?contractId={contractId} [Body] AdjustmentReleaseModel
            UPDATE_RELEASE: describeURL('put|_api/ContractAdjustments/UpdateRelease'), // [Uri] ?contractId={contractId} [Body] AdjustmentReleaseModel
            DELETE_RELEASE: describeURL('put|_api/ContractAdjustments/DeleteRelease'), // [Uri] ?id={id}&contractId={contractId}
            GET_RELEASES: describeURL('_api/ContractAdjustments/Releases'), // [Uri] ?adjustmentId={adjustmentId}&contractId={contractId}
        },
        // Contract adjustments (all types merged)
        ADJUSTMENTS: {
            SEARCH: describeURL('post|_api/Adjustments/Search'), // [Uri] ?entityInstanceId={entityInstanceId} [Body] SearchViewModel
            SEARCH_PP: describeURL('post|_api/Adjustments/SearchAdjustmentsPP'), // [Uri] ?progressPaymentEntityInstanceId={progressPaymentEntityInstanceId}&contractId={contractId}&editMode={editMode} [Body] SearchViewModel
            GET: describeURL('_api/Adjustments/Get'), // [Uri] ?id={id}&entityInstanceId={entityInstanceId}
            CREATE: describeURL('post|_api/Adjustments/Create'), // [Uri] ?entityInstanceId={entityInstanceId} [Body] AdjustmentsModel
            UPDATE: describeURL('put|_api/Adjustments/Update'), // [Uri] ?entityInstanceId={entityInstanceId} [Body] AdjustmentsModel
            DELETE: describeURL('put|_api/Adjustments/Delete'), // [Uri] ?id={id}
            CREATE_RELEASE: describeURL('post|_api/Adjustments/CreateRelease'), // [Uri] ?entityInstanceId={entityInstanceId} [Body] AdjustmentsReleaseModel
            UPDATE_RELEASE: describeURL('put|_api/Adjustments/UpdateRelease'), // [Uri] ?entityInstanceId={entityInstanceId} [Body] AdjustmentsReleaseModel
            DELETE_RELEASE: describeURL('put|_api/Adjustments/DeleteRelease'), // [Uri] ?id={id}&entityInstanceId={entityInstanceId}
            GET_RELEASES: describeURL('_api/Adjustments/Releases'), // [Uri] ?adjustmentId={adjustmentId}&entityInstanceId={entityInstanceId}
            PAY_ADJUSTMENT: describeURL('put|_api/Adjustments/PayOnAdjustment'), // [Uri] ?progressPaymentEntityInstanceId={progressPaymentEntityInstanceId}&contractId={contractId}&editMode={editMode}  [Body] AdjustmentProgressPaymentModel
            DICT: describeURL('_api/Adjustments/Dict'), // [Uri] ?entityInstanceId={entityInstanceId}
            CATEGORY_DICT: describeURL('_api/Adjustments/CategoryDict'),
            ACCRUAL_FREQUENCY_DICT: describeURL('_api/Adjustments/AccrualFrequencyDict'),
            CLOSE_ADJUSTMENT: describeURL('put|_api/Adjustments/CloseAdjustment'), // [Uri] ?id={id}&contractId={contractId}
        },
        //SOV Categories; are using SOV module route/permissions; SOV module id needs to be sent in header
        SOV_CATEGORY: {
            SEARCH: describeURL('post|_api/SOVCategory/Search'), // [Uri] ?sovEntityInstanceId={sovEntityInstanceId} [Body] SearchViewModel
            GET: describeURL('_api/SOVCategory/Get'), // [Uri] ?id={id}&contractId={contractId}
            CREATE: describeURL('post|_api/SOVCategory/Create'), // [Uri] ?contractId={contractId} [Body] SOVCategoryModel
            UPDATE: describeURL('put|_api/SOVCategory/Update'), // [Uri] ?contractId={contractId} [Body] SOVCategoryModel
            DELETE: describeURL('put|_api/SOVCategory/Delete'), // [Uri] ?id={id}&contractId={contractId}
            SYNC_POSITION: describeURL('put|_api/SOVCategory/SyncPosition'), // [Uri] ?contractId={contractId} [Body] array of SOVCategoryModel
            DICT: describeURL('_api/SOVCategory/Dict') // [Uri] ?contractId={contractId}
        },
        //SOV and SOV Items; this section uses SOV permissions
        SOV: {
            //SOV Item
            SOVI_FIELDS: describeURL('_api/SOV/SOVItemsGridFields'), // [Uri] ?workflowId={workflowId}&contractId={contractId}
            SOVI_SEARCH: describeURL('post|_api/SOV/SOVItemsSearch'), // [Uri] ?contractId={contractId}&categoryId={categoryId}&sovEntityInstanceId={sovEntityInstanceId} [Body] SearchHybridModuleModel 
            SOVI_GET: describeURL('_api/SOV/SOVItem'), // [Uri] ?workflowId={workflowId}&entityInstanceId={entityInstanceId}&contractId={contractId}&actionId={actionId}
            SOVI_SAVE: describeURL('post|_api/SOV/SOVItemSave'), // [Body] ModuleScreenValuesModel [Uri] ?workflowId={workflowId}&sovItemEntityInstanceId={sovItemEntityInstanceId}&contractId={contractId}&roleId={roleId}
            SOVI_SAVE_REVISE: describeURL('post|_api/SOV/SOVItemSaveReviseInstance'), // [Body] ModuleScreenValuesModel [Uri] ?sovItemEntityInstanceId={sovItemEntityInstanceId}&contractId={contractId}roleId={roleId}
            SOVI_DELETE: describeURL('put|_api/SOV/SOVItemDelete'), // [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}

            //Approved Change Order
            ACO_FIELDS: describeURL('_api/SOV/ApprovedChangeOrdersGridFields'), // [Uri] ?contractId={contractId}
            ACO_SEARCH: describeURL('post|_api/SOV/ApprovedChangeOrdersSearch'), // [Uri] ?contractId={contractId} [Body] SearchHybridModuleModel 

            //SOV
            SOV_GET: describeURL('_api/SOV/SOV'), // [Uri] ?transitionId={transitionId}&contractId={contractId}
            SOV_CREATE: describeURL('post|_api/SOV/SOVCreate'), // [Uri] ?workflowId={workflowId}&contractId={contractId}&roleId={roleId}
            SOV_SAVE: describeURL('put|_api/SOV/SOVSave'), // [Body] ModuleScreenValuesModel [Uri] ?workflowId={workflowId}&entityInstanceId={entityInstanceId}&transitionId={transitionId}&contractId={contractId}&roleId={roleId}
            SOV_REVISE: describeURL('post|_api/SOV/SOVSaveReviseInstance'), // [Body] ModuleScreenValuesModel [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}roleId={roleId}
            SOV_TRANSITIONS: describeURL('_api/SOV/SOVTransitions'), // [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}
            SOV_IMPORT: describeURL('put|_api/SOV/SOVImport'), // [Uri] ?contractId={contractId}
            SOV_EXPORT: describeURL('_api/SOV/SOVExport'), // [Uri] ?contractId={contractId}&entityInstanceId={entityInstanceId}
            SOV_DELETE: describeURL('put|_api/SOV/SOVDelete'), // [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}
            SOV_APPROVALS: describeURL('_api/SOV/CheckApprovals'), // [Uri] ?contractId={contractId}
            SOV_MULTIPLE_SEARCH: describeURL('post|_api/SOV/GetMultipleSOVs'), // ?contractId={contractId} [Body] SearchViewModel
            SOV_DO_APPROVAL: describeURL('put|_api/SOV/DoApproval'), // [Body] DoApprovalModel  [Uri] ?moduleId={moduleId}&contractId={contractId}&roleId={roleId}
            SOV_ERRORS: describeURL('_api/SOV/SOVImportErrors'), // [Uri] ?entityInstanceId={entityInstanceId}
            ROLLBACK: describeURL('put|_api/SOV/RollbackLastTransition'), // [Uri] ?actionInstanceId={actionInstanceId}&contractId={contractId}

            // new calls for getting info
            GET_GENERAL_INFO: describeURL('_api/SOV/GetGeneralInformationEntityInstance'), // [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}
            GET_ASSIGNMENT: describeURL('_api/SOV/GetAssignmentEntityInstance'), // [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}
            GET_ASSIGNMENT_HISTORY: describeURL('_api/SOV/GetAssignmentHistoryEntityInstance'), // [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}
            GET_SIGNERS: describeURL('_api/SOV/GetSignersEntityInstance'), // [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}
            GET_TRANSITIONS: describeURL('_api/SOV/GetTransitionsEntityInstance'), // [Uri] ?workflowId={workflowId}&entityInstanceId={entityInstanceId}&contractId={contractId}
            GET_ACTIVITY_SUMMARY: describeURL('_api/SOV/GetActivitySummaryEntityInstance'), // [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}
            GET_TRANSITION_INSTANCE: describeURL('_api/SOV/GetTransitionInstanceInformations'), // [Uri] ?actionInstanceId={actionInstanceId}&contractId={contractId}&noFields={noFields}
            GET_FIELDS: describeURL('_api/SOV/GetFieldsEntityInstance'), // [Uri] ?workflowId={workflowId}&entityInstanceId={entityInstanceId}&transitionId={transitionId}&contractId={contractId}&actionId={actionId}&excludeRoleId={excludeRoleId}&screenId={screenId}
            OTHER_DRAFTS: describeURL('_api/SOV/GetOtherDrafts'), // [Uri] ?contractId={contractId}

            IMPORT_DICT: describeURL('_api/SOV/ImportDict'), // [Uri] ?screenId={screenId}
            IMPORT_DOCS: describeURL('put|_api/SOV/ImportDocuments'), // [Body] ok   [Uri] ?contractId={contractId}
        },
        WBS: {
            GET: describeURL('_api/WBS/GetWbs'), // [Uri] ?contractId={contractId}&sovEntityInstanceId={sovEntityInstanceId}&includeUsedFlags={includeUsedFlags} (includeUsedFlags degrades performance when true, necessary for editing a WBS)
            GET_WITH_CATEGORIES: describeURL('post|_api/WBS/GetWbsWithCategories'), // [Uri] ?contractId={contractId}&sovEntityInstanceId={sovEntityInstanceId} [Body] List<int> expandedNodeIds (nullible. When list of expanded ids provided, API will return those nodes with isExpanded = true)
            IMPORT: describeURL('post|_api/WBS/ImportWbs'), // [Uri] ?contractId={contractId}&contractEntityInstanceId={contractEntityInstanceId} [Body] file blob
            PROCESS_DATA: describeURL('_api/WBS/CreateSOVItemsFromWBSImport'), // [URI] ?contractId={contractId}&sovEntityInstanceId={sovEntityInstanceId}
            CREATE_NODE: describeURL('post|_api/WBS/CreateNode'), // [Uri] ?contractId={contractId}&sovEntityInstanceId={sovEntityInstanceId} [Body] node: WBSViewModel
            RENAME_NODE: describeURL('put|_api/WBS/RenameNode'), // [Uri] ?contractId={contractId}&id={id}&name={name}
            DELETE_NODE: describeURL('delete|_api/WBS/DeleteNode'), // [Uri] ?contractId={contractId}&sovEntityInstanceId={sovEntityInstanceId}&id={id}
            SYNC_POSITIONING: describeURL('put|_api/WBS/SyncPositioning'), // [Uri] ?contractId={contractId}&sovEntityInstanceId={sovEntityInstanceId} [Body] WBS Elements
        },
        //Payment Period, Progress Payment and Progress Payment Items; this section uses Progress Payment permissions
        PROGRESS_PAYMENT: {
            //Payment Period
            PPD_SEARCH: describeURL('post|_api/PaymentPeriod/Search'), // [Uri] ?contractId={contractId} [Body] SearchViewModel 
            PPD_GET: describeURL('_api/PaymentPeriod/Get'), // [Uri] ?contractId={contractId}&id={id}
            PPD_GET_FROM_ENTITY_INSTANCE: describeURL('_api/PaymentPeriod/GetFromEntityInstance'), // [Uri] ?entityInstanceId={entityInstanceId}
            PPD_CURRENT: describeURL('_api/PaymentPeriod/Current'), // [Uri] ?contractId={contractId}
            PPD_DICT: describeURL('_api/PaymentPeriod/Dict'), // [Uri] ?contractId={contractId}
            RECREATE_PAYMENT_PERIODS: describeURL('put|_api/PaymentPeriod/RecreatePaymentPeriods'), // [Uri] ?contractId={contractId}
            CREATE_NEW_PERIOD: describeURL('post|_api/PaymentPeriod/Create'), // [Uri] ?contractId={contractId} [Body] PaymentPeriodCUDModel (EndDate required)
            UPDATE_PERIOD: describeURL('put|_api/PaymentPeriod/Update'), // [Uri] ?contractId={contractId} [Body] PaymentPeriodCUDModel (Id and EndDate required)
            DELETE_PERIOD: describeURL('put|_api/PaymentPeriod/Delete'), // [Uri] ?contractId={contractId} [Body] PaymentPeriodCUDModel (Id required)
            COMBINE_PERIODS: describeURL('put|_api/PaymentPeriod/CombinePeriods'), // [Uri] ?entityInstanceId={entityInstanceId} [Body] ids
            REVERT_COMBINE_PERIODS: describeURL('put|_api/PaymentPeriod/UndoCombinePeriods'), // [Uri] ?id={id}
            DICT_DATE_PERIODS: describeURL('_api/PaymentPeriod/GetPeriodStartEndDate'), // [Uri] ?entityInstanceId={entityInstanceId}&isStartDate={isStartDate}&orderAscending={orderAscending}

            //Progress Payment Item
            PPI_FIELDS: describeURL('_api/ProgressPayment/ProgressPaymentItemsGridFields'), // [Uri] ?contractId={contractId}
            PPI_SEARCH: describeURL('post|_api/ProgressPayment/ProgressPaymentItemsSearch'), // [Uri] ?contractId={contractId}&categoryId={categoryId}&progressPaymentEntityInstanceId={progressPaymentEntityInstanceId} [Body] SearchViewModel 
            PPI_HISTORY: describeURL('_api/ProgressPayment/ProgressPaymentItemsHistory'), // [Uri] ?contractId={contractId}
            PPI_GET: describeURL('_api/ProgressPayment/ProgressPaymentItem'), // [Uri] ?contractId={contractId}&progressPaymentEntityInstanceId={progressPaymentEntityInstanceId}&sovItemEntityInstanceId={sovItemEntityInstanceId}
            PPI_SAVE: describeURL('post|_api/ProgressPayment/ProgressPaymentItemSave'), // [Body] ProgressPaymentItemModel [Uri] ?contractId={contractId}&progressPaymentEntityInstanceId={progressPaymentEntityInstanceId}&sovItemEntityInstanceId={sovItemEntityInstanceId}
            PPI_DELETE: describeURL('put|_api/ProgressPayment/ProgressPaymentItemDelete'), // [Uri] ?contractId={contractId}&id={id}

            // Progress Payment / Item NEW
            PP_RELATIONS: describeURL('post|_api/ProgressPayment/ProgressPaymentModules'), // [Uri] ?contractId={contractId}&progressPaymentEntityInstanceId={progressPaymentEntityInstanceId}&editMode={editMode}
            PPI_PAGES: describeURL('post|_api/ProgressPayment/GetProgressPaymentItemsPages'), // [Body] SearchViewModel [Uri] ?progressPaymentEntityInstanceId={progressPaymentEntityInstanceId}&editMode={editMode}
            PPI_RETRIEVE: describeURL('post|_api/ProgressPayment/GetProgressPaymentItems'), // [Body] SearchViewModel [Uri] ?contractId={contractId}&progressPaymentEntityInstanceId={progressPaymentEntityInstanceId}&relationId={relationId}&editMode={editMode}
            PPI_DETAILS: describeURL('post|_api/ProgressPayment/GetProgressPaymentItem'), // [Uri] ?contractId={contractId}&progressPaymentEntityInstanceId={progressPaymentEntityInstanceId}&childEntityInstanceId={childEntityInstanceId}&moduleId={moduleId}&editMode={editMode}
            PPI_UPDATE: describeURL('put|_api/ProgressPayment/SaveProgressPaymentItem'), // [Body] SaveProgressPaymentItemModel [Uri] ?contractId={contractId}&editMode={editMode}
            PP_MULTIPLE_SEARCH: describeURL('post|_api/ProgressPayment/SearchMultipleProgressPayments'), // ?paymentPeriodId={paymentPeriodId}&contractId={contractId} [Body] SearchViewModel
            ROLLBACK: describeURL('put|_api/ProgressPayment/RollbackLastTransition'), // [Uri] ?actionInstanceId={actionInstanceId}&contractId={contractId}

            //Change Order Payment
            COP_FIELDS: describeURL('_api/ProgressPayment/ChangeOrderPaymentGridFields'), // [Uri] ?contractId={contractId}
            COP_SEARCH: describeURL('post|_api/ProgressPayment/ChangeOrderPaymentsSearch'), // [Uri] ?contractId={contractId}&progressPaymentEntityInstanceId={progressPaymentEntityInstanceId} [Body] SearchViewModel 
            COP_GET: describeURL('_api/ProgressPayment/ChangeOrderPayment'), // [Uri] ?contractId={contractId}&progressPaymentEntityInstanceId={progressPaymentEntityInstanceId}&changeOrderEntityInstanceId={changeOrderEntityInstanceId}
            COP_SAVE: describeURL('post|_api/ProgressPayment/ChangeOrderPaymentSave'), // [Body] ChangeOrderPaymentModel [Uri] ?contractId={contractId}&progressPaymentEntityInstanceId={progressPaymentEntityInstanceId}&changeOrderEntityInstanceId={changeOrderEntityInstanceId}
            COP_DELETE: describeURL('put|_api/ProgressPayment/ChangeOrderPaymentDelete'), // [Uri] ?contractId={contractId}&id={id}

            //Progress Payment
            PP_GET: describeURL('_api/ProgressPayment/ProgressPayment'), // [Uri] ?transitionId={transitionId}&contractId={contractId}&entityInstanceId={entityInstanceId}&paymentPeriodId={paymentPeriodId}
            PP_CREATE: describeURL('post|_api/ProgressPayment/ProgressPaymentCreate'), // [Uri] ?workflowId={workflowId}&contractId={contractId}&paymentPeriodId={paymentPeriodId}&moduleTypeId={moduleTypeId}&roleId={roleId}&screenId={screenId}
            PP_SAVE: describeURL('put|_api/ProgressPayment/ProgressPaymentSave'), // [Body] ModuleScreenValuesModel [Uri] ?workflowId={workflowId}&entityInstanceId={entityInstanceId}&transitionId={transitionId}&contractId={contractId}&paymentPeriodId={paymentPeriodId}&moduleTypeId={moduleTypeId}&roleId={roleId}
            PP_REVISE: describeURL('post|_api/ProgressPayment/ProgressPaymentSaveReviseInstance'), // [Body] ModuleScreenValuesModel [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}roleId={roleId}
            PP_TRANSITIONS: describeURL('_api/ProgressPayment/ProgressPaymentTransitions'), // [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}
            PP_DELETE: describeURL('put|_api/ProgressPayment/ProgressPaymentDelete'), // [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}
            PP_PRINT: describeURL('_api/ProgressPayment/ProgressPaymentPrintOut'), // [Uri] contractId={contractId}&progressPaymentEntityInstanceId={progressPaymentEntityInstanceId}&getZeroPercentages={getZeroPercentages}
            PP_DO_APPROVAL: describeURL('put|_api/ProgressPayment/DoApproval'), // [Body] DoApprovalModel  [Uri] ?moduleId={moduleId}&contractId={contractId}&roleId={roleId}
            PP_MOVE: describeURL('put|_api/ProgressPayment/MoveProgressPaymentToDifferentPeriod'), // [Uri] ?entityInstanceId={entityInstanceId}&periodId={periodId}&contractId={contractId}
            PP_OTHER_DRAFTS: describeURL('_api/ProgressPayment/GetOtherDrafts'), // [Uri] ?paymentPeriodId={paymentPeriodId}

            PP_IS_USER_INITIATOR: describeURL('_api/ProgressPayment/IsUserInitiator'), // [Uri] contractId={contractId}

            // new calls for getting info
            GET_GENERAL_INFO: describeURL('_api/ProgressPayment/GetGeneralInformationEntityInstance'), // [Uri] ?entityInstanceId={entityInstanceId}&paymentPeriodId={paymentPeriodId}&moduleTypeId={moduleTypeId}&contractId={contractId}
            GET_ASSIGNMENT: describeURL('_api/ProgressPayment/GetAssignmentEntityInstance'), // [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}
            GET_ASSIGNMENT_HISTORY: describeURL('_api/ProgressPayment/GetAssignmentHistoryEntityInstance'), // [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}
            GET_SIGNERS: describeURL('_api/ProgressPayment/GetSignersEntityInstance'), // [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}
            GET_TRANSITIONS: describeURL('_api/ProgressPayment/GetTransitionsEntityInstance'), // [Uri] ?workflowId={workflowId}&entityInstanceId={entityInstanceId}&paymentPeriodId={paymentPeriodId}&contractId={contractId}
            GET_ACTIVITY_SUMMARY: describeURL('_api/ProgressPayment/GetActivitySummaryEntityInstance'), // [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}
            GET_TRANSITION_INSTANCE: describeURL('_api/ProgressPayment/GetTransitionInstanceInformations'), // [Uri] ?actionInstanceId={actionInstanceId}&contractId={contractId}&noFields={noFields}
            GET_FIELDS: describeURL('_api/ProgressPayment/GetFieldsEntityInstance'), // [Uri] ?workflowId={workflowId}&entityInstanceId={entityInstanceId}&transitionId={transitionId}&contractId={contractId}&actionId={actionId}&excludeRoleId={excludeRoleId}&screenId={screenId}

            //Adjustment releases
            GET_RELEASES_TO_PAY: describeURL('_api/ProgressPayment/ReleasesToPay'), // [Uri] ?contractId={contractId}&progressPaymentEntityInstanceId={progressPaymentEntityInstanceId}
            PAY_RELEASED: describeURL('put|_api/ProgressPayment/PayReleased'), // [Uri] ?contractId={contractId} [Body] PayReleasedModel (returns ReleasePaymentItemResponseModel)
            WITHHOLD_RELEASED: describeURL('put|_api/ProgressPayment/WithholdReleased'), // [Uri] ?contractId={contractId} [Body] PayReleasedModel (returns ReleasePaymentItemResponseModel)
            GET_RELEASE_PP_ITEM: describeURL('_api/ProgressPayment/GetRelease'), // [Uri] ?id={id}&progressPaymentEntityInstanceId={progressPaymentEntityInstanceId}  (returns AdjustmentSelectedReleaseModel)
        },
        CO: {
            //moduleId needs to be passed in the header on all calls
            GRID_FIELDS: describeURL('_api/ChangeOrder/GridFields'), // [Uri] ?workflowId={workflowId}&moduleId={moduleId}&contractId={contractId}
            GRID_SEARCH: describeURL('post|_api/ChangeOrder/GridSearch'), // [Uri] ?moduleId={moduleId}&contractId={contractId} [Body] SearchViewModel; 
            GET_TRANSITION_SCREEN: describeURL('_api/ChangeOrder/GetTransitionScreen'), // [Uri] ?moduleId={moduleId}&entityInstanceId={entityInstanceId}&transitionId={transitionId}&contractId={contractId}
            SAVE_TRANSITION_SCREEN: describeURL('post|_api/ChangeOrder/SaveTransitionScreen'), // [Body] ModuleScreenValuesModel [Uri] ?workflowId={workflowId}&moduleId={moduleId}&entityInstanceId={entityInstanceId}&transitionId={transitionId}&contractId={contractId}&roleId={roleId}
            SAVE_PRESET: describeURL('post|_api/ChangeOrder/SaveTemplateInstance'), // [Body] ModuleScreenValuesModel [Uri] ?workflowId={workflowId}&presetId={presetId}&transitionId={transitionId}&contractId={contractId}&roleId={roleId}
            SAVE_DRAFT: describeURL('post|_api/ChangeOrder/SaveDraftInstance'), // [Body] ModuleScreenValuesModel [Uri] ?workflowId={workflowId}&entityInstanceId={entityInstanceId}&contractId={contractId}&roleId={roleId}
            SAVE_REVISE: describeURL('post|_api/ChangeOrder/SaveReviseInstance'), // [Body] ModuleScreenValuesModel [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}roleId={roleId}
            SAVE_MULTI_TRANSITION_SCREEN: describeURL('post|_api/ModuleInstance/SaveMultipleTransitionScreens'), // [Body] ModuleScreenMultipleValuesModel [Uri] ?workflowId={workflowId}&moduleId={moduleId}&transitionId={transitionId}&contractId={contractId}&relationId={relationId}&parentEntityInstanceId={parentEntityInstanceId}&roleId={roleId}            
            SAVE_MULTI_DRAFT: describeURL('post|_api/ModuleInstance/SaveMultipleDraftInstances'), // [Body] ModuleScreenMultipleValuesModel [Uri] ?moduleId={moduleId}&contractId={contractId}&roleId={roleId}
            SAVE_MULTI_REVISE: describeURL('post|_api/ModuleInstance/SaveMultipleReviseInstances'), // [Body] ModuleScreenMultipleValuesModel [Uri] ?moduleId={moduleId}&contractId={contractId}&roleId={roleId}
            UPDATE: describeURL('put|_api/ChangeOrder/UpdateFieldValuesDocument'), // [Body] ModuleScreenValuesModel [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}
            DELETE: describeURL('put|_api/ChangeOrder/Delete'), // [Uri] ?moduleId={moduleId}&entityInstanceId={entityInstanceId}&contractId={contractId}
            TRANSITIONS: describeURL('_api/ChangeOrder/GetTransitions'), // [Uri] ?moduleId={moduleId}&entityInstanceId={entityInstanceId}&contractId={contractId}
            PREVIEW: describeURL('_api/ChangeOrder/PreviewTemplate'),
            DO_APPROVAL: describeURL('put|_api/ChangeOrder/DoApproval'), // [Body] DoApprovalModel  [Uri] ?moduleId={moduleId}&contractId={contractId}&roleId={roleId}
            ROLLBACK: describeURL('put|_api/ChangeOrder/RollbackLastTransition') // [Uri] ?actionInstanceId={actionInstanceId}&contractId={contractId}
        },
        CT: {
            //moduleId needs to be passed in the header on all calls
            DUPLICATE: describeURL('post|_api/Contracts/Duplicate'), // [Uri] ?moduleId={moduleId}&entityInstanceId={entityInstanceId}&newContractName={newContractName}&newContractNo={newContractNo}&isDuplicateProject={isDuplicateProject}
            GRID_FIELDS: describeURL('_api/Contracts/GridFields'), // [Uri] ?workflowId={workflowId}&moduleId={moduleId}&contractId={contractId}
            GRID_SEARCH: describeURL('post|_api/Contracts/GridSearch'), // [Uri] ?moduleId={moduleId}&contractId={contractId} [Body] SearchViewModel; 
            GET_TRANSITION_SCREEN: describeURL('_api/Contracts/GetTransitionScreen'), // [Uri] ?moduleId={moduleId}&entityInstanceId={entityInstanceId}&transitionId={transitionId}&contractId={contractId}
            SAVE_TRANSITION_SCREEN: describeURL('post|_api/Contracts/SaveTransitionScreen'), // [Body] ModuleScreenValuesModel [Uri] ?workflowId={workflowId}&moduleId={moduleId}&entityInstanceId={entityInstanceId}&transitionId={transitionId}&contractId={contractId}&roleId={roleId}
            SAVE_PRESET: describeURL('post|_api/Contracts/SaveTemplateInstance'), // [Body] ModuleScreenValuesModel [Uri] ?workflowId={workflowId}&presetId={presetId}&transitionId={transitionId}&roleId={roleId}
            SAVE_DRAFT: describeURL('post|_api/Contracts/SaveDraftInstance'), // [Body] ModuleScreenValuesModel [Uri] ?workflowId={workflowId}&entityInstanceId={entityInstanceId}&roleId={roleId}
            SAVE_REVISE: describeURL('post|_api/Contracts/SaveReviseInstance'), // [Body] ModuleScreenValuesModel [Uri] ?entityInstanceId={entityInstanceId}&roleId={roleId}
            SAVE_MULTI_TRANSITION_SCREEN: describeURL('post|_api/ModuleInstance/SaveMultipleTransitionScreens'), // [Body] ModuleScreenMultipleValuesModel [Uri] ?workflowId={workflowId}&moduleId={moduleId}&transitionId={transitionId}&contractId={contractId}&relationId={relationId}&parentEntityInstanceId={parentEntityInstanceId}&roleId={roleId}            
            SAVE_MULTI_DRAFT: describeURL('post|_api/ModuleInstance/SaveMultipleDraftInstances'), // [Body] ModuleScreenMultipleValuesModel [Uri] ?moduleId={moduleId}&contractId={contractId}&roleId={roleId}
            SAVE_MULTI_REVISE: describeURL('post|_api/ModuleInstance/SaveMultipleReviseInstances'), // [Body] ModuleScreenMultipleValuesModel [Uri] ?moduleId={moduleId}&contractId={contractId}&roleId={roleId}
            UPDATE: describeURL('put|_api/Contracts/UpdateFieldValuesDocument'), // [Body] ModuleScreenValuesModel [Uri] ?entityInstanceId={entityInstanceId}
            DELETE: describeURL('put|_api/Contracts/Delete'), // [Uri] ?moduleId={moduleId}&entityInstanceId={entityInstanceId}&contractId={contractId}
            TRANSITIONS: describeURL('_api/Contracts/GetTransitions'), // [Uri] ?moduleId={moduleId}&entityInstanceId={entityInstanceId}&contractId={contractId}
            PREVIEW: describeURL('_api/Contracts/PreviewTemplate'),
            DICT: describeURL('_api/Contracts/Dict'), //?userId={userId}&roleId={roleId}&moduleId={moduleId}
            ROLE_DICT: describeURL('_api/Contracts/RoleDict'), //?entityInstanceId={entityInstanceId}&perContract={perContract}
            GET_ROLE_USERS: describeURL('_api/Contracts/RoleUsers'), //?entityInstanceId={entityInstanceId}
            SYNC_ROLE_USERS: describeURL('put|_api/Contracts/RoleUsers'), //?entityInstanceId={entityInstanceId}
            CONTRACT_DETAILS: describeURL('_api/Contracts/ContractDetails'), //?entityInstanceId={entityInstanceId}
            CONTRACT_OVERVIEW: describeURL('_api/Contracts/ContractOverview'), //?contractId={contractId}
            SYNC_ROLE_POSITIONS: describeURL('put|_api/Contracts/SyncRolePositions'), // [Body] SyncRolePositionsModel
            SET_CURRENT_CONTRACT: describeURL('post|_api/Contracts/AddLastUsedContract'), // ?contractId={contractId}
            DO_APPROVAL: describeURL('put|_api/Contracts/DoApproval'), // [Body] DoApprovalModel  [Uri] ?moduleId={moduleId}&roleId={roleId}
            MODULES_OVERVIEW: describeURL('_api/Contracts/ContractOverviewModuleIds'), //  [Uri] ?entityInstanceId={entityInstanceId}
            MODULE_OVERVIEW_DETAILS: describeURL('post|_api/Contracts/GetModuleOverview'), // [Uri] ?moduleId={moduleId}&entityInstanceId={entityInstanceId}&turnAroundDays={turnAroundDays}
            ROLLBACK: describeURL('put|_api/Contracts/RollbackLastTransition'), // [Uri] ?actionInstanceId={actionInstanceId}&contractId={contractId}
            GET_ARCHIVE_FILE_URL: describeURL('_api/Contracts/GetArchiveFileURL'), //?contractId={contractId}&fileName={fileName}
            GET_ARCHIVES: describeURL('_api/Contracts/GetArchivesList'), //?contractId={contractId}
            GET_ARCHIVE_FILES: describeURL('_api/Contracts/GetArchiveFilesList'), //?archiveId={archiveId}
            SEARCH_ARCHIVES: describeURL('post|_api/Contracts/SearchArchives'), // [Body] SearchViewModel [Uri] ?contractId={contractId}
            SEARCH_ARCHIVE_FILES: describeURL('post|_api/Contracts/SearchArchiveFiles'), // [Body] SearchViewModel [Uri] ?archiveId={archiveId}
            SAVE_ARCHIVE: describeURL('post|_api/Contracts/SaveDocumentArchive'), // [Body] ArchiveModel [Uri] ?contractId={contractId}
            DELETE_ARCHIVES: describeURL('put|_api/Contracts/DeleteArchives'), // [Body] archiveIds
            DELETE_ARCHIVE_FILES: describeURL('put|_api/Contracts/DeleteArchiveFiles'), // [Body] archiveFileIds
            CLOSE_CONTRACT:  describeURL('put|_api/Contracts/CloseContract'), // ?entityInstanceId={entityInstanceId}
            SEARCH_OPEN_DOCS: describeURL('post|_api/Contracts/SearchOpenDocuments'), // [Uri] ?entityInstanceId={entityInstanceId} [Body] SearchViewModel;

            GET_HEADER: describeURL('_api/Contracts/GetHeaderEntityInstance'), // [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}
            GET_GENERAL_INFO: describeURL('_api/Contracts/GetGeneralInformationEntityInstance'), // [Uri] ?entityInstanceId={entityInstanceId}
            GET_FIELDS: describeURL('_api/Contracts/GetFieldsEntityInstance'), // [Uri] ?workflowId={workflowId}&entityInstanceId={entityInstanceId}&transitionId={transitionId}&contractId={contractId}&actionId={actionId}&excludeRoleId={excludeRoleId}&screenId={screenId}
            GET_ASSIGNMENT: describeURL('_api/Contracts/GetAssignmentEntityInstance'), // [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}
            GET_ASSIGNMENT_HISTORY: describeURL('_api/Contracts/GetAssignmentHistoryEntityInstance'), // [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}
            GET_SIGNERS: describeURL('_api/Contracts/GetSignersEntityInstance'), // [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}
            GET_TRANSITIONS: describeURL('_api/Contracts/GetTransitionsEntityInstance'), // [Uri] ?workflowId={workflowId}&entityInstanceId={entityInstanceId}&contractId={contractId}
            GET_ACTIVITY_SUMMARY: describeURL('_api/Contracts/GetActivitySummaryEntityInstance'), // [Uri] ?entityInstanceId={entityInstanceId}&contractId={contractId}
            GET_TRANSITION_INSTANCE: describeURL('_api/Contracts/GetTransitionInstanceInformations') // [Uri] ?actionInstanceId={actionInstanceId}&contractId={contractId}&noFields={noFields}
        },
        P: {
            //moduleId needs to be passed in the header on all calls
            GRID_FIELDS: describeURL('_api/Projects/GridFields'), // [Uri] ?workflowId={workflowId}&moduleId={moduleId}&contractId={contractId}
            GRID_SEARCH: describeURL('post|_api/Projects/GridSearch'), // [Uri] ?moduleId={moduleId}&contractId={contractId} [Body] SearchViewModel; 
            GET_TRANSITION_SCREEN: describeURL('_api/Projects/GetTransitionScreen'), // [Uri] ?moduleId={moduleId}&entityInstanceId={entityInstanceId}&transitionId={transitionId}&contractId={contractId}
            SAVE_TRANSITION_SCREEN: describeURL('post|_api/Projects/SaveTransitionScreen'), // [Body] ModuleScreenValuesModel [Uri] ?workflowId={workflowId}&entityInstanceId={entityInstanceId}&transitionId={transitionId}&roleId={roleId}
            SAVE_PRESET: describeURL('post|_api/Projects/SaveTemplateInstance'), // [Body] ModuleScreenValuesModel [Uri] ?workflowId={workflowId}&presetId={presetId}&transitionId={transitionId}&roleId={roleId}
            SAVE_DRAFT: describeURL('post|_api/Projects/SaveDraftInstance'), // [Body] ModuleScreenValuesModel [Uri] ?workflowId={workflowId}&entityInstanceId={entityInstanceId}&contractId={contractId}&roleId={roleId}
            SAVE_REVISE: describeURL('post|_api/Projects/SaveReviseInstance'), // [Body] ModuleScreenValuesModel [Uri] ?entityInstanceId={entityInstanceId}&roleId={roleId}
            SAVE_MULTI_TRANSITION_SCREEN: describeURL('post|_api/ModuleInstance/SaveMultipleTransitionScreens'), // [Body] ModuleScreenMultipleValuesModel [Uri] ?workflowId={workflowId}&moduleId={moduleId}&transitionId={transitionId}&contractId={contractId}&relationId={relationId}&parentEntityInstanceId={parentEntityInstanceId}&roleId={roleId}            
            SAVE_MULTI_DRAFT: describeURL('post|_api/ModuleInstance/SaveMultipleDraftInstances'), // [Body] ModuleScreenMultipleValuesModel [Uri] ?moduleId={moduleId}&contractId={contractId}&roleId={roleId}
            SAVE_MULTI_REVISE: describeURL('post|_api/ModuleInstance/SaveMultipleReviseInstances'), // [Body] ModuleScreenMultipleValuesModel [Uri] ?moduleId={moduleId}&contractId={contractId}&roleId={roleId}
            UPDATE: describeURL('put|_api/Projects/UpdateFieldValuesDocument'), // [Body] ModuleScreenValuesModel [Uri] ?entityInstanceId={entityInstanceId}
            DELETE: describeURL('put|_api/Projects/Delete'), // [Uri] ?moduleId={moduleId}&entityInstanceId={entityInstanceId}&contractId={contractId}
            TRANSITIONS: describeURL('_api/Projects/GetTransitions'), // [Uri] ?moduleId={moduleId}&entityInstanceId={entityInstanceId}&contractId={contractId}
            PREVIEW: describeURL('_api/Projects/PreviewTemplate'),
            IMPORT_XER_FILE: describeURL('put|_api/Projects/ImportXerFile'), // [Body] File blob [Url]?entityInstanceId={entityInstanceId}
            IMPORT_MS_PROJECT_FILE: describeURL('put|_api/Projects/ImportMSProjectFile'), // [Body] File blob [Url]?entityInstanceId={entityInstanceId}
            DO_APPROVAL: describeURL('put|_api/Projects/DoApproval'), // [Body] DoApprovalModel  [Uri] ?moduleId={moduleId}&roleId={roleId}
            GET_FIELDS: describeURL('_api/Projects/GetFieldsEntityInstance'), // [Uri] ?workflowId={workflowId}&entityInstanceId={entityInstanceId}&transitionId={transitionId}&contractId={contractId}&actionId={actionId}&excludeRoleId={excludeRoleId}&screenId={screenId}
            ROLLBACK: describeURL('put|_api/Projects/RollbackLastTransition') // [Uri] ?actionInstanceId={actionInstanceId}&contractId={contractId}
        },
        PRG: {
            //moduleId needs to be passed in the header on all calls
            GRID_FIELDS: describeURL('_api/Programs/GridFields'), // [Uri] ?workflowId={workflowId}&moduleId={moduleId}&contractId={contractId}
            GRID_SEARCH: describeURL('post|_api/Programs/GridSearch'), // [Uri] ?moduleId={moduleId}&contractId={contractId} [Body] SearchViewModel; 
            GET_TRANSITION_SCREEN: describeURL('_api/Programs/GetTransitionScreen'), // [Uri] ?moduleId={moduleId}&entityInstanceId={entityInstanceId}&transitionId={transitionId}&contractId={contractId}
            SAVE_TRANSITION_SCREEN: describeURL('post|_api/Programs/SaveTransitionScreen'), // [Body] ModuleScreenValuesModel [Uri] ?workflowId={workflowId}&entityInstanceId={entityInstanceId}&transitionId={transitionId}&roleId={roleId}
            SAVE_PRESET: describeURL('post|_api/Programs/SaveTemplateInstance'), // [Body] ModuleScreenValuesModel [Uri] ?workflowId={workflowId}&presetId={presetId}&transitionId={transitionId}&roleId={roleId}
            SAVE_DRAFT: describeURL('post|_api/Programs/SaveDraftInstance'), // [Body] ModuleScreenValuesModel [Uri] ?workflowId={workflowId}&entityInstanceId={entityInstanceId}&contractId={contractId}&roleId={roleId}
            SAVE_REVISE: describeURL('post|_api/Programs/SaveReviseInstance'), // [Body] ModuleScreenValuesModel [Uri] ?entityInstanceId={entityInstanceId}&roleId={roleId}
            SAVE_MULTI_TRANSITION_SCREEN: describeURL('post|_api/ModuleInstance/SaveMultipleTransitionScreens'), // [Body] ModuleScreenMultipleValuesModel [Uri] ?workflowId={workflowId}&moduleId={moduleId}&transitionId={transitionId}&contractId={contractId}&relationId={relationId}&parentEntityInstanceId={parentEntityInstanceId}&roleId={roleId}            
            SAVE_MULTI_DRAFT: describeURL('post|_api/ModuleInstance/SaveMultipleDraftInstances'), // [Body] ModuleScreenMultipleValuesModel [Uri] ?moduleId={moduleId}&contractId={contractId}&roleId={roleId}
            SAVE_MULTI_REVISE: describeURL('post|_api/ModuleInstance/SaveMultipleReviseInstances'), // [Body] ModuleScreenMultipleValuesModel [Uri] ?moduleId={moduleId}&contractId={contractId}&roleId={roleId}
            UPDATE: describeURL('put|_api/Programs/UpdateFieldValuesDocument'), // [Body] ModuleScreenValuesModel [Uri] ?entityInstanceId={entityInstanceId}
            DELETE: describeURL('put|_api/Programs/Delete'), // [Uri] ?moduleId={moduleId}&entityInstanceId={entityInstanceId}&contractId={contractId}
            TRANSITIONS: describeURL('_api/Programs/GetTransitions'), // [Uri] ?moduleId={moduleId}&entityInstanceId={entityInstanceId}&contractId={contractId}
            PREVIEW: describeURL('_api/Programs/PreviewTemplate'),
            DO_APPROVAL: describeURL('put|_api/Programs/DoApproval'), // [Body] DoApprovalModel  [Uri] ?moduleId={moduleId}&roleId={roleId}
            GET_FIELDS: describeURL('_api/Programs/GetFieldsEntityInstance'), // [Uri] ?workflowId={workflowId}&entityInstanceId={entityInstanceId}&transitionId={transitionId}&contractId={contractId}&actionId={actionId}&excludeRoleId={excludeRoleId}&screenId={screenId}
            ROLLBACK: describeURL('put|_api/Programs/RollbackLastTransition') // [Uri] ?actionInstanceId={actionInstanceId}&contractId={contractId}
        },
        // PDF Markup
        PDF_MARKUP: {
            GET_TOKEN: describeURL('_api/PDFMarkup/GetToken'), // [Uri] ?documentId={documentId}&moduleId={moduleId}&contractId={contractId}
            UPLOAD_DOCUMENT: describeURL('post|_api/PDFMarkup/UploadDocument'), // [Uri] ?fileId={fileId}&fileName={fileName}&versionId={versionId}&moduleId={moduleId}&contractId={contractId}
            UPLOAD_DOCUMENTS: describeURL('post|_api/PDFMarkup/UploadDocuments'), // [Uri] ?moduleId={moduleId}&contractId={contractId}
            GET_GRID: describeURL('_api/PDFMarkup/Grid'), // [Uri] ?moduleId={moduleId}&contractId={contractId}
            GET_MIME_TYPES: describeURL('_api/PDFMarkup/MimeTypes'),
            GET_USER_DICT: describeURL('_api/PDFMarkup/UserDict'), // [Uri] ?contractId={contractId}
            GET_FLATTENED_DOCUMENT: describeURL('_api/PDFMarkup/FlattenedDocument'), // [Uri] ?documentId={documentId}&moduleId={moduleId}&contractId={contractId}
            GET_VERSIONS: describeURL('_api/PDFMarkup/GetVersions'), // [Uri] ?contractId={contractId}&fileId={fileId}&isMarkup={isMarkup}
            GET_DOC_METADATA: describeURL('_api/PDFMarkup/DocumentMetadata'), // [Uri] ?fileId={fileId}
            GET_ASSOCIATED_DOCUMENTS: describeURL('_api/PDFMarkup/GetAssociatedDocuments'), // [Uri] ?contractId={contractId}&fileVersionId={fileVersionId}
            GET_ASSOCIATION_DETAILS: describeURL('_api/PDFMarkup/AssociationDetails'), // [Uri] ?contractId={contractId}&fileVersionId={fileVersionId}
            GET_LINKABLE_MODULES: describeURL('_api/PDFMarkup/GetLinkableModules'), // [Uri] ?contractId={contractId}
            GET_LINKABLE_DOCUMENTS: describeURL('_api/PDFMarkup/GetLinkableDocuments'), // [Uri] ?contractId={contractId}&moduleId={moduleId}
            SAVE_DOC_LINK: describeURL('post|_api/PDFMarkup/SaveDocumentLink'), // [Uri] ?contractId={contractId}&moduleTypeId={moduleTypeId}&entityInstanceId=${entityInstanceId}
            SAVE_DOC_COVERS: describeURL('post|_api/PDFMarkup/SaveDocCovers'), // [Uri] ?contractId={contractId}&fileId={fileId}&documentId={documentId}
            SAVE_ANNOTATIONS: describeURL('post|_api/PDFMarkup/SaveAnnotations'), // [Body] PDFAnnotationsModel [Uri] ?contractId={contractId}
            DELETE_DOC: describeURL('put|_api/PDFMarkup/DeleteDocument'), // [Uri] ?contractId={contractId}&fileId={fileId}
            DELETE_DOC_LINK: describeURL('delete|_api/PDFMarkup/DeleteDocumentLink'), // [Uri] ?contractId={contractId}&fileVersionId={fileVersionId}&moduleTypeId={moduleTypeId}
            DELETE_DOC_LINK_ID: describeURL('delete|_api/PDFMarkup/DeleteDocumentLinkByDocument'), // [Uri] ?contractId={contractId}&documentId={documentId}&moduleTypeId={moduleTypeId}
            GET_EI_LINKS: describeURL('_api/PDFMarkup/GetEntityInstanceLinks') // [Uri] ?entityInstanceId={entityInstanceId}
        },
        MAPS: {
            //moduleId needs to be passed in the header on all calls
            GRID_FIELDS: describeURL('_api/MapsNew/GridFields'), // [Uri] ?workflowId={workflowId}&moduleId={moduleId}&contractId={contractId}
            GRID_SEARCH: describeURL('post|_api/MapsNew/GridSearch'), // [Uri] ?moduleId={moduleId}&contractId={contractId} [Body] SearchViewModel; 
        },
        WOPI: {
            DISCOVERY: describeURL('_api/wopi/Discovery'), // [Uri] /fileId
        }
    });

    cipo.constant("ADJUSTMENTS", {
        types: [
            { key: 1, value: "Recurring", name: "Recurring" },
            { key: 2, value: "One Time", name: "One Time" },
            { key: 3, value: "Accrual", name: "Accrual" },
        ],
        term: [
            { key: 1, value: "Charge" },
            { key: 2, value: "Bonus" },
        ]
    })

    cipo.constant("TABLE_ROW", {
        totalHeight: 57,
        labelHeight: 25,
        editorHeadingHight: 85
    });
    cipo.constant("ACTIONS", {
        REFRESH: {
            alwaysOnTop: true,
            name: "Refresh",
            actionType: "refresh",
            icon: "mdi mdi-refresh",
            noLabel: true
        },
        SHARE_DOCUMENTS: {
            name: 'Share Documents',
            actionType: "share-doc",
            alwaysOnTop: true,
            visible: true,
            noLabel: true,
            icon: 'mdi mdi-share',
            isGroup: true
        },
        EMAIL: {
            alwaysOnTop: true,
            name: "Email",
            actionType: "email",
            icon: "mdi mdi-email-send-outline",
            matIcon: 'forward_to_inbox',
        },
        FILTER: {
            alwaysOnTop: true,
            name: "Filter",
            actionType: "filter",
            icon: "mdi mdi-filter-menu",
            noLabel: true
        },
        REMOVE_FILTER: {
            alwaysOnTop: true,
            name: "Filter",
            actionType: "filter-remove",
            icon: "mdi mdi-filter-remove",
            noLabel: true
        },
        ADD_FIELD: {
            alwaysOnTop: true,
            name: "Add Field",
            actionType: "add-field",
            icon: "mdi mdi-text-box-plus",
            noLabel: true
        },
        REMOVE_FIELD: {
            alwaysOnTop: true,
            name: "Remove Field",
            actionType: "remove-field",
            icon: "mdi mdi-text-box-minus-outline",
            noLabel: true
        },
        ADD_HEADER: {
            alwaysOnTop: true,
            name: "Add Header",
            actionType: "add-header",
            icon: "mdi mdi-page-layout-header",
            //noLabel: true
        },
        ADD_FOOTER: {
            alwaysOnTop: true,
            name: "Add Footer",
            actionType: "add-footer",
            icon: "mdi mdi-page-layout-footer",
            //noLabel: true
        },
        SET_DEFAULT: {
            alwaysOnTop: true,
            name: "Set Default",
            actionType: "set-default",
            icon: "mdi mdi-check",
        },
        EDIT_PRINTOUTS: {
            alwaysOnTop: true,
            name: "Edit Printouts",
            actionType: "edit-printouts",
            icon: "mdi mdi-file-document-edit-outline",
        },
        SET_TAG: {
            alwaysOnTop: true,
            name: "Set Tag",
            actionType: "set-tag",
            icon: "mdi mdi-at",
        },
        UNSET_TAG: {
            alwaysOnTop: true,
            name: "Unset Tag",
            actionType: "unset-tag",
            icon: "mdi mdi-minus-circle-outline",
        },
        REMOVE_CURRENCY: {
            alwaysOnTop: true,
            name: "Remove Partial Payment",
            actionType: "remove-currency",
            icon: "mdi mdi-credit-card-remove-outline",
            noLabel: true
        },
        ADD_CURRENCY: {
            alwaysOnTop: true,
            name: "Set Partial Payment",
            actionType: "add-currency",
            icon: "mdi mdi-credit-card-plus-outline",
            noLabel: true
        },
        EDIT_CURRENCY: {
            alwaysOnTop: true,
            name: "Edit Partial Payment",
            actionType: "edit-currency",
            icon: "mdi mdi-credit-card-settings-outline",
            noLabel: true
        },
        SWAP: {
            alwaysOnTop: true,
            name: "Swap",
            actionType: "swap",
            icon: "mdi mdi-swap-vertical",
            noLabel: true
        },
        PRINT: {
            alwaysOnTop: true,
            name: "Print",
            actionType: "print",
            icon: "mdi mdi-printer",
            noLabel: true
        },
        SET_AVATAR: {
            alwaysOnRow: true,
            name: "Set as Profile Image",
            actionType: "set-avatar",
            icon: "mdi mdi-account-box",
            noLabel: true
        },
        SET_LOGO: {
            alwaysOnRow: true,
            name: "Set as Company Logo",
            actionType: "set-logo",
            icon: "mdi mdi-image",
            noLabel: true
        },
        MORE: {
            name: 'More',
            actionType: "more",
            alwaysOnTop: true,
            visible: true,
            noLabel: true,
            icon: 'mdi mdi-dots-vertical',
            isGroup: true
        },
        MOVE: {
            name: 'Move to Folder',
            actionType: "move",
            alwaysOnTop: true,
            alwaysOnRow: true,
            visible: true,
            noLabel: true,
            icon: 'mdi mdi-folder-move',
            isGroup: true
        },
        ARCHIVE: {
            alwaysOnTop: true,
            alwaysOnRow: true,
            name: 'Archive',
            actionType: "archive",
            noLabel: true,
            icon: 'mdi mdi-archive',
            matIcon: 'inventory_2',
        },
        CLOSE_CONTRACT: {
            alwaysOnTop: true,
            alwaysOnRow: true,
            name: 'Close Contract',
            actionType: "close-contract",
            noLabel: true,
            icon: 'mdi mdi-folder-lock',
            matIcon: 'lock'
        },
        DELETE: {
            alwaysOnTop: true,
            alwaysOnRow: true,
            name: "Delete",
            actionType: "delete",
            icon: "mdi mdi-delete",
            matIcon: "delete_outline",
            noLabel: true
        },
        DETAILS_VIEW: {
            alwaysOnTop: true,
            alwaysOnRow: true,
            name: "Edit Content",
            actionType: "details-view",
            icon: "mdi mdi-table-plus",
            noLabel: true
        },
        VIEW: {
            alwaysOnRow: true,
            name: "View",
            actionType: "view",
            icon: "mdi mdi-file-eye-outline",
            noLabel: true
        },
        ADD_CHILD: {
            alwaysOnTop: true,
            alwaysOnRow: true,
            name: "Edit",
            actionType: "add-child",
            icon: "mdi mdi-library-plus",
            noLabel: true
        },
        SAVE: {
            alwaysOnTop: true,
            alwaysOnRow: true,
            name: "Save",
            actionType: "save",
            icon: "mdi mdi-check",
            isPrimary: true,
            noLabel: true
        },
        CANCEL: {
            alwaysOnTop: true,
            alwaysOnRow: true,
            name: "Cancel",
            actionType: "cancel",
            icon: "mdi mdi-close",
            noLabel: true
        },
        DISCARD: {
            alwaysOnTop: true,
            alwaysOnRow: true,
            name: "Discard",
            actionType: "discard",
            noLabel: false
        },
        SHARE: {
            alwaysOnTop: true,
            alwaysOnRow: true,
            name: "Share File(s)",
            actionType: "share",
            icon: 'mdi mdi-account-plus',
            noLabel: true
        },
        UNSHARE: {
            alwaysOnTop: true,
            alwaysOnRow: true,
            name: "Unshare File(s)",
            actionType: "unshare",
            icon: 'mdi mdi-account-remove',
            noLabel: true
        },
        GLOBAL: {
            alwaysOnTop: true,
            alwaysOnRow: true,
            name: "Set File(s) Global",
            actionType: "set-global",
            icon: 'mdi mdi-earth-plus',
            noLabel: true
        },
        UNSET_GLOBAL: {
            alwaysOnTop: true,
            alwaysOnRow: true,
            name: "Unset File(s) Global",
            actionType: "unset-global",
            icon: 'mdi mdi-earth-minus',
            noLabel: true
        },
        CREATE: {
            alwaysOnTop: true,
            name: 'Add New',
            actionType: "create",
            type: 'primary',
            icon: 'fa fa-plus',
            //noLabel: true  
        },
        CREATE_IN_DROPDOWN: {
            alwaysOnTop: true,
            name: 'Add New',
            actionType: "create-in-dropdown",
            type: 'primary',
            icon: 'mdi mdi-plus',
            //noLabel: true  
        },
        CREATE_URL: {
            alwaysOnTop: true,
            name: 'Add New',
            actionType: "create-url",
            type: 'primary',
            icon: 'mdi mdi-link-plus',
            //noLabel: true  
        },
        CREATE_REPORT_MODULE: {
            alwaysOnTop: true,
            name: 'Add New',
            actionType: "create-reporting",
            type: 'primary',
            icon: 'mdi mdi-chart-areaspline',
            //noLabel: true  
        },
        INVITE: {
            alwaysOnTop: true,
            name: 'Invite',
            actionType: "invite",
            type: 'primary',
            icon: 'fa fa-plus',
            //noLabel: true  
        },
        UNINVITE: {
            alwaysOnTop: true,
            alwaysOnRow: true,
            name: 'Uninvite',
            actionType: "uninvite",
            type: 'primary',
            icon: "mdi mdi-delete",
            noLabel: true
        },
        CHANGE_EMAIL: {
            alwaysOnTop: false,
            alwaysOnRow: true,
            name: 'Change User Email',
            actionType: "change-email",
            type: 'primary',
            icon: "mdi mdi-email-send-outline",
            noLabel: true
        },
        SYNCSIG: {
            alwaysOnTop: true,
            name: 'Sync Signers',
            actionType: "sync-sig",
            type: 'primary',
            icon: 'fa fa-plus',
            //noLabel: true  
        },
        BACK: {
            alwaysOnTop: true,
            name: 'Back',
            actionType: "back",
            noLabel: true,
            icon: 'mdi mdi-arrow-left'
        },
        NEWFOLDER: {
            alwaysOnTop: true,
            name: 'New',
            actionType: "new-folder",
            icon: 'mdi mdi-folder-plus',
            noLabel: true
        },
        DETAILS: {
            alwaysOnRow: true,
            name: 'View',
            actionType: "details",
            icon: 'mdi mdi-pencil',
            matIcon: 'mode_edit_outline',
        },
        OVERVIEW: {
            alwaysOnRow: true,
            alwaysOnTop: false,
            name: 'Overview',
            actionType: "overview",
            icon: 'mdi mdi-camera'
        },
        DOWNLOAD_PDF: {
            alwaysOnRow: true,
            name: 'Download PDF',
            actionType: "download-pdf",
            icon: 'mdi mdi-file-pdf-outline'
        },
        DOWNLOAD_PDF_VAR: {
            alwaysOnRow: true,
            name: 'Download PDF',
            actionType: "download-pdf-var",
            icon: 'mdi mdi-pdf-box',
            matIcon: 'print',
        },
        REPORTS: {
            alwaysOnRow: true,
            name: 'Reports',
            actionType: "reports",
            icon: 'mdi mdi-text-box-multiple-outline'
        },
        EXCEL: {
            alwaysOnRow: false,
            alwaysOnTop: true,
            name: 'Export as Excel',
            actionType: "excel",
            icon: 'mdi mdi-file-excel'
        },
        REINVITE: {
            alwaysOnRow: true,
            name: 'Reinvite',
            actionType: "reinvite",
            icon: 'mdi mdi-account-convert'
        },
        EDIT: {
            alwaysOnRow: true,
            name: 'Edit',
            actionType: 'edit',
            icon: 'mdi mdi-square-edit-outline',
            matIcon: 'mode_edit_outline',
        },
        OPEN_FOLDER: {
            alwaysOnRow: true,
            name: 'Open',
            actionType: "open-folder",
            icon: 'mdi mdi-folder-open'
        },
        EDIT_FOLDER: {
            alwaysOnRow: true,
            name: 'Edit',
            actionType: "edit-folder",
            icon: 'mdi mdi-folder-edit-outline'
        },
        DOWNLOAD_ZIP: {
            alwaysOnTop: true,
            noLabel: true,
            alwaysOnRow: true,
            name: 'Download',
            actionType: "download-zip",
            icon: 'mdi mdi-download'
        },
        DOWNLOAD: {
            alwaysOnRow: false,
            name: 'Download',
            actionType: "download",
            icon: 'mdi mdi-download',
            matIcon: 'get_app',
        },
        UPLOAD: {
            alwaysOnTop: true,
            name: 'Upload',
            actionType: "upload",
            icon: 'mdi mdi-upload',
            noLabel: true
        },
        // for later
        UPLOADFILE: {
            alwaysOnTop: true,
            name: 'Import',
            actionType: "upload-file",
            icon: 'mdi mdi-upload',
            isFileType: 1,
            uploadMessage: "Uploading file(s)",
            generateFileIdUrl: '',
            startUploadUrl: '',
            uploadChunkUrl: '',
            uploadUrl: '', // this has to be added
            noLabel: true
        },
        OPENUPLOADDIALOG: {
            alwaysOnTop: true,
            name: 'Import',
            actionType: "open-upload-dialog",
            icon: 'mdi mdi-upload',
            isFileType: true,
            accept: "*",
            mimeTypeInUrl: false,
            // uploadMessage: "Uploading file(s)",
            multiple: false,
            urlParams: null,
            headerParams: null,
            isImport: false,
            isCreateVersion: true,
            closesOnFinish: false,
            methodOnFinish: "",
            generateFileIdUrl: '',
            startUploadUrl: '',
            uploadChunkUrl: '',
            uploadUrl: '', // this has to be added
            // noLabel: true
        },
        IMPORT: {
            alwaysOnTop: false,
            alwaysOnRow: false,
            name: 'Import',
            actionType: "import",
            icon: 'mdi mdi-file-import-outline'
        },
        EXPORT: {
            alwaysOnTop: false,
            alwaysOnRow: true,
            name: 'Export',
            actionType: "export",
            icon: 'mdi mdi-file-export-outline'
        },
        ACTIVATE: {
            alwaysOnTop: false,
            alwaysOnRow: true,
            name: 'Activate',
            actionType: "activate",
            icon: 'mdi mdi-checkbox-marked-circle-outline'
        },
        DEACTIVATE: {
            alwaysOnTop: false,
            alwaysOnRow: true,
            name: 'Deactivate',
            actionType: "deactivate",
            icon: 'mdi mdi-checkbox-blank-circle-outline'
        },
        TABLE: {
            alwaysOnTop: true,
            name: 'Table',
            actionType: "table",
            icon: 'mdi mdi-table-large'
        },
        LOG: {
            alwaysOnTop: true,
            name: 'Table',
            actionType: "log",
            icon: 'mdi mdi-view-headline'
        },
        WORKFLOW: {
            alwaysOnTop: true,
            name: 'Action',
            actionType: "workflow",
            icon: 'mdi mdi-folder-move',
        },
        PERCENT: {
            alwaysOnTop: true,
            name: 'Edit Percent',
            actionType: "percent",
            icon: 'mdi mdi-percent'
        },
        AMOUNT: {
            alwaysOnTop: true,
            name: 'Edit Amount',
            actionType: "amount",
            icon: 'mdi mdi-currency-usd'
        },
        DOCUMENTS: {
            alwaysOnTop: true,
            name: 'Table',
            actionType: "documents",
            icon: 'mdi mdi-text-box-multiple-outline',
            matIcon: 'folder_copy',
        },
        DUPLICATE: {
            alwaysOnRow: true,
            name: 'Duplicate',
            actionType: "duplicate",
            icon: 'mdi mdi-note-multiple-outline',
            matIcon: 'content_copy',
        },
        MANAGE_PRESETS: {
            alwaysOnRow: true,
            name: 'Manage Presets',
            actionType: "manage-presets",
            icon: 'mdi mdi-file-hidden'
        },
        CLOSE: {
            alwaysOnTop: true,
            alwaysOnRow: true,
            visible: true,
            noLabel: true,
            name: 'Close',
            actionType: "close",
            icon: 'mdi mdi-close'
        },
        PDF_MARKUP: {
            alwaysOnTop: true,
            alwaysOnRow: false,
            visible: true,
            noLabel: true,
            name: 'Markup',
            actionType: "pdf-markup",
            icon: 'mdi mdi-square-edit-outline'
        },
        PDF_MARKUP_ROW: {
            alwaysOnTop: false,
            alwaysOnRow: true,
            visible: true,
            noLabel: true,
            name: 'Markup',
            actionType: "pdf-markup-row",
            icon: 'mdi mdi-square-edit-outline'
        },
        VIEW_SOURCE: {
            alwaysOnTop: false,
            alwaysOnRow: true,
            visible: true,
            noLabel: false,
            name: 'View Source File',
            actionType: "view-source",
            icon: 'mdi mdi-exit-to-app'
        },
        RESTORE: {
            alwaysOnTop: true,
            alwaysOnRow: true,
            visible: true,
            noLabel: true,
            name: 'Restore',
            actionType: "restore",
            icon: 'mdi mdi-delete-restore'
        },
        CLOSE_ADJUSTMENT: {
            name: "Close Adjustment",
            actionType: "close-adjustment",
            icon: "mdi mdi-file-lock",
        }
    });
    // old fields
    cipo.constant("FIELDS_INFO", {
        '1': {
            //name: "Text",
            class: "text",
            type: "text",
            cols: 6,
            rows: 1,
            maxItemRows: 1
        },
        '2': {
            //name: "Number",
            class: "text",
            type: "number",
            cols: 3,
            rows: 1,
            maxItemRows: 1
        },
        '3': {
            //name: "Multiline text",
            class: "textarea",
            type: "textarea",
            cols: 12,
            rows: 1,
            minItemRows: 1,
            minItemCols: 4,
            maxItemRows: 1
        },
        '4': {
            //name: "Richtext",
            class: "editor",
            type: "editor",
            cols: 12,
            rows: 4,
            minItemRows: 1,
            minItemCols: 4,
            maxItemRows: 12
        },
        '5': {
            //name: "Select",
            class: "select",
            type: "select",
            cols: 6,
            rows: 1,
            maxItemRows: 1
        },
        '6': {
            //name: "Checkbox",
            class: "checkbox",
            type: "checkbox",
            cols: 6,
            rows: 1,
            maxItemRows: 1
        },
        '7': {
            //name: "Date Picker",
            class: "datepicker",
            type: "datetimepicker",
            cols: 6,
            rows: 1,
            maxItemRows: 1
        },
        '8': {
            //name: "Radio Buttons",
            class: "radio",
            type: "radio",
            cols: 6,
            rows: 1,
            maxItemRows: 1
        },
        '9': {
            //name: "Annotation",
            class: "text",
            type: "annotation",
            cols: 12,
            rows: 1,
            minItemRows: 1,
            minItemCols: 3,
            maxItemRows: 1
        },
        '10': {
            //name: 'Increment',
            class: "text",
            type: "increment",
            dataType: 'String',
            cols: 6,
            rows: 1,
            minItemRows: 1,
            maxItemRows: 1
        },
        '11': {
            //name: "Table",
            type: "table",
            class: "table",
            cols: 12,
            rows: 2,
            minItemRows: 2,
            minItemCols: 6,
            maxItemRows: 12
        },
        '12': {
            //name: "Attachment",
            class: "attachment",
            type: "attachment",
            cols: 12,
            rows: 1,
            maxItemRows: 1,
            minItemCols: 3,
            maxItemCols: 12
        },
        '13': {
            //name: "Multiselect",
            class: "select",
            type: "multiselect",
            cols: 6,
            rows: 1,
            maxItemRows: 1
        },
        '14': {
            //name: "Date",
            class: "datepicker",
            type: "datepicker",
            cols: 6,
            rows: 1,
            maxItemRows: 1
        },
        '15': {
            //name: "Time",
            class: "datepicker",
            type: "timepicker",
            cols: 6,
            rows: 1,
            maxItemRows: 1
        },
        '16': {
            //name: "Integer",
            class: "text",
            type: "integer",
            cols: 3,
            rows: 1,
            maxItemRows: 1
        },
        '17': {
            //name: "Formula",
            class: "text",
            type: "number",
            isFormula: true,
            cols: 3,
            rows: 1,
            maxItemRows: 1
        },
        '18': {
            //name: "small integer",
            class: "text",
            type: "integer",
            cols: 3,
            rows: 1,
            maxItemRows: 1
        },
        '19': {
            //name: "byte",
            class: "text",
            type: "integer",
            cols: 3,
            rows: 1,
            maxItemRows: 1
        },
        '20': {
            //name: "Single Relation",
            class: "select",
            type: "select",
            cols: 6,
            rows: 1,
            maxItemRows: 1
        },
        '21': {
            //name: "Multiple Relations",
            class: "select",
            type: "multiselect",
            cols: 6,
            rows: 1,
            maxItemRows: 1
        },
        '22': {
            //name: "Module",
            class: "select",
            type: "submodule",
            cols: 3,
            rows: 1,
            maxItemRows: 1
        },
        '23': {
            //name: "Money",
            class: "text",
            type: "money",
            cols: 3,
            rows: 1,
            maxItemRows: 1
        },
        '24': {
            //name: "Relation Formula",
            class: "text",
            type: "number",
            cols: 3,
            rows: 1,
            maxItemRows: 1
        },
        '25': {
            //name: "Relation Formula",
            class: "text",
            type: "weather",
            cols: 3,
            rows: 1,
            maxItemRows: 1
        }
    });

    cipo.constant('SET_FIELDS_GRIDSTER', function (field) {
        var gridsterTypes = {
            'Text': {
                //name: "Text",
                class: "text",
                type: "text",
                cols: 6,
                rows: 1,
                minItemCols: 2,
                maxItemRows: 1
            },
            'Number': {
                //name: "Number",
                class: "text",
                type: "number",
                cols: 4,
                rows: 1,
                minItemCols: 2,
                maxItemRows: 1
            },
            'Multiline': {
                //name: "Multiline text",
                class: "textarea",
                type: "textarea",
                cols: 12,
                rows: 1,
                minItemRows: 1,
                minItemCols: 3,
                maxItemRows: 1
            },
            'Editor': {
                //name: "Richtext",
                class: "editor",
                type: "editor",
                cols: 12,
                rows: 1,
                minItemRows: 1,
                minItemCols: 3,
                maxItemRows: 12
            },
            'Select': {
                //name: "Select",
                class: "select",
                type: "select",
                cols: 6,
                rows: 1,
                minItemCols: 2,
                maxItemRows: 1
            },
            'Checkbox': {
                //name: "Checkbox",
                class: "checkbox",
                type: "checkbox",
                cols: 6,
                rows: 1,
                minItemCols: 2,
                maxItemRows: 1
            },
            'Date': {
                //name: "Date Picker",
                class: "datepicker",
                type: "datetimepicker",
                cols: 6,
                rows: 1,
                minItemCols: 2,
                maxItemRows: 1
            },
            'Radio': {
                //name: "Radio Buttons",
                class: "radio",
                type: "radio",
                cols: 6,
                rows: 1,
                minItemCols: 3,
                maxItemRows: 3
            },
            'Annotation': {
                //name: "Annotation",
                class: "annotation",
                type: "annotation",
                cols: 12,
                rows: 1,
                minItemRows: 1,
                minItemCols: 3,
                maxItemRows: 1
            },
            'Table': {
                //name: "Table",
                type: "table",
                class: "table",
                cols: 12,
                rows: 1,
                minItemRows: 1,
                minItemCols: 6,
                maxItemRows: 12
            },
            'Attachment': {
                //name: "Attachment",
                class: "attachment",
                type: "attachment",
                cols: 12,
                rows: 1,
                maxItemRows: 1,
                minItemCols: 3,
                maxItemCols: 12
            },
            'Formula': {
                //name: "Formula",
                class: "text",
                type: "number",
                isFormula: true,
                cols: 3,
                rows: 1,
                maxItemRows: 1
            },
            'Weather': {
                //name: "Weather",
                class: "text",
                type: "weather",
                cols: 12,
                rows: 1,
                maxItemRows: 1
            }
        };

        var props;

        // text
        if (field.displayTypeId == 1 && field.typeId == 1)
            props = gridsterTypes['Text'];
        // number
        if (field.displayTypeId == 1 && (field.typeId == 2 || field.typeId == 9))
            props = gridsterTypes['Number'];
        // multiline
        if (field.displayTypeId == 2)
            props = gridsterTypes['Multiline'];
        // editor
        if (field.displayTypeId == 9) props = gridsterTypes['Editor'];
        // yes/no
        if (field.typeId == 3)
            props = gridsterTypes['Checkbox'];
        // select user relation
        if (field.displayTypeId == 3 || field.typeId == 6 || field.typeId == 10)
            props = gridsterTypes['Select'];

        // date
        if (field.typeId == 4)
            props = gridsterTypes['Date'];

        // annotation
        if (field.typeId == 50)
            props = gridsterTypes['Annotation'];

        // attachment
        if (field.typeId == 5)
            props = gridsterTypes['Attachment'];

        // attachment
        if (field.typeId == 7)
            props = gridsterTypes['Table'];

        // radio
        if (field.displayTypeId == 4)
            props = gridsterTypes['Radio'];
        
        // weather
        if (field.typeId == 11 || field.displayTypeId == 10)
            props = gridsterTypes['Weather']; 

        for (var key in props) {
            if (props.hasOwnProperty(key)) {
                // is no cols and rows, they are added
                if (!field.cols) field.cols = props.cols;
                if (!field.rows) field.rows = props.rows;
                // add the rest of the properties
                if (key != 'cols' && key != 'rows') field[key] = props[key];
                // downsize if necessary
                if (field.rows > props.maxItemRows) field.rows = props.maxItemRows;
            }


        }

    });
    // just an fyi // fields on module configuration
    cipo.constant("fieldOrigin", {
        property: 1,
        relation: 2,
        screen: 3
    });

    // just an fyi // fields on module configuration
    cipo.constant("AggregateOperators", {
        1: "SUM",
        2: "AVG",
        3: "MIN",
        4: "MAX",
        5: "COUNT"
    });

    cipo.constant("AggregateOperatorsDate", {
        3: "MIN",
        4: "MAX",
        5: "COUNT"
    });

    // action types on module configuration
    cipo.constant("ActionTypes", {
        WorkflowTransitions: 1,
        DraftTransition : 2,
        ReviseTransition : 3,
        UpdateDocument : 4,
        Comment : 5,
        Approvals : 6,
        ChangeDocumentNumber : 7,
        AssociateVirtualDrawing : 8,
        SaveInstanceFromTemplate : 9,
        PreferredFieldValue: 10,
        RollbackTransition: 11,
        SaveInstanceFromCorrespondence: 12,
        SaveNotificationReplayFromCorrespondence: 13,
        Assignment: 14,
        Email: 15
    });

    cipo.constant("FIELDS_CONFIG", function (mimetypes) {
        var mimetypesDict = mimetypes || [];
        
        //field types typeId
        var fieldTypes = {
            1: "Text",
            2: "Number",
            3: "Yes/No",
            4: "Date-Time",
            5: "Attachment",
            6: "User",
            7: "Table",
            8: "Submodule",
            9: "Formula",
            10: "Relation",
            11: "Weather",
            50: "Annotation",
        };

        // display types
        var displayTypeDict = {
            1: { key: 1, name: "Text" },
            2: { key: 2, name: "MultiLine" },
            3: { key: 3, name: "Select" },
            4: { key: 4, name: "Radio" },
            5: { key: 5, name: "CheckBox" },
            6: { key: 6, name: "Attachment" },
            7: { key: 7, name: "User" },
            8: { key: 8, name: "Table" },
            9: { key: 9, name: "Editor" },
            10: { key: 10, name: "Weather" }
        };

        //field types typeId
        var fieldTypesMapping = {
            1: {
                categoryName: "Text",
                icon: "mdi mdi-format-text",
                canHaveMultipleValues: true,
                restrictionsList: ['ReadOnly', 'Required', 'MaximumLength'],
                defaultRestrictions: [false, false],
                displayTypes: [1, 2, 9, 3, 4],
                1: {// text
                    fieldTypeName: "Text",
                    icon: "mdi mdi-format-text",
                    fieldTypeNameMultiple: "Text (Multiple)",
                    formattingsList: [],
                    defaultFormattingIds: [],
                    hasDataSource: false
                },
                2: {// multiline
                    fieldTypeName: "Multiline",
                    icon: "mdi mdi-text",
                    fieldTypeNameMultiple: "Multiline (Multiple)",
                    formattingsList: [],
                    defaultFormattingIds: [],
                    hasDataSource: false
                },
                9: {// editor
                    fieldTypeName: "Editor",
                    icon: "mdi mdi-format-size",
                    fieldTypeNameMultiple: "Editor (Multiple)",
                    formattingsList: [],
                    defaultFormattingIds: [],
                    hasDataSource: false
                },
                3: {// select
                    fieldTypeName: "Select",
                    icon: "mdi mdi-menu-down",
                    fieldTypeNameMultiple: "Multiselect",
                    formattingsList: [],
                    defaultFormattingIds: [],
                    hasDataSource: true
                },
                4: {//checkbox
                    fieldTypeName: "Radio Group",
                    icon: "mdi mdi-radiobox-marked",
                    fieldTypeNameMultiple: "Checkbox List",
                    formattingsList: [],
                    defaultFormattingIds: [],
                    hasDataSource: true
                }
            },
            2: {
                categoryName: "Number",
                icon: "mdi mdi-numeric",
                canHaveMultipleValues: true,
                restrictionsList: ['ReadOnly', 'Required', 'ShowThousandSeparator', 'Number'],
                defaultRestrictions: [false, false, false, 1],
                displayTypes: [1],
                1: {
                    fieldTypeName: "Text",
                    icon: "mdi mdi-numeric",
                    fieldTypeNameMultiple: "Text (Multiple)",
                    formattingsList: ['Number', 'Decimals'],
                    defaultFormattingIds: [1, 2],
                    hasDataSource: false
                }
            },
            3: {
                categoryName: "Yes/No",
                icon: "mdi mdi-checkbox-marked",
                canHaveMultipleValues: false,
                restrictionsList: ['ReadOnly', 'NeedsToBeChecked'],
                defaultRestrictions: [false, false],
                displayTypes: [5],
                5: {
                    fieldTypeName: "Checkbox",
                    icon: "mdi mdi-checkbox-marked",
                    fieldTypeNameMultiple: "Checkbox",
                    formattingsList: [],
                    defaultFormattingIds: [],
                    hasDataSource: false
                }
            },
            4: {
                categoryName: "Date-Time",
                icon: "mdi mdi-calendar",
                canHaveMultipleValues: false,
                restrictionsList: ['ReadOnly', 'Required', 'DateTime'],
                defaultRestrictions: [false, false, 3],
                displayTypes: [1],
                1: {
                    fieldTypeName: "Date-Time",
                    icon: "mdi mdi-calendar",
                    fieldTypeNameMultiple: "Date-Time (Multiple)",
                    formattingsList: [],
                    defaultFormattingIds: [],
                    hasDataSource: false
                }
            },
            5: {
                categoryName: "Attachment",
                icon: "mdi mdi-attachment",
                canHaveMultipleValues: true,
                restrictionsList: ['ReadOnly','Required',  'MimeTypes'],
                defaultRestrictions: [false, false, [0]],
                displayTypes: [6],
                6: {
                    fieldTypeName: "Attachment",
                    icon: "mdi mdi-attachment",
                    fieldTypeNameMultiple: "Attachment (Multiple)",
                    formattingsList: [],
                    defaultFormattingIds: [],
                    hasDataSource: false
                }
            },
            //6: {
            //    categoryName: "User",

            //},
            7: {
                categoryName: "Table",
                icon: "mdi mdi-table-large",
                canHaveMultipleValues: false,
                restrictionsList: ['Required'],
                defaultRestrictions: [false],
                displayTypes: [8],
                8: {
                    fieldTypeName: "Table",
                    icon: "mdi mdi-table-large",
                    fieldTypeNameMultiple: "Table (Multiple)",
                    formattingsList: [],
                    defaultFormattingIds: [],
                    hasDataSource: false
                }
            },
            /* NOT USED ANYMORE
            9: {
                categoryName: "Relation Formula",
                icon: "mdi mdi-function-variant",
                canHaveMultipleValues: false,
                restrictionsList: ['ReadOnly', 'Required', 'ShowThousandSeparator', 'Number'],
                defaultRestrictions: [false, false, false, 1],
                displayTypes: [1],
                1: {
                    fieldTypeName: "Text",
                    icon: "mdi mdi-function-variant",
                    fieldTypeNameMultiple: "Text (Multiple)",
                    formattingsList: ['Number', 'Decimals'],
                    defaultFormattingIds: [1, 2],
                    hasDataSource: false
                }
            },*/
            10: {
                categoryName: "Relation",
                icon: "mdi mdi-table-large",
                canHaveMultipleValues: true,
                restrictionsList: ['ReadOnly', 'Required'],
                defaultRestrictions: [false, false],
                displayTypes: [3],
                3: {// select
                    fieldTypeName: "Select",
                    icon: "mdi mdi-menu-down",
                    fieldTypeNameMultiple: "Multiselect",
                    formattingsList: [],
                    defaultFormattingIds: [],
                    hasDataSource: true
                },
            },
            11: {
                categoryName: "Weather",
                icon: "mdi mdi-weather-cloudy",
                canHaveMultipleValues: false,
                restrictionsList: ['Required'],
                defaultRestrictions: [false],
                displayTypes: [10],
                10: {
                    fieldTypeName: "Table",
                    icon: "mdi mdi-table-large",
                    fieldTypeNameMultiple: "Table (Multiple)",
                    formattingsList: [],
                    defaultFormattingIds: [],
                    hasDataSource: false
                }
            },
            //8: {
            //    categoryName: "Submodule"
            //},
            //10: {
            //    categoryName: "Relation"
            //},

        };


        var restrictions = {
            ReadOnly: { elements: [], key: 1, name: "ReadOnly", value: false },
            Required: { elements: [], key: 2, name: "Required", value: false },
            NeedsToBeChecked: { elements: [], key: 3, name: "NeedsToBeChecked", value: false },
            Number: {
                elements: [
                    { key: 1, name: "Decimal" },
                    { key: 2, name: "Integer" }],
                required: true,
                key: 4, name: "Number", value: 0
            },
            DateTime: {
                elements: [
                    { key: 3, name: "Date", value: "L" },
                    { key: 4, name: "DateTime", value: "L LT" },
                    { key: 5, name: "Time", value: "LT" }],
                required: true,
                key: 5, name: "DateTime", value: 0
            },
            MimeTypes: {
                elements: mimetypesDict, key: 6, property: "mimeTypes",
                name: "MimeTypes", mimeTypes: [0], isMultiple: true
            },
            ShowThousandSeparator: { elements: [], key: 7, name: "ShowThousandSeparator", value: true },
            MaximumLength: { elements: [], key: 8, name: "MaximumLength", type:'number' },
        };
        var formattings = {
            Text: {
                elements: [
                    { key: 6, name: "Text" },
                    { key: 5, name: "Multiline" },
                    { key: 4, name: "Editor" }],
                key: 3, name: "Text", value: 1
            },
            Number: {
                elements: [
                    { key: 1, name: "Number" },
                    { key: 2, name: "Money" },
                    { key: 3, name: "Percentage" }],
                key: 1, name: "Number", value: 1
            },
            Decimals: { elements: [], key: 2, name: "Decimals", value: 2 }
        };

        var generate_map = function (obj) {
            var mapping = {};
            for (var prop in obj) {
                if (obj.hasOwnProperty(prop)) {
                    mapping[obj[prop].key] = {};
                    mapping[obj[prop].key].name = obj[prop].name;
                    if (obj[prop].required) mapping[obj[prop].key].required = obj[prop].required;
                    if (typeof obj[prop].value != 'undefined')
                        mapping[obj[prop].key].value = obj[prop].value;
                    if (typeof obj[prop].property != 'undefined') {
                        mapping[obj[prop].key].property = obj[prop].property;
                        mapping[obj[prop].key][mapping[obj[prop].key].property] = obj[prop][mapping[obj[prop].key].property];
                    }
                    if (obj[prop].isMultiple) {
                        mapping[obj[prop].key].isMultiple = obj[prop].isMultiple;
                    }

                    mapping[obj[prop].key].elements = {};
                    if (obj[prop].elements.length) {
                        for (var i = 0; i < obj[prop].elements.length; i++) {
                            mapping[obj[prop].key].elements[obj[prop].elements[i].key] = obj[prop].elements[i].name;
                        }
                    }
                }

            }
            return mapping;
        };

        var fieldsList = [
            {// text
                details: { typeId: 1 },
                fields: [
                    { displayTypeId: 1 },// text
                    { displayTypeId: 2 },//multiline
                    { displayTypeId: 9 },//editor
                    { displayTypeId: 3 },//select
                    { displayTypeId: 4 }// radio
                ]
            },
            {// number
                details: { typeId: 2 },
                fields: [
                    { displayTypeId: 1 }, // text
                    // to add select and radio
                ]
            },
            {// yes/no
                details: { typeId: 3 },
                fields: [
                    { displayTypeId: 5 } // checkbox
                    // to add radio
                ]
            },
            {// date
                details: { typeId: 4 },
                fields: [
                    { displayTypeId: 1 },// date
                    // to add select and radio
                ]
            },
            {// attachment
                details: { typeId: 5 },
                fields: [
                    { displayTypeId: 6 }//attachment
                ]
            },
            {// table
                details: { typeId: 7 },
                fields: [
                    { displayTypeId: 8 }//table
                ]
            },
            /*
            {// formula
                details: { typeId: 9 },
                fields: [
                    { displayTypeId: 1 } // text
                ]
            },*/
            {// weather
                details: { typeId: 11 },
                fields: [
                    { displayTypeId: 10 } //weather
                ]
            }
            //{

            //    details: {
            //        categoryName: "User",
            //        canHaveMultipleValues: true,
            //        restrictionsList: ['ReadOnly', 'Required'],
            //        typeId: 6
            //    },

            //    fields: [
            //        //{
            //        //    fieldTypeName: "User",
            //        //    fieldTypeNameMultiple: "User",
            //        //    displayTypeId: 7,
            //        //    formattingsList: [],
            //        //    defaultFormattingIds: []
            //        //},
            //        {
            //            fieldTypeName: "Select",
            //            fieldTypeNameMultiple: "Multiselect",
            //            displayTypeId: 3,
            //            formattingsList: [],
            //            defaultFormattingIds: []
            //        },
            //        {
            //            fieldTypeName: "Group (Radio)",
            //            fieldTypeNameMultiple: "Group (Checkbox)",
            //            displayTypeId: 4,
            //            formattingsList: [],
            //            defaultFormattingIds: []
            //        }
            //    ]
            //}
        ];
        var isNumber = function (n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) };
        for (var i = 0; i < fieldsList.length; i++) {
            var typeId = fieldsList[i].details.typeId;

            for (var prop in fieldTypesMapping[typeId]) {
                if (fieldTypesMapping[typeId].hasOwnProperty(prop) && !isNumber(prop)) {
                    fieldsList[i].details[prop] = fieldTypesMapping[typeId][prop];
                }
            }
            
            for (var j = 0; j < fieldsList[i].fields.length; j++) {
                var fieldDisplayTypeId = fieldsList[i].fields[j].displayTypeId;
                for (var key in fieldTypesMapping[typeId][fieldDisplayTypeId]) {
                    if (fieldTypesMapping[typeId][fieldDisplayTypeId].hasOwnProperty(key)) {
                        fieldsList[i].fields[j][key] = fieldTypesMapping[typeId][fieldDisplayTypeId][key];
                    }
                }
            }
        }

        return {
            fieldTypes: fieldTypes,
            fieldTypesMapping: fieldTypesMapping,
            displayTypeDict: displayTypeDict,
            restrictions: restrictions,
            restrictionsMapping: generate_map(restrictions),
            formattings: formattings,
            formattingsMapping: generate_map(formattings),
            fieldsList: fieldsList
        };

    });


    cipo.constant("MODAL", {
        title_new: 'Add New',
        title_loading: 'View/Edit',
        actions: {
            new: {
                cancel: 'Cancel',
                save: 'Add'
            },
            existing: {
                cancel: 'Close',
                save: 'Update'
            },
            enable: 'Enable',
            disable: 'Disable',
            revert: 'Discard Changes',
            update_users: 'Update Users',
            update_permissions: 'Update Permissions'
        },
        tabs: {
            overview: 'Overview',
            details: 'Details',
            presets: 'Presets',
            assignments: 'Assignments',
            radjustments: 'Recurring Adjustments',
            otadjustments: 'One Time Adjustments',
            adjustments: 'Adjustments',
            permissions: 'Permissions',
            assets: 'Assets',
            workflows: 'Workflows',
            screens: 'Start Screen',
            states: 'States',
            print: 'Print',
            users: 'Users',
            fields: 'Fields'
        },
        files: {
            rename: 'Rename File',
            rename_input_field: 'New File Name'
        },
        folders: {
            rename: 'Rename Folder',
            rename_input_field: 'New Folder Name'
        },
        messages: {
            save: 'Your changes are not saved. What do you want to do:'
        }
    });

    cipo.constant("LABELS", {
        CLOSE: "Close",
        SAVE: "Save"
    });

    cipo.constant("WEEKDAYS", [
        { key: 1, value: "Monday" },
        { key: 2, value: "Tuesday" },
        { key: 3, value: "Wednesday" },
        { key: 4, value: "Thursday" },
        { key: 5, value: "Friday" },
        { key: 6, value: "Saturday" },
        { key: 7, value: "Sunday" }
    ]);

    cipo.constant("MODULE_TYPES", {
        SYSTEM: 1,
        URL: 2,
        DYNAMIC: 3,
        INTERNAL: 4,
        TABLE: 5,
        REPORTING: 6
    });

    cipo.constant("sysMenu", [
        {
            "id": 1,
            "name": "Tenants",
            "code": "tenants",
            "iconId": 8,
            "position": 0,
            "parentId": 0,
            "disabled": false,
            "typeId": 3,
            "typeName": "Screen",
            "isModule": false,
            "primitiveId": 12,
            "isPrimitive": true,
            "isUrl": false
        }
    ]);

    cipo.constant("Permissions", {
        1: { Create: true },
        2: { Read: true },
        3: { Update: true },
        4: { Delete: true },
        5: { Print: true },
        6: { Signatures: true },
        7: { Assignments: true },
        8: { ExcludeActivityItem: true },
        9: { ReleaseAdjustment: true },
        10: { PayReleasedAdjustment: true }
    });


    cipo.constant("FILTERS", function () {
        //
        var logic = [
            { key: 0, value: "Filter by all selected criteria", icon: "mdi mdi-set-center paleNeutralText" },
            { key: 1, value: "Filter by any selected criteria", icon: "mdi mdi-set-all paleNeutralText" },
        ];

        var operators = {
            0: "Equals",
            20: "Contains",
            22: "Starts With",
            23: "Ends With",
            30: "Less Than",
            31: "Less Than Or Equals",
            32: "More Than",
            33: "More Than Or Equals",
            40: "Between",
            41: "On",
            42: "Before",
            43: "On Or Before",
            44: "After",
            45: "On Or After"
        };

        var operatorsMapping = {
            // fieldTypeId + displayTypId
            // string inputs
            11: [0, 20, 22, 23],//text
            12: [0, 20, 22, 23],//multiline
            19: [0, 20, 22, 23],//editor
            56: [0, 20, 22, 23],//attachment
            // number fields
            21: [0, 30, 31, 32, 33],//number
            // select fields
            13: [0], // select
            14: [0], // radio
            35: [0], // checkbox
            // date, time, datetime
            41: [44, 45, 42, 43, 40, 41],
            // relations
            103: [0], // select
        };

        var operatorsDict = {};

        for (var key in operatorsMapping)
            if (operatorsMapping.hasOwnProperty(key)) {
                operatorsDict[key] = [];
                for (var i = 0; i < operatorsMapping[key].length; i++) {
                    operatorsDict[key].push({ key: operatorsMapping[key][i], value: operators[operatorsMapping[key][i]] })
                }
            }

        var filterTypeMapping = {
            // string inputs
            11: "text", // 0 - input type text
            12: "text",
            19: "text",
            56: "text", // attachment
            // number fields
            21: "number",
            // select fields
            13: "multiselect", // 1 - multiselect
            14: "multiselect",
            35: "select", // checkbox
            103: "multiselect",  // 10 - relation
            // date, time, datetime
            41: "datetimepicker",  // 2 - datepicker

        };

        var checkboxSourceDict = [
            { key: 0, value: "Any" },
            { key: true, value: "Yes" },
            { key: false, value: "No" },

        ];


        return {
            logic: logic,
            operators: operators,
            operatorsMapping: operatorsMapping,
            operatorsDict: operatorsDict,
            filterTypeMapping: filterTypeMapping,
            checkboxSourceDict: checkboxSourceDict


        }

    });

    cipo.constant("FilterLogic", {
        0: "AND",
        1: "OR"
    });

    cipo.constant("FilterOperators", {
        20: "Contains",
        22: "Starts With",
        23: "Ends With",
        0: "Equals",
        30: "Less Than",
        31: "Less Than Or Equals",
        32: "More Than",
        33: "More Than Or Equals",
        40: "Between",
        41: "On",
        42: "Before",
        43: "On Or Before",
        44: "After",
        45: "On Or After"
    });

    cipo.constant("FilterOperatorFieldsMapping", {
        // string inputs
        1: [20, 22, 23, 0],
        3: [20, 22, 23, 0],
        4: [20, 22, 23, 0],
        9: [20, 22, 23, 0],
        // number fields
        2: [0, 30, 31, 32, 33],
        16: [0, 30, 31, 32, 33],
        17: [0, 30, 31, 32, 33],
        18: [0, 30, 31, 32, 33],
        19: [0, 30, 31, 32, 33],
        23: [0, 30, 31, 32, 33],
        24: [0, 30, 31, 32, 33],
        // select fields
        5: [0],
        8: [0],
        20: [0],
        // date, time, datetime
        14: [40, 41, 42, 43, 44, 45],
        15: [40, 42, 43, 44, 45],
        7: [40, 41, 42, 43, 44, 45],
    });

    cipo.constant("FilterTypeFieldsMapping", {
        // string inputs
        1: 0, // 0 - input type text
        3: 0,
        4: 0,
        9: 0,
        // number fields
        2: 0,
        16: 0,
        17: 0,
        18: 0,
        19: 0,
        23: 0,
        24: 0,
        // select fields
        5: 1, // 1 - multiselect
        8: 1,
        20: 1,
        // date, time, datetime
        14: 2,  // 2 - datepicker
        15: 3,  // 3 - timepicker
        7: 4,   // 4 - datetimepicker
    });

    cipo.constant("PSPDFKIT_CONFIG", {
        LICENSE_KEY: function () {
            return location.origin.indexOf('localhost') != -1
                // localhost
                  // 'bXzFD1tgssdik2wWx1jeGv-2IYdNcDGO0PQLCNRzlo_ilijQDnV234WGwAPyJnfnNhSIUWkBZc9W-3QrcxZ--PU3WeJLDajy3xQZQ1H3C6p1F4RjYslX5HUi5oAJmDu_jUNuu2HQ0tmYuU1pcCIdp3-0Ytzj90VE88FPwx7ECQs1-d_0r0zp1M1Q0U3jUUs2w8qSs_pELTJcdmlFSP7X85aV4w8PwTPJPjTOxSiSvPklCMyns1a0ibaLCaIVHL5EHlg6LdM-kG_v6OlCGF7XcPuTQRF9VJEq5rkAHjU9OZ4Oh3DHzM8SShi1_raiOpJ4XuLm5vhNlxEmWSYivnN5xiXKDvfnwv8Q56BdhsUg9hxYZ-2j3nCFtukno0rotdnmqLgvuJgBsmzD-TaXVUfJJEADF6sP65DbHwxm_BLsRAfPM88j1pmYpRNX6PNfekS2yBA-Bb_GhApOQzThrsKE6xxmcsP2tXc7-VhUtNnJN3YjTw1YWYZr4C_PZRTX5geYCcCjUmuYWs7BYeIBGcMgSuCqKaKK28mIeEAFyytLzHUAM-NBtnpMGObGlf3UEKU9_fjh1WbkdcDMqHUgmk_SZBk8jXqakZJT21fWdP-v57XzzRPsJz1olI1mDZUEejH7z_fWUhkT6UU9hSAUHCcz_n32eapU8OGhBlAnFB-rfjk='
                  // new
                ? 'WA42OyylZjAlih5QG9_ySMdcz7Ov0ELmIACo5lGTvuBk6BQ8eqxPhtUcd1dbllDYRoipJGEXXacUsuCeXPXnxf80j1kFFL2sr_jdEddiAK6V9HSZde__AL9eAmxHnbXCBVocB1_qg8grB55kE0ktPx76Yep4tSZbHrZkeJ2emR0Vx6P3APp5iHyaPIYjjLA6ywqYRhzPPGGy5ss0TJ0NXPEqkzMrrTdYwMhR2yzsckDw1GDmMGc_mVR_2OjYAWgnZvSgVGt5ff2W39912DdGENtO-DTehJGvEcmPu3l-Rlmrk939k1Mrfi1G_Js5JsAZauOymEL_SurZNLhW-8DEESvcbYNb5e9uZCI3drL_AttMbtBL_NrXItsbO--OqjCe-juQZHTl8T_vc-ytcMkUBxG5p9UWNTqeHoghGjyGFYC1wMDwE35UsFl-I7FdBXy500SwF3x-O_YR6ESFZLkHZSLSoSPQ3k69bN4i-RsNVrj-WfsG5hEohFhF_-ue97W5Ox6C7KhdZXQvKDD2H8WYArAKtnXeV7_VKShn8FjJi1mgAJuWDKWN8v-JB16MUSiU9AWt-y1v3POODzfF0y6p5X6ps4o-ERXbq06hxG1gzoCY4PDgqxW8FOzl0QnJ5AVPF4lkys3folJqEuABR-J51TeS4FKD9YgPgeEIRhBd9D4='
                : location.origin.indexOf('dev.cipo.cloud') != -1
                    // dev                    
                    // 'sWmqUJayPodOz9uZhGqrTs6oUD-wI9RlsxJE5H1DwDig6hcnJqC9oxHMFL57iq5jqsCdn8iI6WBnyO08KpJniX3t76nBeq_OdeTBjjAbl3ofmQLZ4D6ANl34QQhfTAlnvSdXaFMvG2BFGTZKhvnQlsPtshopbU-rZMTRiPjNMp6JAy0V5oDqr-Mua1fRfvKZbH5MKFl_YYQvYMS0Q97_EDICQENkpDGfOFZPYSbEElZmjJM534xPkjQ19aUdO8AY3-9idMa_gXc9ilPI2k3XyLCi74agpXAma-gcOhzaq-yxyKj6DQy0KqmU7mPGY4X33FfYJtfDRqKdpAMnmuPtr8aP35yYpbUgon7dghdT7DJ7IHjgTXeJNbE3e-7UTvIJQfv6CM3zDjCrTLTKKIYvRkRtyuynC7nlJBcDGUZBFmYkxEi4OlNW4HlLssmTVGkwXiAGFmNMRgyiT2qSnhLTzQOm2U5QDFV22dmwchX3Q_CPjC88oU5TXuNH77y_llW9ALpyZR9LbPzST9HLYkw-ZIzvtu0Ae80UidNba1u1s394Wyaca4rqz8Qeuk6RUTVJacH6kojA9tuVOVCNrbIC28j0Eu0Liv4pG_gYUlgo_Ffs7f-cTMJ5lE7qw1u30ktgykjx5w0DErIeWV9R-fQW_1hLBTVZab8yLlJ5JJq6DwE='
                    // new
                    ? 'PO5Wk20YbnUd99X9Pknlu3pEHghmU0D1mttqSfMTCtjve6tzTPz_hXZ1VFJxXCYt4bSvGyszRUvpSh_6CdVGsLEWxThk7kHOEaPYKBUs5kyqnfScPms33tPX66mg394HyZTOu28X42Zl2WIQ4JW2wBTWqWx9igA414SyXku9gnn6y1Dx2JNFyxuhQQx2HI0mITT-vSMl3YYUViz_5CHr33y7nmXJUdMOYARdauhRfgrXxXgh5znzoJ0l_Cg8eayxodxMXp4-tDmV9PpYlV9y4LtuUdmiwdieKNo4OP8pKyCUzyUcWqz1xFqO-6dgE2_z5qTDSBa3lxk6px0zzDPHbKus95F3LVDhpLxzVuT9e91yeeu-ej0JFCrWZbHAcNQ0rGRBXMRVQgsk4t-xq3WC1Evx37yih7RaXSS6B8gc33CjsXargZFJ1t_R5YXBAxTN_SKfztAVzAa45_kIk8UNKwWkgyvAoHWphePLQTAiIH7EqsKkU_2m7qKC7oojt_mOO7cgEXbTHLoB2FDk-t9GX6H7JHenYD-MRIJmkmrBKMfKtstEQHgAQkjzVzFmVI5tVwBlUfMvsh5HTze4aTMJGsavZp62GQRGLDi3bMyc-G1eW0m8I4NdSw9jZIVLcPTINOvKtF3B65bOiMYUEDEwya2z9aXRcWAZqaEPtAS0jHM='
                : location.origin.indexOf('qa.cipo.cloud') != -1
                    // QA
                    // 'SrOOKYlkg5P55UKY2-R-QgmXkvBQsah5i10WlJ9GRFh9GTCuwRT5cMDj97UV1DNBYjdpi_5gMBngJOxjJCpdrNZxWjL-T1Xh5bfQLFPGQ65_vO6jKLOMMbv5OmNKGzneb4O_fB93dp_zItw-CjWfXvoz75mlLWMMwJAR7i0K-fxqNZpkb6d7tD1b1DCICGDbKrOKJvY4-m6UQhwyyyid8nnQNoefZplWvpVUt_26zgwlQqJg8lJ3iBduJCF6QVCT4raJz9pXPwku0zKn0v_Q2XNeU8o4C-AYqFlw4VuJuOH-ssyqnaOJqaNU8pQ8fiRVoquqpkypvwIPscNMO-vpHMyH5t6RKgOZuo0gBYQq1hY8k0gMhw3v-HliVJmF8-trWDqJjdFAt9NWo88fmojXR_BFKEkX_FOscBltcpk0NXleI00h-4vAvACDjGwwMZcxYY_rnPes6aN6W50uDsHjkOQQPhrhSKmLWzCJ_FBv8w8_51EPYx2pqjZvi9yT1lx4DfRyGOiqgxle78hb1gzesP0QyUtd-VHMTTcAGr32Wv7h1AiCRpajtRWD8qsJmdDVThUTa5nfcg3GRHo1CiyF-GjYxI4A_0IBeBHqwB-2hcr6J0-3vL5F52Oz5zPRefBJp-pacF8rj_kO636bPBL3gvAiHVXbYIi9SHOp5l-VNbs='
                    // new
                        ? 'W_9U2mEFYh26ySKXepZkXjzHAmOTWiXmQbOXkhz3jMBQxDdapHS-yYvLfVfJbrWWtcv_TZ9kHqeIejU5ppxCqUMGDoM_iSmGar5y231rf-oSnXtonnPLW6teF1YvVDgCK9Qys2jFov0eLOW04HffWHRm2xWedvyIvKLqwOAjnXnZVGVsODIFMR42rUf00fgfYX_WSkCCTIp7I9rqnY7-MDqPi6vODKMiclZUCMQ5b9rtCTfIFTxqWKhzP6VpvkRKNgCJMD6ohjS1uYMHaNj-RErDbheWm24mx4LLVxMr3hSnxHSrJXX_AMa_WlWm3n85ymnK9Qg85aZZMo8CeKQwpTF0-1WbBTZaVSOjjcEW6xhCzRW5gGVWmxZDHmeVW_K3o1iaXbY6KguwVDLCmGPCuYqPjqxJwTsetDoZeT_IHQYi2Cun4crSZW-7nYbMnKZDx9zrOe2jTix-uC3Kwz00EuWm9baPciakVW_5hckhbXdTPP-3ljIRQ-QcNn7tDDxGYm2a_Wd1jOjaflddgIAViJb2Qtc96QAZxjfMGK1vzyIhy1lfPQgDg5taO3rZ3ErziYjamPMYuugCJ0bauBv8JcF2FDMcn9zBHPyF--uoMwbeF4afpAcnxggSPWZezrCVEr9fDGNlWyZely7M0nUeRApvHLg75f9j4ysj___rJj0='
                    // prod
                    // 'ZMqkS3K-a6N8x1gNyXFOLQcMKb5BcJC7gQN2KuJgjQpLgNBuVcCfhnh7aw0O2D0WNnkF0L9srsJowtFp9tx0fbMhA43BXIEAWkEiRhqzXBTP-F7g6nmAfNXULNYwPLeBXGq-0SClXMcK5SYzFfJhOYx3WDb57LGUhmrYWHCsYwoslt6GrU9KQb3c0DeDaOt0AqSP6YYS39oiA7u157T5FdT_l_8KqAkXyabtamCFfrCbITNHpEVGHVL8IIqMh6HOVQsYVcLZaD06WS0eqobtSHWkCO70Y099tMOafebeuMWrX9Rg7m3be_nNuFei7FlH9ojWetRVxd_LNrZHCMPwGhsyyf21d07FEhsr9TWbdsSh31SQMOpHXEyiKLibP3WGdEqi9HPKbBbO4h4k4KXRb5n9ZqvXtDGmbHMQcBB3e13ogTGrXXPr5LKUQmAJSTqRRoUmiXRl1PS6Skw-jC5FUijziHVVkEY_AU8FNj6zm-8X6jkL1t0BecGT1aHeayC7t1sYFp5nh3Qf6uicB2q3XWU0r8PW5CoLLCUbIkJi0HwZuBuF5zKjLJZrvBwLv9GmL8r12JS6ecVQIMgSRC-sbTj75RpNBlMfy00iv_IBxAw3GD6Rxb8P6wXWQBv_juLz_ej7YxSOh6biV8KTY6rPNIoqdXdAGKMjYLU6cxEreoM=';
                    // new
                        : 'k5fAeF1DZb6AOq63mUOaXzG-dPqOyEymMbUkAv211fRPR9kUsPTa0UFljZ-5DUhbQ46SNK5Y376t0R5R9LP22KrvhYe3yY_kSTHuvXO267Ip-kfcsb3nLcbrfnngrBFdGsSDnbKfUbKAI_Nyc1QdnmvW0LbdfUPYcmxotjAn6KbvjtxvfBcuHxvJAnSRX3PslF2S4GDb8mpof4D1eB04sgf177ik0twYuJHStRE0gYgXoEpfDCjbHrWgOEZwswSf5FGoIQSmRE6Vd1mU2ZLGPqDqnc0YBG_LjzuabIqAs6N1X6gtPklGRdtEadKI1iLjsKp3vy_7nqaYs6GDYjM4si1yAEfhZwBUo8HsYLGjqmzOsBWYHJ8iT71dNEMVnUPnRRUotSMkqoLmcSR7QYS53uQSBWbxjhdejOB0pX9uehNg1ZBYHQ8vEakh4RwLPi7kb-0ngadHa9iNGBG4unwdsOu6_63rtDMuVo3tEALTZ103sh6yn4E8zk_jVKQ12QeRYfuVotHQGawBmuvn7htlKx7benyfLOF_9r6i0g2O12Jo7lmVvlRrp3h_lCUQm6jWzISNZ5aUN0Y1_T0vXDtCiS20vf3AadQw930CPRECATvCu6jSwLGnp_HKAbaSBQSZyJUpQI-ZRKzjkT1bYFrqjw==';
        },
        MIME_TYPES: [ 'application/pdf', 'image/jpeg', 'image/png' ],
        ANNOTATION_TYPES: {
            DOCUMENT_LINK: 'documentLink'
        }
    });

    cipo.constant("MIME_TYPES", {
        PREVIEWABLE: [
            'image/png' ,
            'application/pdf', 
            'text/plain', 
            'image/jpeg']
    });

    cipo.constant("OFFICE_MIME_TYPES", [
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
        'application/wopitest',
        'application/wopitestx',
    ]);

    cipo.constant("URL_ACTIONS", {
        CLONE: 1,
        PRESET: 2
    });

    cipo.constant("MAX_NR_VISIBLE_SELECT_ITEMS", 50);

    cipo.constant("PROJECT_FILE_MODULE_ID", -20);